import cn from 'classnames';
import * as React from 'react';

import s from './ContentCard.module.scss';

type ContentCardProps = React.PropsWithChildren<{
  className?: string;
  children: React.ReactNode;
}>;

const ContentCard: React.FC<ContentCardProps> = ({ className, children }) => {
  return <div className={cn(s['content-card'], className)}>{children}</div>;
};

export default ContentCard;
