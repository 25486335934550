import { Incoterms } from '@/entities/stage';
import { Nullable } from '@/shared/types/values';

import { BidCompany, BidDifferenceType, BidReplyServer, BidStatus, IBidReply } from '../types';

export class BidReplyModel implements IBidReply {
  id: number;
  status: BidStatus;
  deliveryBasis: Incoterms;
  diffType: BidDifferenceType;
  diffValue: number;
  diffCurrencyId: number;
  company: Nullable<BidCompany>;

  constructor({ id, status, deliveryBasis, diffType, diffValue, diffCurrencyId, company }: IBidReply) {
    this.id = id;
    this.status = status;
    this.deliveryBasis = deliveryBasis;
    this.diffType = diffType;
    this.diffValue = diffValue;
    this.diffCurrencyId = diffCurrencyId;
    this.company = company;
  }

  static fromServer(server: BidReplyServer): BidReplyModel {
    return new BidReplyModel({
      id: server.id,
      status: server.status,
      deliveryBasis: server.delivery_basis,
      diffType: server.diff_type,
      diffValue: server.diff_value,
      diffCurrencyId: server.diff_currency_id,
      company: server.company && {
        id: server.company.id,
        name: server.company.name,
        countryId: server.company.country_id,
      },
    });
  }
}
