import { BaseResponse } from '@kts-front/types';
import { UploadFile } from 'antd';
import { RcFile } from 'antd/es/upload';
import { action, computed, makeObservable, observable } from 'mobx';

import { DocumentFileType, DocumentInfoServer, DocumentTrade, FileModel, getDocTypeLabel } from '@/entities/file';
import { UserType } from '@/entities/user';
import { ValueModel } from '@/shared/model';
import { TranslationString } from '@/shared/types/localization';
import { Nullable } from '@/shared/types/values';

export type DocumentModelParams = {
  id: number;
  docType: DocumentFileType;
  createdAt: Date;
  name: string;
  url: string;
  comment: Nullable<string>;
  roles: Nullable<Array<UserType>>;
  origin: ValueModel<boolean>;
  trade: DocumentTrade;
};

export class DocumentModel {
  readonly id: number;
  readonly docType: DocumentFileType;
  readonly createdAt: Date;
  readonly name: string;
  readonly url: string;
  readonly comment: Nullable<string>;
  readonly roles: Nullable<Array<UserType>>;
  readonly origin: ValueModel<boolean>;
  readonly trade: DocumentTrade;

  private _file: Nullable<RcFile> = null;

  constructor({ id, docType, createdAt, name, url, comment, roles, origin, trade }: DocumentModelParams) {
    this.id = id;
    this.docType = docType;
    this.createdAt = createdAt;
    this.name = name;
    this.url = url;
    this.comment = comment;
    this.roles = roles;
    this.origin = origin;
    this.trade = trade;

    makeObservable<this, '_file'>(this, {
      _file: observable,

      file: computed,
      label: computed,

      fetchFile: action.bound,
    });
  }

  get label(): TranslationString {
    return getDocTypeLabel(this.docType);
  }

  get file(): UploadFile {
    return {
      uid: String(this.id),
      url: this.url,
      name: this.name,
      originFileObj: this._file ?? undefined,
    };
  }

  get fileList(): Array<UploadFile> {
    return [this.file];
  }

  async fetchFile(): Promise<BaseResponse> {
    if (this._file) {
      return { isError: false };
    }

    const result = await FileModel.fetchFile({ url: this.url, name: this.name });

    if (result.isError) {
      return { isError: true };
    }

    this._file = result.data as RcFile;

    return { isError: false };
  }

  static fromJson(docServer: DocumentInfoServer): DocumentModel {
    return new DocumentModel({
      id: docServer.id,
      docType: docServer.doc_type,
      createdAt: new Date(docServer.created_at),
      name: docServer.name,
      url: docServer.link,
      comment: docServer.description ?? null,
      roles: docServer.roles?.length ? docServer.roles : null,
      origin: new ValueModel(docServer.original ?? false),
      trade: {
        id: docServer.trade.id ?? null,
        weight: docServer.trade.weight,
        productId: docServer.trade.product_id,
        productTypeId: docServer.trade.product_type_id,
        weightUnitId: docServer.trade.weight_unit_id,
        customer: docServer.trade.customer,
        supplier: docServer.trade.supplier,
      },
    });
  }
}
