import { DocumentFileType, DocumentsServer, DocumentsWithStatusServer, FileModel } from '@/entities/file';
import { UploadFileModel } from '@/shared/model/form/UploadFileModel';
import { Nullable } from '@/shared/types/values';

import { BaseFieldsModelParams } from '../BaseFieldsModel';
import { BaseUploadFileFieldsModel, BaseUploadFileFieldsModelParams } from '../BaseUploadFileFieldsModel';

type BaseUploadFilesParams<Step, Action> = BaseUploadFileFieldsModelParams<Step, Action> & {
  docType: DocumentFileType;
};

export class BaseUploadFilesFieldsModel<Step, Action> extends BaseUploadFileFieldsModel<Step, Action> {
  readonly docType: DocumentFileType;

  constructor({ docType: docTypes, ...params }: BaseUploadFilesParams<Step, Action>) {
    super(params);

    this.docType = docTypes;
  }

  toApproveJson() {
    const files: File[] = [];
    const docTypes: DocumentFileType[] = [];
    this.file.value.forEach(({ originFileObj }) => {
      if (originFileObj) {
        files.push(originFileObj);
        docTypes.push(this.docType);
      }
    });

    return {
      action: this.approveAction,
      files,
      doc_types: docTypes,
    };
  }

  static fromServer<Step, Action>({
    data,
    maxCount,
    docFileType,
    ...params
  }: {
    maxCount: number;
    docFileType: DocumentFileType;
    data: Nullable<DocumentsServer | DocumentsWithStatusServer>;
  } & BaseFieldsModelParams<Step, Action>): BaseUploadFilesFieldsModel<Step, Action> {
    return new BaseUploadFilesFieldsModel({
      docType: docFileType,
      fileModel: new UploadFileModel({
        maxCount: maxCount,
        initialValue: FileModel.fileListFromJson(data?.documents),
        required: true,
      }),
      status: data && 'status' in data ? data.status : null,
      error: data && 'error_message' in data ? data.error_message : null,
      uploadedBy: data?.documents?.at(0)?.uploaded_by.role ?? null,
      ...params,
    });
  }
}
