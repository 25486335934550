import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { DictionaryField, DictionaryFielsdArgs } from '@/entities/dictionary';
import { UserType } from '@/entities/user';
import { CustomResult } from '@/shared/ui';

import { useRootStore } from '../store';

type Params<P> = {
  Component: React.ComponentType<P>;
  dictionariesMap: Record<UserType, DictionaryField[]>;
  dictionariesArgs?: Record<UserType, DictionaryFielsdArgs>;
};

/* HOC для предварительной загрузки необходимых словарей */
export const withDictionaries = <P extends Record<string, unknown>>({
  Component,
  dictionariesMap,
  dictionariesArgs,
}: Params<P>) =>
  observer((props: P) => {
    const { userStore, dictionariesStore, localizationStore } = useRootStore();

    React.useEffect(() => {
      if (!userStore.userModel) {
        return;
      }

      dictionariesStore.loadDictionaries(
        dictionariesMap[userStore.userModel.type],
        dictionariesArgs?.[userStore.userModel.type],
      );
    }, [dictionariesMap, localizationStore.lng, userStore.userModel?.type]);

    if (dictionariesStore.loadingStage.isError) {
      return <CustomResult status="error" title="Ошибка загрузки словарей" />;
    }

    return <Component {...props} />;
  });
