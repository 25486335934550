import { locale as dayjsLocale } from 'dayjs';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { action, computed, makeObservable, observable } from 'mobx';
import { initReactI18next } from 'react-i18next';

import { nameSpaceNames } from '@/app/locales/nameSpaces';
import { resources } from '@/app/locales/resources';
import {
  SupportedLocale,
  TFunctionType,
  defaultNameSpace,
  fallbackLanguage,
  keySeparator,
} from '@/shared/types/localization';

import { ILocalizationStore, LocalizationStoreParams } from './types';

export class LocalizationStore implements ILocalizationStore {
  private _langs: Array<SupportedLocale>;
  private _lng: SupportedLocale;
  private _t: TFunctionType;

  constructor({ t, lng, langs }: LocalizationStoreParams) {
    this._langs = langs;
    this._lng = lng;
    this._t = t;

    dayjsLocale(this._lng);

    makeObservable<LocalizationStore, '_langs' | '_lng' | '_t'>(this, {
      _langs: observable,
      _lng: observable,
      _t: observable,

      langs: computed,
      lng: computed,
      t: computed,

      changeLng: action,
    });
  }

  get t(): TFunctionType {
    return this._t;
  }

  get lng(): SupportedLocale {
    return this._lng;
  }

  get langs(): Array<SupportedLocale> {
    return this._langs;
  }

  changeLng = async (lng: SupportedLocale): Promise<void> => {
    // @ts-ignore
    // eslint-disable-next-line import/no-named-as-default-member
    this._t = await i18next.changeLanguage(lng);
    this._lng = lng;
    dayjsLocale(this._lng);
  };

  static async init(): Promise<LocalizationStore> {
    const supportedLocales = Object.keys(resources) as Array<SupportedLocale>;

    // @ts-ignore
    // eslint-disable-next-line import/no-named-as-default-member
    const t: TFunctionType = await i18next
      .use(initReactI18next)
      .use(LanguageDetector)
      .init({
        parseMissingKeyHandler: () => null,
        ns: nameSpaceNames,
        defaultNS: defaultNameSpace,
        fallbackLng: fallbackLanguage,
        resources,
        supportedLngs: supportedLocales,
        keySeparator: keySeparator,
        interpolation: {
          escapeValue: false,
          skipOnVariables: false,
        },
      });

    return new LocalizationStore({
      langs: supportedLocales,
      lng: i18next.language as SupportedLocale,
      t,
    });
  }
}
