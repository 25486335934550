import { BaseResponse } from '@kts-front/types';

import { DocumentFileType, GenerationFieldsModel } from '@/entities/file';
import { AdditionalAgreementDocPayload, AdditionalAgreementDocResponse } from '@/entities/stage/types';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { SelectModel } from '@/shared/model/form/SelectModel';
import { Nullable } from '@/shared/types/values';
import { formatDate } from '@/shared/utils/formatDate';
import { emptyValueValidator } from '@/shared/utils/validators';

export class AdditionalAgreementFiedsModel extends GenerationFieldsModel<
  DocumentFileType.customerAdditionalAgreement,
  AdditionalAgreementDocPayload
> {
  readonly date = new DatePickerModel({
    initialValue: null,
    label: (t) => t('additionalAgreement.date', { ns: 'stage' }),
    placeholder: (t) => t('placeholders.date', { ns: 'shared' }),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly signatureId = new SelectModel<number>({
    initialValue: null,
    label: (t) => t('additionalAgreement.signature', { ns: 'stage' }),
    placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly invoiceNumber = new InputModel({
    initialValue: '',
    label: (t) => t('additionalAgreement.invoiceNumber', { ns: 'stage' }),
    placeholder: (t) => t('additionalAgreement.invoiceNumberPlaceholder', { ns: 'stage' }),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly invoiceDate = new DatePickerModel({
    initialValue: null,
    label: (t) => t('additionalAgreement.invoiceDate', { ns: 'stage' }),
    placeholder: (t) => t('placeholders.date', { ns: 'shared' }),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly debtRepaymentDate = new DatePickerModel({
    initialValue: null,
    label: (t) => t('additionalAgreement.debtRepaymentDate', { ns: 'stage' }),
    placeholder: (t) => t('placeholders.date', { ns: 'shared' }),
    required: true,
    validators: [emptyValueValidator()],
  });

  get fields() {
    return [this.date, this.signatureId, this.invoiceNumber, this.invoiceDate, this.debtRepaymentDate];
  }

  toJson(): Nullable<AdditionalAgreementDocPayload> {
    if (
      !this.date.value ||
      !this.signatureId.value ||
      !this.invoiceNumber.value ||
      !this.invoiceDate.value ||
      !this.debtRepaymentDate.value
    ) {
      return null;
    }

    return {
      date: formatDate(this.date.value),
      invoice_number: this.invoiceNumber.value,
      invoice_date: formatDate(this.invoiceDate.value),
      debt_repayment_date: formatDate(this.debtRepaymentDate.value),
      signatory_id: this.signatureId.value,
    };
  }

  async loadFieldsData(): Promise<BaseResponse> {
    if (this.loadingState.isLoading) {
      this._fieldsDataRequest.cancel();
    }

    this.loadingState.loading();

    const response = await this._fieldsDataRequest.call<AdditionalAgreementDocResponse>();

    if (response.isError) {
      this.loadingState.error();
    } else {
      this._fillFieldsByResponseData(response.data);
      this.loadingState.success();
    }

    return response;
  }

  private readonly _fillFieldsByResponseData = (data: AdditionalAgreementDocResponse): void => {
    this.date.change(data.date ? new Date(data.date) : null);
    this.invoiceDate.change(data.invoice_date ? new Date(data.invoice_date) : null);
    this.invoiceNumber.change(data.invoice_number ?? '');
    this.debtRepaymentDate.change(data.debt_repayment_date ? new Date(data.debt_repayment_date) : null);
    this.signatureId.change(data.signatory_id ?? null);
  };
}
