import { TranslationString } from '@/shared/types/localization';
import { Options } from '@/shared/types/values';

import { UserType } from './types';

export const mapUserTypeToLabel: Record<UserType, TranslationString> = {
  [UserType.agent]: (t) => t('userRoles.agent', { ns: 'user' }),
  [UserType.customer]: (t) => t('userRoles.customer', { ns: 'user' }),
  [UserType.manager]: (t) => t('userRoles.manager', { ns: 'user' }),
  [UserType.supplier]: (t) => t('userRoles.supplier', { ns: 'user' }),
  [UserType.admin]: (t) => t('userRoles.administrator', { ns: 'user' }),
};

export const userFilterOptions: Options<UserType> = [
  {
    label: mapUserTypeToLabel[UserType.customer],
    value: UserType.customer,
  },
  {
    label: mapUserTypeToLabel[UserType.supplier],
    value: UserType.supplier,
  },
];
