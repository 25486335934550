import { computed, makeObservable } from 'mobx';

import { LoadingStageModel, LocalStore } from '@/shared/model';
import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';
import { InputModel, InputType } from '@/shared/model/form/InputModel';
import { Nullable } from '@/shared/types/values';
import { emailValidator, emptyValueValidator, phoneValidator, stringLengthValidator } from '@/shared/utils/validators';

export type ClientPayload = {
  email: string;
  phone: string;
};

export type ClientFieldsParams = {
  email: string;
  phone: string;
};

const T_OPTIONS = { ns: 'user' } as const;

export class ClientFieldsModel extends LocalStore {
  readonly email: InputModel;
  readonly phone: InputModel;

  readonly loadingStage = new LoadingStageModel();

  protected fields: BaseFieldModel<any>[];

  constructor(user: ClientFieldsParams) {
    super();

    this.email = new InputModel({
      initialValue: user.email,
      label: (t) => t('fields.email', T_OPTIONS),
      placeholder: (t) => t('fields.email', T_OPTIONS),
      validators: [emptyValueValidator(), emailValidator, stringLengthValidator(128)],
      required: true,
    });

    this.phone = new InputModel({
      initialValue: user.phone,
      type: InputType.phone,
      label: (t) => t('fields.phone', T_OPTIONS),
      placeholder: (t) => t('fields.phone', T_OPTIONS),
      validators: [emptyValueValidator(), phoneValidator],
      required: true,
    });

    this.fields = [this.email, this.phone];

    makeObservable(this, {
      isValid: computed,
      isChange: computed,
    });
  }

  get isValid(): boolean {
    return this.fields.every((field) => !field.isError);
  }

  get isChange(): boolean {
    return this.fields.some((field) => field.touched);
  }

  validate = (): boolean => {
    this.fields.forEach((field) => field.validate());

    return this.isValid;
  };

  reset = (): void => {
    this.fields.forEach((field) => field.reset());
  };

  toJson = (): Nullable<ClientPayload> => {
    if (!this.email.value && !this.phone.value) {
      return null;
    }

    return {
      email: this.email.value,
      phone: this.phone.value,
    };
  };

  static fromDefaultParams(): ClientFieldsModel {
    return new ClientFieldsModel({
      email: '',
      phone: '',
    });
  }
}
