import { action, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { StageType } from '@/entities/stage';
import { ToggleModel } from '@/shared/model';
import { CheckboxListFilterModel } from '@/shared/model/filters/CheckboxListFilterModel';
import { BaseFieldModelParams } from '@/shared/model/form/BaseFieldModel';

import { tradeStageChatFilterOptionsCreator } from '../config';

type TradeStageFilterModelParams = BaseFieldModelParams<StageType[]> & {
  rootStore: IRootStore;
};

export class TradeStageFilterModel extends CheckboxListFilterModel<StageType> {
  readonly checkedAll: ToggleModel;

  constructor(params: TradeStageFilterModelParams) {
    const userType = params.rootStore.userStore.userModel?.type ?? null;
    const options = userType ? tradeStageChatFilterOptionsCreator(userType) : [];

    super({
      ...params,
      options,
    });

    const initialValue = params.initialValue;
    this.checkedAll = new ToggleModel(initialValue.length > 0 && initialValue.length === options.length);

    makeObservable(this, {
      onChange: action.bound,
    });
  }

  reset() {
    this.checkedAll.change(false);
    this._valueForJson.change([]);
    this.change([]);
    this.dropdownState.close();
  }

  onChange(value: StageType) {
    this._onChange(value);

    if (this._value.length === this._options.length) {
      this.checkedAll.change(true);
    } else {
      this.checkedAll.change(false);
    }
  }
}
