import cn from 'classnames';
import * as React from 'react';

import { formatMessageCount } from './utils';

import s from './NewMessageCounter.module.scss';

type Props = {
  count: number;
  className?: string;
  small?: boolean;
};

const NewMessageCounter: React.FC<Props> = ({ count, small, className }) => {
  const formattedCount = formatMessageCount(count);
  const oneDigitNumber = count < 10;

  return (
    <div className={cn(s.counter, oneDigitNumber && s['counter_one-digit'], small && s['counter_small'], className)}>
      {formattedCount}
    </div>
  );
};

export default React.memo(NewMessageCounter);
