import * as React from 'react';

import { SupportedLocale } from '@/shared/types/localization';
import { RUIcon, USIcon, ZHIcon } from '@/shared/ui/icons';

export const mapLangToFlag: Record<SupportedLocale, React.ReactElement> = {
  [SupportedLocale.en]: <USIcon />,
  [SupportedLocale.ru]: <RUIcon />,
  [SupportedLocale.zh]: <ZHIcon />,
};
