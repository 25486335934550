import { Flex } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { TranslationNode } from '@/shared/types/localization';

import { CustomTypographyText } from '../CustomTypographyText';

import s from './StepDescription.module.scss';

type StepDescriptionProps = React.PropsWithChildren<{
  className?: string;
  description?: TranslationNode;
  direction?: 'horizontal' | 'vertical';
}>;

const StepDescription: React.FC<StepDescriptionProps> = ({
  className,
  children,
  description,
  direction = 'vertical',
}) => {
  const isVertical = direction === 'vertical';

  return (
    <div className={cn(s.step_wrapper, className)}>
      {description && (
        <CustomTypographyText className={s.step_description} type="secondary">
          {description}
        </CustomTypographyText>
      )}
      <Flex vertical={isVertical} gap={8}>
        {children}
      </Flex>
    </div>
  );
};

export default StepDescription;
