import { DocumentFileType } from '@/entities/file';
import { SupplierFinalizationStep } from '@/entities/stage/types';
import { Nullable } from '@/shared/types/values';

import { DocTypesOptions } from '../../fieldsModel/WaitingFieldsWithFilesModel';

export type TradeCloseSupplierDocs = DocumentFileType.supplierAcceptanceCertificate;

const T_OPTIONS = { ns: 'finalizationStage' } as const;

export const tradeCloseSupplierDocsOptions: DocTypesOptions<TradeCloseSupplierDocs> = [
  {
    docType: DocumentFileType.supplierAcceptanceCertificate,
    description: (t) =>
      t(`supplier.tradeCloseSupplierDocs.docsDescription.${DocumentFileType.supplierAcceptanceCertificate}`, T_OPTIONS),
  },
];

export const mapSupplierStepToNumber = (step: Nullable<SupplierFinalizationStep>): number => {
  switch (step) {
    case SupplierFinalizationStep.cargoArrival:
      return 0;

    case SupplierFinalizationStep.tradeCloseSupplierDocs:
      return 1;

    case SupplierFinalizationStep.finish:
      return 2;

    default:
      return -1;
  }
};
