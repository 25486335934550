import { ApiRequest } from '@kts-front/call-api';
import { BaseResponse } from '@kts-front/types';
import { StepProps } from 'antd';
import { action, computed, makeObservable, observable } from 'mobx';

import { IRootStore } from '@/app/store';
import { IDictionariesStore } from '@/entities/dictionary';
import {
  BaseManagerStageModel,
  ConfirmationStageServer,
  CreationStageServer,
  CustomerFinalizationStageDataServer,
  CustomerFinalizationStageModel,
  CustomerShipmentStageDataServer,
  CustomerShipmentStageModel,
  CustomerTransportationStageDataServer,
  CustomerTransportationStageModel,
  FinalizationStageServer,
  ManagerConfirmationStageDataServer,
  ManagerConfirmationStageModel,
  ManagerCreationStageDataServer,
  ManagerCreationStageModel,
  ManagerFinalizationStageDataServer,
  ManagerFinalizationStageModel,
  ManagerPaymentStageDataServer,
  ManagerPaymentStageModel,
  ManagerShipmentStageDataServer,
  ManagerShipmentStageModel,
  ManagerTransportationStageDataServer,
  ManagerTransportationStageModel,
  OtherCreationStageDataServer,
  OtherCreationStageModel,
  PaymentStageServer,
  ShipmentStageServer,
  StageModel,
  StageServer,
  StageType,
  SupplierConfirmationStageDataServer,
  SupplierConfirmationStageModel,
  SupplierFinalizationStageDataServer,
  SupplierFinalizationStageModel,
  SupplierPaymentStageDataServer,
  SupplierPaymentStageModel,
  SupplierShipmentStageDataServer,
  SupplierShipmentStageModel,
  TransportationStageServer,
  mapStageTypeToLabel,
  mapUserTypeToStageTypes,
} from '@/entities/stage';
import { ITradeWorkflowStore, TradeInfoModel, TradeResponse, TradeStatus } from '@/entities/trade';
import { UserType } from '@/entities/user';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, LocalStore, ToggleModel } from '@/shared/model';
import { TranslationString } from '@/shared/types/localization';
import { ID } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

import { getStepItems } from './config';

type StageModelParams<Server extends StageServer> = {
  stage: Server;
  tradeWorkflowStore: ITradeWorkflowStore;
};

type TradeWorkflowStoreParams = {
  tradeServer: TradeResponse;
  rootStore: IRootStore;
};

export class TradeWorkflowStore extends LocalStore implements ITradeWorkflowStore {
  protected _stageModel: StageModel;
  protected _tradeInfo: TradeInfoModel;

  readonly rootStore: IRootStore;

  readonly loadingStage = new LoadingStageModel();
  readonly successModalState = new ToggleModel();

  readonly tradeDetailsRequest: ApiRequest<TradeResponse>;

  constructor({ tradeServer, rootStore }: TradeWorkflowStoreParams) {
    super();

    this.rootStore = rootStore;
    this._tradeInfo = TradeInfoModel.fromJson({
      json: tradeServer,
      rootStore: this.rootStore,
    });
    this._stageModel = this._stageModelFromJson({
      stage: tradeServer.stage,
      tradeWorkflowStore: this,
    });

    this.tradeDetailsRequest = apiStore.createRequest({
      url: apiUrls.trade.details(tradeServer.id),
    });

    makeObservable<this, '_stageModel' | '_tradeInfo'>(this, {
      _stageModel: observable,
      _tradeInfo: observable,

      tradeId: computed,
      tradeInfo: computed,
      stageModel: computed,
      userType: computed,
      currentStageNumber: computed,
      currentStageTitle: computed,
      tradeStepItems: computed,
      isLoadingDictionaries: computed,
      loadingNextStage: computed,

      updateTradeWorkflow: action.bound,
      loadStage: action.bound,
    });
  }

  get tradeId(): number {
    return this._tradeInfo.id;
  }

  get tradeInfo(): TradeInfoModel {
    return this._tradeInfo;
  }

  get stageModel(): StageModel {
    return this._stageModel;
  }

  get dictionaries(): IDictionariesStore {
    return this.rootStore.dictionariesStore;
  }

  get userType(): Nullable<UserType> {
    return this.rootStore.userStore.userModel?.type ?? null;
  }

  get currentStageNumber(): number {
    return this.userType
      ? mapUserTypeToStageTypes[this.userType].findIndex((stageType) => stageType === this.stageModel.type)
      : 0;
  }

  get tradeStepItems(): StepProps[] {
    const isTradeFinished = this.tradeInfo.tradeStatus === TradeStatus.finished;

    return getStepItems({
      currentType: this.stageModel.currentType,
      userType: this.userType,
      isTradeFinished,
    });
  }

  get currentStageTitle(): TranslationString {
    return mapStageTypeToLabel[this.stageModel.currentType];
  }

  get isLoadingDictionaries(): boolean {
    return this.dictionaries.loadingStage.isLoading;
  }

  get loadingNextStage(): boolean {
    return (
      this.loadingStage.isLoading ||
      (this.stageModel instanceof BaseManagerStageModel && this.stageModel.transferFields.loadingStage.isLoading)
    );
  }

  updateTradeWorkflow(tradeServer: TradeResponse, omitStageUpdate = false): void {
    this._tradeInfo = TradeInfoModel.fromJson({
      json: tradeServer,
      rootStore: this.rootStore,
    });

    if (!omitStageUpdate) {
      this._stageModel = this._stageModelFromJson({
        stage: tradeServer.stage,
        tradeWorkflowStore: this,
      });
    }
  }

  async loadStage(step?: number): Promise<void> {
    if (this.loadingStage.isLoading) {
      return;
    }

    this.loadingStage.loading();

    const params =
      step !== undefined && this.userType ? { key: mapUserTypeToStageTypes[this.userType][step] } : undefined;

    const response = await this.tradeDetailsRequest.call({ params });

    if (response.isError) {
      this.loadingStage.error();

      return;
    }

    this.updateTradeWorkflow(response.data);
    this.loadingStage.success();
  }

  private _creationStageModelFromJson({
    stage,
    tradeWorkflowStore,
  }: StageModelParams<CreationStageServer>): StageModel {
    switch (tradeWorkflowStore.rootStore.userStore.userModel?.type) {
      case UserType.admin:

      case UserType.manager:
        return ManagerCreationStageModel.fromJson({
          dataServer: stage.data as ManagerCreationStageDataServer,
          tradeWorkflowStore,
        });

      default:
        return OtherCreationStageModel.fromJson({
          dataServer: stage.data as OtherCreationStageDataServer,
          tradeWorkflowStore,
        });
    }
  }

  private _confirmationStageModelFromJson({
    stage,
    tradeWorkflowStore,
  }: StageModelParams<ConfirmationStageServer>): StageModel {
    switch (tradeWorkflowStore.rootStore.userStore.userModel?.type) {
      case UserType.admin:

      case UserType.manager:
        return ManagerConfirmationStageModel.fromJson({
          dataServer: stage.data as ManagerConfirmationStageDataServer,
          tradeWorkflowStore,
        });

      case UserType.supplier:
        return SupplierConfirmationStageModel.fromJson({
          dataServer: stage.data as SupplierConfirmationStageDataServer,
          tradeWorkflowStore,
        });

      default:
        return ManagerConfirmationStageModel.fromJson({
          dataServer: stage.data as ManagerConfirmationStageDataServer,
          tradeWorkflowStore,
        });
    }
  }

  private _paymentStageModelFromJson({ stage, tradeWorkflowStore }: StageModelParams<PaymentStageServer>): StageModel {
    switch (tradeWorkflowStore.rootStore.userStore.userModel?.type) {
      case UserType.admin:

      case UserType.manager:
        return ManagerPaymentStageModel.fromJson({
          dataServer: stage.data as ManagerPaymentStageDataServer,
          tradeWorkflowStore,
        });

      case UserType.supplier:
        return SupplierPaymentStageModel.fromJson({
          dataServer: stage.data as SupplierPaymentStageDataServer,
          tradeWorkflowStore,
        });

      default:
        return ManagerPaymentStageModel.fromJson({
          dataServer: stage.data as ManagerPaymentStageDataServer,
          tradeWorkflowStore,
        });
    }
  }

  private _shipmentStageModelFromJson({
    stage,
    tradeWorkflowStore,
  }: StageModelParams<ShipmentStageServer>): StageModel {
    switch (tradeWorkflowStore.rootStore.userStore.userModel?.type) {
      case UserType.admin:

      case UserType.manager:
        return ManagerShipmentStageModel.fromJson({
          dataServer: stage.data as ManagerShipmentStageDataServer,
          tradeWorkflowStore,
        });

      case UserType.supplier:
        return SupplierShipmentStageModel.fromJson({
          dataServer: stage.data as SupplierShipmentStageDataServer,
          tradeWorkflowStore,
        });

      case UserType.customer:
        return CustomerShipmentStageModel.fromJson({
          dataServer: stage.data as CustomerShipmentStageDataServer,
          tradeWorkflowStore,
        });

      default:
        return ManagerShipmentStageModel.fromJson({
          dataServer: stage.data as ManagerShipmentStageDataServer,
          tradeWorkflowStore,
        });
    }
  }

  private _transportationStageModelFromJson({
    stage,
    tradeWorkflowStore,
  }: StageModelParams<TransportationStageServer>): StageModel {
    switch (tradeWorkflowStore.rootStore.userStore.userModel?.type) {
      case UserType.admin:

      case UserType.manager:
        return ManagerTransportationStageModel.fromJson({
          dataServer: stage.data as ManagerTransportationStageDataServer,
          tradeWorkflowStore,
        });

      case UserType.customer:
        return CustomerTransportationStageModel.fromJson({
          dataServer: stage.data as CustomerTransportationStageDataServer,
          tradeWorkflowStore,
        });

      default:
        return ManagerTransportationStageModel.fromJson({
          dataServer: stage.data as ManagerTransportationStageDataServer,
          tradeWorkflowStore,
        });
    }
  }

  private _finalizationStageModelFromJson({
    stage,
    tradeWorkflowStore,
  }: StageModelParams<FinalizationStageServer>): StageModel {
    switch (tradeWorkflowStore.rootStore.userStore.userModel?.type) {
      case UserType.admin:

      case UserType.manager:
        return ManagerFinalizationStageModel.fromJson({
          dataServer: stage.data as ManagerFinalizationStageDataServer,
          tradeWorkflowStore,
        });

      case UserType.supplier:
        return SupplierFinalizationStageModel.fromJson({
          dataServer: stage.data as SupplierFinalizationStageDataServer,
          tradeWorkflowStore,
        });

      case UserType.customer:
        return CustomerFinalizationStageModel.fromJson({
          dataServer: stage.data as CustomerFinalizationStageDataServer,
          tradeWorkflowStore,
        });

      default:
        return ManagerFinalizationStageModel.fromJson({
          dataServer: stage.data as ManagerFinalizationStageDataServer,
          tradeWorkflowStore,
        });
    }
  }

  protected _stageModelFromJson = ({ stage, tradeWorkflowStore }: StageModelParams<StageServer>): StageModel => {
    switch (stage.key) {
      case StageType.creation:
        return this._creationStageModelFromJson({ stage, tradeWorkflowStore });

      case StageType.confirmation:
        return this._confirmationStageModelFromJson({ stage, tradeWorkflowStore });

      case StageType.payment:
        return this._paymentStageModelFromJson({ stage, tradeWorkflowStore });

      case StageType.shipment:
        return this._shipmentStageModelFromJson({ stage, tradeWorkflowStore });

      case StageType.transportation:
        return this._transportationStageModelFromJson({ stage, tradeWorkflowStore });

      case StageType.finalization:
        return this._finalizationStageModelFromJson({ stage, tradeWorkflowStore });
    }
  };

  static async asyncStoreInit({
    tradeId,
    rootStore,
  }: {
    tradeId?: ID;
    rootStore: IRootStore;
  }): Promise<BaseResponse<TradeWorkflowStore>> {
    if (!tradeId) {
      return { isError: true };
    }

    const response = await apiStore
      .createRequest<TradeResponse>({
        url: apiUrls.trade.details(tradeId),
      })
      .call();

    if (response.isError) {
      return { isError: true };
    }

    return {
      isError: false,
      data: new TradeWorkflowStore({
        tradeServer: response.data,
        rootStore,
      }),
    };
  }
}
