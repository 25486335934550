import { Checkbox, MenuProps } from 'antd';
import * as React from 'react';

import { Options } from '@/shared/types/values';
import { TransComp } from '@/shared/ui';

export const getMenuItems = <Value extends string>({
  values,
  options,
  onChange,
}: {
  values: Value[];
  onChange: (value: Value) => void;
  options: Options<Value>;
}): MenuProps['items'] => {
  return options.map(({ value, label }) => ({
    key: value,
    label: (
      <Checkbox
        style={{ width: '100%' }}
        value={value}
        checked={values.includes(value)}
        onChange={() => onChange(value)}
      >
        <TransComp>{label}</TransComp>
      </Checkbox>
    ),
  }));
};
