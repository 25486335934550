import * as React from 'react';

import { IconProps } from '../types';

const ChatEmptyIcon: React.FC<IconProps> = ({ size = 225, ...props }) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 226 158" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8402 0.638031H138.718C142.884 0.642722 146.878 2.29981 149.824 5.24575C152.77 8.1917 154.427 12.1859 154.432 16.3521V83.5757C154.427 87.7419 152.77 91.7361 149.824 94.682C146.878 97.6279 142.884 99.285 138.718 99.2897H15.8402C11.6741 99.285 7.67985 97.6279 4.73392 94.682C1.78798 91.7361 0.130902 87.7419 0.126221 83.5757V16.3521C0.130901 12.1859 1.78798 8.1917 4.73392 5.24575C7.67985 2.29981 11.6741 0.642722 15.8402 0.638031Z"
        fill="#E4EBF7"
      />
      <path
        d="M15.8402 5.16177H138.718C141.685 5.16509 144.529 6.34514 146.627 8.44301C148.725 10.5409 149.905 13.3852 149.908 16.3521V83.5757C149.905 86.5425 148.725 89.3869 146.627 91.4847C144.529 93.5826 141.685 94.7626 138.718 94.766H15.8402C12.8734 94.7626 10.0291 93.5826 7.93122 91.4847C5.83338 89.3868 4.65335 86.5425 4.65002 83.5757V16.3521C4.65335 13.3853 5.83337 10.5409 7.93121 8.44305C10.0291 6.34518 12.8734 5.16512 15.8402 5.16177Z"
        fill="white"
      />
      <path
        d="M17.0391 123.358C16.6683 123.357 16.3014 123.283 15.9593 123.14C15.4488 122.934 15.012 122.58 14.7063 122.122C14.4006 121.665 14.2402 121.126 14.246 120.576V97.3368L45.2275 96.2944L18.984 122.538C18.7302 122.797 18.4276 123.002 18.0937 123.143C17.7599 123.284 17.4014 123.357 17.0391 123.358Z"
        fill="#E4EBF7"
      />
      <path
        d="M123.328 21.8827H30.9304C27.2311 21.8827 24.2322 24.8816 24.2322 28.581V28.581C24.2322 32.2803 27.2311 35.2792 30.9304 35.2792H123.328C127.027 35.2792 130.026 32.2803 130.026 28.581C130.026 24.8816 127.027 21.8827 123.328 21.8827Z"
        fill="#E4EBF7"
      />
      <path
        d="M123.478 43.2656H31.0802C27.3809 43.2656 24.382 46.2645 24.382 49.9639V49.9639C24.382 53.6632 27.3809 56.6621 31.0802 56.6621H123.478C127.177 56.6621 130.176 53.6632 130.176 49.9639C130.176 46.2645 127.177 43.2656 123.478 43.2656Z"
        fill="#E4EBF7"
      />
      <path
        d="M123.627 64.6485H31.2299C27.5305 64.6485 24.5316 67.6474 24.5316 71.3468V71.3468C24.5316 75.0461 27.5305 78.045 31.2299 78.045H123.627C127.327 78.045 130.326 75.0461 130.326 71.3468C130.326 67.6474 127.327 64.6485 123.627 64.6485Z"
        fill="#E4EBF7"
      />
      <path
        d="M209.873 34.9232H86.9956C82.8294 34.9278 78.8352 36.5849 75.8893 39.5309C72.9434 42.4768 71.2863 46.471 71.2816 50.6372V117.861C71.2863 122.027 72.9434 126.021 75.8893 128.967C78.8352 131.913 82.8295 133.57 86.9956 133.575H209.873C214.039 133.57 218.033 131.913 220.979 128.967C223.925 126.021 225.582 122.027 225.587 117.861V50.6372C225.582 46.471 223.925 42.4768 220.979 39.5309C218.033 36.5849 214.039 34.9278 209.873 34.9232Z"
        fill="#E4EBF7"
      />
      <path
        d="M209.873 39.4469H86.9956C84.0288 39.4502 81.1844 40.6303 79.0866 42.7281C76.9887 44.826 75.8086 47.6704 75.8053 50.6372V117.861C75.8086 120.828 76.9887 123.672 79.0866 125.77C81.1844 127.868 84.0288 129.048 86.9956 129.051H209.873C212.84 129.048 215.684 127.868 217.782 125.77C219.88 123.672 221.06 120.828 221.063 117.861V50.6372C221.06 47.6704 219.88 44.826 217.782 42.7282C215.684 40.6303 212.84 39.4502 209.873 39.4469Z"
        fill="white"
      />
      <path
        d="M200.525 156.823L174.282 130.58L205.263 131.622V154.861C205.269 155.411 205.109 155.95 204.803 156.408C204.497 156.865 204.06 157.22 203.55 157.425C203.208 157.568 202.841 157.642 202.47 157.643C202.108 157.642 201.749 157.569 201.415 157.428C201.082 157.287 200.779 157.082 200.525 156.823Z"
        fill="#E4EBF7"
      />
      <path
        d="M121.333 92.7386C126.021 92.7386 129.822 88.9377 129.822 84.249C129.822 79.5603 126.021 75.7593 121.333 75.7593C116.644 75.7593 112.843 79.5603 112.843 84.249C112.843 88.9377 116.644 92.7386 121.333 92.7386Z"
        fill="#1677FF"
      />
      <path
        d="M148.434 92.7386C153.123 92.7386 156.924 88.9377 156.924 84.249C156.924 79.5603 153.123 75.7593 148.434 75.7593C143.746 75.7593 139.945 79.5603 139.945 84.249C139.945 88.9377 143.746 92.7386 148.434 92.7386Z"
        fill="#1677FF"
      />
      <path
        d="M175.536 92.7386C180.225 92.7386 184.026 88.9377 184.026 84.249C184.026 79.5603 180.225 75.7593 175.536 75.7593C170.847 75.7593 167.046 79.5603 167.046 84.249C167.046 88.9377 170.847 92.7386 175.536 92.7386Z"
        fill="#1677FF"
      />
    </svg>
  );
};

export default React.memo(ChatEmptyIcon);
