import * as React from 'react';

import { IconProps } from '../types';

const SwapRightOutlined: React.FC<IconProps> = ({ size = 16, ...props }) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5625 11.1094H17.2781L13.3813 7.72668C13.3109 7.66551 13.3552 7.54971 13.4485 7.55117L14.557 7.56855L15.9034 7.58966C15.9269 7.59003 15.9496 7.5987 15.9674 7.61413L20.3672 11.4328C20.4483 11.5034 20.5134 11.5905 20.558 11.6884C20.6025 11.7862 20.6256 11.8925 20.6256 12C20.6256 12.1075 20.6025 12.2138 20.558 12.3116C20.5134 12.4095 20.4483 12.4966 20.3672 12.5672L15.9396 16.4079C15.9216 16.4235 15.8986 16.4321 15.8748 16.4323L13.4428 16.451C13.3499 16.4518 13.3063 16.3364 13.3765 16.2755L17.2758 12.8906H3.5625C3.45937 12.8906 3.375 12.8063 3.375 12.7031V11.2969C3.375 11.1937 3.45937 11.1094 3.5625 11.1094Z"
        fill="black"
        fillOpacity="0.25"
      />
    </svg>
  );
};

export default React.memo(SwapRightOutlined);
