import { IngotIdType, IngotServer } from '@/entities/stage';
import { LocalStore } from '@/shared/model';
import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { numberParser } from '@/shared/utils';
import { emptyValueValidator, numberValidator, stringLengthValidator } from '@/shared/utils/validators';

const T_OPTIONS = { ns: 'stage' } as const;

export class IngotModel extends LocalStore {
  readonly fieldUin: BaseFieldModel<any>[];
  readonly fieldYear: BaseFieldModel<any>[];
  readonly fields: BaseFieldModel<any>[];
  readonly ingotNumber = new InputModel({
    initialValue: '',
    placeholder: (t) => t('customerSpecification.ingotNumberPlaceholder', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), stringLengthValidator(16)],
    parser: (value) => value.replace(/[^0-9a-zA-Zа-яА-ЯёЁ]/g, ''),
  });
  readonly weight = new InputModel({
    initialValue: '',
    placeholder: (t) => t('customerSpecification.weightPlaceholder', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
    parser: numberParser({ maxFractionDigits: 3 }),
  });
  readonly ligatureWeight = new InputModel({
    initialValue: '',
    placeholder: (t) => t('customerSpecification.weightPlaceholder', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
    parser: numberParser({ maxFractionDigits: 3 }),
  });
  readonly metalPurity = new InputModel({
    initialValue: '',
    placeholder: (t) => t('customerSpecification.metalPurityPlaceholder', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
    parser: numberParser(),
  });
  readonly year = new DatePickerModel({
    initialValue: null,
    placeholder: (t) => t('customerSpecification.year', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator()],
    ignoreOnBlurValidation: true,
  });
  readonly uin = new InputModel({
    initialValue: '',
    placeholder: (t) => t('customerSpecification.uin', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
    parser: (value) => (value as string).replace(/[^0-9+]/g, ''),
  });

  constructor() {
    super();

    this.fieldUin = [this.ingotNumber, this.weight, this.ligatureWeight, this.metalPurity, this.uin];
    this.fieldYear = [this.ingotNumber, this.weight, this.ligatureWeight, this.metalPurity, this.year];
    this.fields = [...this.fieldUin, this.year];
  }

  getFields = (value: IngotIdType): BaseFieldModel<any>[] => {
    if (value === IngotIdType.year) {
      return this.fieldYear;
    }

    return this.fieldUin;
  };

  reset(): void {
    this.fields.forEach((field) => field.reset());
  }

  static formJson(data: IngotServer): IngotModel {
    const model = new IngotModel();
    model.ingotNumber.change(data.bar_number);
    model.weight.change(data.weight.toString());
    model.ligatureWeight.change(data.ligature_weight ? data.ligature_weight.toString() : '');
    model.metalPurity.change(data.metal_purity.toString());
    model.year.change(new Date(Number(data.year), 0, 1));
    model.uin.change(data.uin?.toString() || '');

    return model;
  }
}
