import { StepProps } from 'antd';
import * as React from 'react';

import { mapStageTypeToLabel, mapUserTypeToStageTypes, StageType } from '@/entities/stage';
import { UserType } from '@/entities/user';
import { Nullable } from '@/shared/types/values';
import { TransComp } from '@/shared/ui';

type Params = {
  userType: Nullable<UserType>;
  currentType: StageType;
  isTradeFinished: boolean;
};

export const getStepItems = ({ currentType, userType, isTradeFinished }: Params): StepProps[] => {
  const stageTypes = userType ? mapUserTypeToStageTypes[userType] : [];
  const currentTypeIndex = stageTypes.findIndex((type) => type === currentType);

  return stageTypes.map<StepProps>((type, typeIndex) => ({
    title: <TransComp>{mapStageTypeToLabel[type]}</TransComp>,
    disabled: typeIndex > currentTypeIndex,
    status: isTradeFinished || typeIndex < currentTypeIndex ? 'finish' : type === currentType ? 'process' : 'wait',
  }));
};
