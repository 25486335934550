export const profile = {
  en: {
    title: 'Profile',
    menu: {
      me: 'My profile',
      company: 'Company',
      notifications: 'Notifications',
    },
    passwordFields: {
      title: 'Change password',
      currentPassword: 'Current password',
      newPassword: 'New password',
      repeatPassword: 'Repeat password',
    },
    errors: {
      invalidCurrentPassword: 'Invalid current password',
    },
    messages: {
      errorUnknown: 'An unknown error has occurred',
      editProfileSuccess: 'Profile updated successfully',
      editProfileError: 'An error occurred while updating the profile',
      editCompanySuccess: 'Company details successfully updated',
      editCompanyError: 'An error occurred while updating the company',
      passwordUpdated: 'Password successfully updated',
      errorUpdatingPassword: 'There was an error updating your password.',
    },
    button: {
      buttonSave: 'Save changes',
      buttonReset: 'Cancel changes',
      buttonChange: 'Change password',
    },
  },
  ru: {
    title: 'Профиль',
    menu: {
      me: 'Мой профиль',
      company: 'Компания',
      notifications: 'Уведомления',
    },
    passwordFields: {
      title: 'Изменение пароля',
      currentPassword: 'Текущий пароль',
      newPassword: 'Новый пароль',
      repeatPassword: 'Повторите пароль',
    },
    errors: {
      invalidCurrentPassword: 'Неверный текущий пароль',
    },
    messages: {
      errorUnknown: 'Произошла неизвестная ошибка',
      editProfileSuccess: 'Профиль успешно обновлен',
      editProfileError: 'Произошла ошибка при обновлении профиля',
      editCompanySuccess: 'Данные о компании успешно обновлены',
      editCompanyError: 'Произошла ошибка при обновлении компании',
      passwordUpdated: 'Пароль успешно обновлен',
      errorUpdatingPassword: 'Произошла ошибка при обновлении пароля',
    },
    button: {
      buttonSave: 'Сохранить изменения',
      buttonReset: 'Отменить изменения',
      buttonChange: 'Изменить пароль',
    },
  },
  zh: {
    title: '个人资料',
    menu: {
      me: '我的个人资料',
      company: '公司',
      notifications: '通知',
    },
    passwordFields: {
      title: '修改密码',
      currentPassword: '当前密码',
      newPassword: '新密码',
      repeatPassword: '重复密码',
    },
    errors: {
      invalidCurrentPassword: '当前密码不正确',
    },
    messages: {
      errorUnknown: '发生未知错误',
      editProfileSuccess: '个人资料已成功更新',
      editProfileError: '更新您的个人资料时出错',
      editCompanySuccess: '公司信息更新成功',
      editCompanyError: '更新公司时出错',
      passwordUpdated: '密码更新成功',
      errorUpdatingPassword: '更新密码时出错',
    },
    button: {
      buttonSave: '保存更改',
      buttonReset: '取消更改',
      buttonChange: '取消更改',
    },
  },
};
