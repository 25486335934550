import { Flex } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { UploadOptionalFileModel } from '@/entities/file';
import { FormUpload } from '@/shared/ui/form/FormUpload';
import { ApproveButton } from '@/shared/ui/form/buttons';

type UploadSpecificationProps = {
  model: UploadOptionalFileModel;
};

const UploadOptionalFile: React.FC<UploadSpecificationProps> = ({ model }) => {
  const { disabled, isUploaded, isError, isInitializedByValue, loadingStage, uploadFile } = model;

  return (
    <Flex vertical gap={8}>
      <FormUpload model={model} disabled={isInitializedByValue} />
      <ApproveButton
        loading={loadingStage.isLoading}
        disabled={disabled || !isUploaded || isError || isInitializedByValue}
        onClick={uploadFile}
      />
    </Flex>
  );
};

export default observer(UploadOptionalFile);
