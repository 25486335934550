import { Button, Flex, Image, Typography } from 'antd';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks';

import ErrorBoundaryIcon from '../../shared/assets/ErrorBoundaryIcon.png';

import s from './ErrorBoundary.module.scss';

const ErrorBoundary: React.FC = () => {
  const { t } = useTypedTranslation('shared');

  return (
    <Flex vertical className={s.container}>
      <Image src={ErrorBoundaryIcon} preview={false} width={250} />
      <Typography.Title level={5} className={s.container_title}>
        {t('errorBoundary.title')}
      </Typography.Title>
      <Typography.Text className={s.container_subtitle}>{t('errorBoundary.subTitle')}</Typography.Text>
      <Button type="primary" onClick={() => window.location.reload()}>
        {t('errorBoundary.buttonText')}
      </Button>
    </Flex>
  );
};

export default ErrorBoundary;
