import { IRootStore } from '@/app/store';

import { MyBidServer } from '../types';

import { BidModel } from './BidModel';

export class MyBidModel extends BidModel {
  static fromJson(server: MyBidServer, rootStore: IRootStore): MyBidModel {
    return new MyBidModel({ ...BidModel.mapServerToClient({ server, rootStore }) });
  }
}
