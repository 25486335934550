import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { BaseButtonProps } from '../types';

import s from './BackButton.module.scss';

type BackButtonProps = BaseButtonProps;

const BackButton: React.FC<BackButtonProps> = ({ className, ...props }) => {
  return <Button className={cn(s.button, className)} icon={<ArrowLeftOutlined />} {...props} />;
};

export default BackButton;
