import { BaseResponse } from '@kts-front/types';

import { DocumentFileType, GenerationFieldsModel } from '@/entities/file';
import { Incoterms, InvoiceDocPayload, InvoiceDocResponse, Packing } from '@/entities/stage/types';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { SelectModel } from '@/shared/model/form/SelectModel';
import { Nullable } from '@/shared/types/values';
import { numberParser } from '@/shared/utils';
import { formatDate } from '@/shared/utils/formatDate';
import { emptyValueValidator, stringLengthValidator } from '@/shared/utils/validators';

export class InvoiceFiedsModel<DocType extends DocumentFileType> extends GenerationFieldsModel<
  DocType,
  InvoiceDocPayload
> {
  readonly date = new DatePickerModel({
    initialValue: null,
    label: (t) => t('invoiceForm.date', { ns: 'stage' }),
    placeholder: (t) => t('placeholders.date', { ns: 'shared' }),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly countryId = new SelectModel<number>({
    initialValue: null,
    label: (t) => t('invoiceForm.country', { ns: 'stage' }),
    placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
  });
  readonly flightNumber = new InputModel({
    initialValue: '',
    label: (t) => t('invoiceForm.flightNumber', { ns: 'stage' }),
    placeholder: (t) => t('invoiceForm.flightNumberPlaceholder', { ns: 'stage' }),
    required: true,
    validators: [emptyValueValidator(), stringLengthValidator(128)],
  });
  readonly packing = new SelectModel<Packing>({
    initialValue: null,
    label: (t) => t('invoiceForm.packing', { ns: 'stage' }),
    placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly incoterms = new SelectModel<Incoterms>({
    initialValue: null,
    label: (t) => t('invoiceForm.incoterms', { ns: 'stage' }),
    placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly signatureId = new SelectModel<number>({
    initialValue: null,
    label: (t) => t('invoiceForm.signature', { ns: 'stage' }),
    placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly totalSum = new InputModel({
    initialValue: '',
    label: (t) => t('invoiceForm.totalSum', { ns: 'stage' }),
    placeholder: (t) => t('placeholders.sum', { ns: 'shared' }),
    parser: numberParser(),
    validators: [emptyValueValidator(), stringLengthValidator(16)],
    required: true,
  });

  get isCommercialInvoice() {
    return this._docType === DocumentFileType.customerCommercialInvoice;
  }

  get isExportInvoice() {
    return this._docType === DocumentFileType.exportInvoice;
  }

  get fields() {
    const coreFields = [this.date, this.countryId, this.flightNumber, this.packing, this.incoterms, this.signatureId];

    if (this.isExportInvoice) {
      coreFields.push(this.totalSum);
    }

    return coreFields;
  }

  toJson(): Nullable<InvoiceDocPayload> {
    if (
      !this.date.value ||
      !this.flightNumber.value ||
      !this.packing.value ||
      !this.incoterms.value ||
      !this.signatureId.value
    ) {
      return null;
    }

    if (this.isExportInvoice && !this.totalSum.value) {
      return null;
    }

    return {
      date: formatDate(this.date.value),
      flight_number: this.flightNumber.value,
      packing: this.packing.value,
      incoterms: this.incoterms.value,
      signatory_id: this.signatureId.value,
      ...(this.countryId.value ? { country_of_manufacture_id: this.countryId.value } : {}),
      ...(this.isExportInvoice ? { price_total: Number(this.totalSum.value) } : {}),
    };
  }

  async loadFieldsData(): Promise<BaseResponse> {
    if (this.loadingState.isLoading) {
      this._fieldsDataRequest.cancel();
    }

    this.loadingState.loading();

    const response = await this._fieldsDataRequest.call<InvoiceDocResponse>();

    if (response.isError) {
      this.loadingState.error();

      return response;
    }

    this._fillFieldsByResponseData(response.data);
    this.loadingState.success();

    return response;
  }

  private readonly _fillFieldsByResponseData = (data: InvoiceDocResponse): void => {
    this.date.change(data.date ? new Date(data.date) : null);
    this.countryId.change(data.country_of_manufacture_id ?? null);
    this.flightNumber.change(data.flight_number ?? '');
    this.packing.change(data.packing ?? null);
    this.incoterms.change(data.incoterms ?? null);
    this.signatureId.change(data.signatory_id ?? null);
    this.totalSum.change(data.price_total ? String(data.price_total) : '');
  };
}
