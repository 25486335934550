import { computed, makeObservable } from 'mobx';

import { ID } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

import { AccountServer } from '../types';

type Params = {
  id: ID;
  link: string;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  username: Nullable<string>;
};

export class AccountModel {
  readonly id: ID;
  readonly link: string;
  readonly firstName: Nullable<string>;
  readonly lastName: Nullable<string>;
  readonly username: Nullable<string>;

  constructor({ id, link, firstName, lastName, username }: Params) {
    this.id = id;
    this.link = link;
    this.firstName = firstName;
    this.lastName = lastName;
    this.username = username;

    makeObservable(this, {
      accountName: computed,
    });
  }

  get accountName(): Nullable<string> {
    if (this.username) {
      return this.username;
    }

    if (this.firstName || this.lastName) {
      return `${this.firstName ?? ''} ${this.lastName ?? ''}`.trim();
    }

    return null;
  }

  static fromJson({ account_id, account_link, first_name, last_name, username }: AccountServer): AccountModel {
    return new AccountModel({
      id: account_id,
      link: account_link,
      firstName: first_name,
      lastName: last_name,
      username,
    });
  }
}
