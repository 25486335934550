import { DocumentFileType } from '@/entities/file';
import { CustomerShipmentStep } from '@/entities/stage/types';
import { Nullable } from '@/shared/types/values';

import { DocTypesOptions } from '../../fieldsModel/WaitingFieldsWithFilesModel';

export type DestinationCountryDocs =
  | DocumentFileType.customerAcceptanceCertificate
  | DocumentFileType.customerAdditionalAgreement
  | DocumentFileType.customerCommercialInvoice
  | DocumentFileType.customerSpecification;

const T_OPTIONS = { ns: 'shipmentStage' } as const;

export const destinationCountryDocsOptions: DocTypesOptions<DestinationCountryDocs> = [
  {
    docType: DocumentFileType.customerCommercialInvoice,
    description: (t) =>
      t(`customer.destinationCountryDocs.docsDescription.${DocumentFileType.customerCommercialInvoice}`, T_OPTIONS),
  },
  {
    docType: DocumentFileType.customerSpecification,
    description: (t) =>
      t(`customer.destinationCountryDocs.docsDescription.${DocumentFileType.customerSpecification}`, T_OPTIONS),
  },
  {
    docType: DocumentFileType.customerAcceptanceCertificate,
    description: (t) =>
      t(`customer.destinationCountryDocs.docsDescription.${DocumentFileType.customerAcceptanceCertificate}`, T_OPTIONS),
  },
  {
    docType: DocumentFileType.customerAdditionalAgreement,
    description: (t) =>
      t(`customer.destinationCountryDocs.docsDescription.${DocumentFileType.customerAdditionalAgreement}`, T_OPTIONS),
  },
];

export const STEPS_ORDER_DIRECT: Record<CustomerShipmentStep, number> = {
  [CustomerShipmentStep.shipmentType]: 0,
  [CustomerShipmentStep.destinationCountryDocs]: 0,
  [CustomerShipmentStep.transfer]: 1,
};

export const mapStepToNumber = (step: Nullable<CustomerShipmentStep>, isShipmentTypeDirect: boolean): number => {
  if (!step || !isShipmentTypeDirect) {
    return -1;
  }

  return STEPS_ORDER_DIRECT[step] ?? -1;
};
