import { CustomerServer, SupplierServer, UserType } from '@/entities/user';
import { ApiListResponse } from '@/shared/types/api';
import { MapDataFieldsToNull, SortDirection } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

/**
 * @field supplierSpecification - спецификация поставщика
 * @field customerSpecification - спецификация покупателя
 * @field arbitraryDocument - произвольный документ
 *
 * @field deliveryConfirmation - подтверждение поставки
 * @field confirmationSigning - подписание подтверждения
 *
 * @field paymentBill - счет оплаты
 * @field paidBill - оплаченный счет
 *
 * @field exportInvoice - инвойс для экспорта 4%
 * @field customerCommercialInvoice - коммерческий инвойс покупателя
 *
 * @field awbForDestinationCountry - AWB для страны назначения
 * @field awbForTransitCountry - AWB для страны транзита
 *
 * @field customerAcceptanceCertificate - акт приема-передачи покупателя
 * @field customerAdditionalAgreement - дополнительный соглашение покупателя
 *
 * @field supplierAcceptanceCertificate - акт приема-передачи поставщика
 * @field supplierAdditionalAgreement - дополнительный соглашение поставщика
 *
 * @field signedCustomerAcceptanceCertificate - подписанный акт приема-передачи покупателя
 * @field signedCustomerAdditionalAgreement - подписанный дополнительный соглашение покупателя
 *
 * @field warrantyLoa - доверенность LOA
 * @field indirectTaxesPaymentCertificate - справка об уплате налогов
 * @field authorizationLetter - авторизационное письмо
 * @field finalizationPhoto - фото
 */
export enum DocumentFileType {
  supplierSpecification = 'supplier_specification',
  customerSpecification = 'customer_specification',
  arbitraryDocuments = 'arbitrary_documents',

  deliveryConfirmation = 'delivery_confirmation',
  confirmationSigning = 'confirmation_signing',

  paymentBill = 'payment_bill',
  paidBill = 'paid_bill',

  exportInvoice = 'export_invoice',
  customerCommercialInvoice = 'customer_commercial_invoice',

  awbForDirectCountry = 'awb_for_direct_country',
  awbForTransitCountry = 'awb_for_transit_country',
  awbForDestinationCountry = 'awb_for_destination_country',

  customerAcceptanceCertificate = 'customer_acceptance_certificate',
  customerAdditionalAgreement = 'customer_additional_agreement',

  supplierAcceptanceCertificate = 'supplier_acceptance_certificate',
  supplierAdditionalAgreement = 'supplier_additional_agreement',

  signedCustomerAcceptanceCertificate = 'signed_customer_acceptance_certificate',
  signedCustomerAdditionalAgreement = 'signed_customer_additional_agreement',

  warrantyLoa = 'warranty_loa',
  indirectTaxesPaymentCertificate = 'indirect_taxes_payment_certificate',
  authorizationLetter = 'authorization_letter',
  finalizationPhoto = 'finalization_photo',
}

export enum GenerationStatus {
  creating = 'creating',
  success = 'success',
  error = 'error',
}

export type SuccessGenerationServer = {
  id: number;
  status: GenerationStatus.success;
  link: string;
};

export type GenerationServer =
  | {
      id: number;
      status: GenerationStatus.creating | GenerationStatus.error;
      link: null;
    }
  | SuccessGenerationServer;

export type BaseFileServer = {
  id: number;
  link: string;
  name: string;
  size?: number;
};

export type FileServer = Required<BaseFileServer> & {
  uploaded_by: {
    id: number;
    role: UserType.admin | UserType.manager | UserType.supplier;
  };
};

export type FileGenerationServer =
  | (MapDataFieldsToNull<FileServer> & { generation: GenerationServer })
  | (FileServer & { generation: null });

export type DocumentServer = {
  document: FileServer;
};

export type DocumentsServer = {
  documents: FileServer[];
};

export enum DocumentCheckStatus {
  waiting = 'waiting',
  approved = 'approved',
  rejected = 'rejected',
}

export type DocumentWithStatusServer = {
  document: Nullable<FileServer>;
  status: DocumentCheckStatus;
  error_message: Nullable<string>;
};

export type DocumentsWithStatusServer = {
  documents: Nullable<FileServer[]>;
  status: DocumentCheckStatus;
  error_message: Nullable<string>;
};

export type DocumentInfoServer = Omit<FileServer, 'uploaded_by'> & {
  created_at: string;
  description?: string;
  doc_type: DocumentFileType;
  roles: Array<UserType>;
  trade_id: number;
  original: boolean;
  trade: DocumentTradeServer;
};

export type DocumentListPayload = {
  limit: number;
  offset: number;
} & Partial<{
  search: string;
  doc_type: Array<DocumentFileType>;
  order: Exclude<SortDirection, SortDirection.none>;
  role: Array<UserType>;
  original: boolean;
  trade_id: number;
}>;

export type DownloadDocsArchivePayload = {
  token: string;
} & Partial<{
  search: string;
  doc_type: Array<DocumentFileType>;
  role: Array<UserType>;
  trade_id: number;
}>;

export type UploadDocumentsPayload = {
  files: Array<File>;
  doc_types: Array<DocumentFileType>;
  doc_descriptions?: Array<string>;
  visibility?: Array<Array<UserType>>;
};

export type UpdateDocumentPayload = {
  file: File;
  description?: string;
  visibility?: Array<UserType>;
};

export type DocumentListResponse = ApiListResponse<DocumentInfoServer>;

export type DocumentTradeServer = {
  id: number;
  weight: number;
  product_id: Nullable<number>;
  product_type_id: number;
  weight_unit_id: number;
  customer: Nullable<CustomerServer>;
  supplier: Nullable<SupplierServer>;
};
