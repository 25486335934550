import { BellFilled } from '@ant-design/icons';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';

import s from './NotificationsBell.module.scss';

type NotificationsBellProps = {
  className?: string;
  color?: string;
};

const NotificationsBell: React.FC<NotificationsBellProps> = observer(({ className, ...props }) => {
  const { userStore } = useRootStore();

  return (
    <BellFilled
      className={cn(s.bell, userStore.newNotificationsCount > 0 && s['bell_has-notifications'], className)}
      {...props}
    />
  );
});

export default React.memo(NotificationsBell);
