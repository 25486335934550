import * as React from 'react';

import { IconProps } from '../types';

const Read: React.FC<IconProps> = ({ size = 20, ...props }) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.75125 10.9564L10.6338 11.8389L15.4831 6.98951C15.7272 6.74547 16.1228 6.74547 16.3669 6.98951C16.6109 7.23355 16.6109 7.62922 16.3669 7.87326L11.3409 12.8993C10.9503 13.2898 10.3172 13.2898 9.92664 12.8993L7.09813 10.0708C6.85408 9.82672 6.85408 9.43105 7.09813 9.18701C7.34217 8.94297 7.73783 8.94297 7.98188 9.18701L8.86813 10.0733L9.75125 10.9564ZM10.1931 9.62947C9.94975 9.38613 9.94973 8.99162 10.193 8.74826L12.4069 6.53398C12.6502 6.29058 13.0448 6.29056 13.2882 6.53393C13.5315 6.77726 13.5315 7.17178 13.2882 7.41514L11.0744 9.62942C10.831 9.87281 10.4364 9.87284 10.1931 9.62947ZM7.54406 12.2817C7.78793 12.5256 7.78793 12.921 7.54406 13.1648C7.30019 13.4087 6.90481 13.4087 6.66094 13.1648L3.56688 10.0708C3.32283 9.82672 3.32283 9.43105 3.56688 9.18701C3.81092 8.94297 4.20658 8.94297 4.45062 9.18701L4.89188 9.62826L4.89125 9.62889L7.54406 12.2817Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default React.memo(Read);
