import { OtherCreationStageDataServer, StageModelJsonParams, StageType } from '@/entities/stage';

import { BaseStageModel, BaseStageModelParams } from '../BaseStageModel';

type Params = BaseStageModelParams<null>;

export class OtherCreationStageModel extends BaseStageModel<StageType.creation, null> {
  constructor(params: Params) {
    super({
      type: StageType.creation,
      ...params,
    });
  }

  get isCreating(): boolean {
    return this.type === this.currentType;
  }

  get currentStep(): number {
    return 0;
  }

  get steps(): [] {
    return [];
  }

  destroy(): void {
    super.destroy();
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<OtherCreationStageDataServer>): OtherCreationStageModel {
    return new OtherCreationStageModel({
      ...BaseStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
