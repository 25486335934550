import { Options } from '@/shared/types/values';

import { BaseFieldModel, BaseFieldModelParams } from './BaseFieldModel';

export type CheckboxListModelParams<Value> = BaseFieldModelParams<Value[]> & {
  options: Options<Value>;
};

type CheckboxListModelProps<Value> = {
  value: Value[];
  onChange: (checkedValues: Value[]) => void;
  disabled: boolean;
  options: Options<Value>;
};
export class CheckboxListModel<Value> extends BaseFieldModel<Value[]> {
  protected readonly _options: Options<Value>;

  constructor({ options, ...params }: CheckboxListModelParams<Value>) {
    super(params);

    this._options = options;
  }

  get props(): CheckboxListModelProps<Value> {
    return {
      value: this._value,
      onChange: this.change,
      disabled: this.disabled,
      options: this._options,
    };
  }
}
