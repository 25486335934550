import { Typography } from 'antd/lib';
import cn from 'classnames';
import * as React from 'react';

import { TransComp } from '@/shared/ui';

import { AccountModel } from '../../model';
import { NotificationService } from '../../types';

import { accountTagProps } from './config';

import s from './AccountTag.module.scss';

type AccountTagProps = {
  className?: string;
  service: NotificationService;
  account: AccountModel;
};

const AccountTag: React.FC<AccountTagProps> = ({ className, service, account }) => {
  const tagProps = accountTagProps[service];

  if (!tagProps) {
    return null;
  }

  return (
    <Typography.Link
      className={cn(s.tag, className)}
      href={account.link}
      target="_blank"
      style={{ backgroundColor: tagProps.color }}
    >
      {tagProps.icon}
      <TransComp>
        {account.accountName ?? ((t) => t(`settings.accounts.${service}`, { ns: 'notifications' }))}
      </TransComp>
    </Typography.Link>
  );
};

export default AccountTag;
