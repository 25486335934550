import { UserType } from '@/entities/user';
import { TranslationString } from '@/shared/types/localization';

type UserTypeTagConfig = {
  color: string;
  label: TranslationString;
};

export const userTypeTagMap: Record<UserType, UserTypeTagConfig> = {
  [UserType.customer]: {
    color: 'orange',
    label: (t) => t('userRoles.customer', { ns: 'user' }),
  },
  [UserType.supplier]: {
    color: 'blue',
    label: (t) => t('userRoles.supplier', { ns: 'user' }),
  },
  [UserType.agent]: {
    color: 'purple',
    label: (t) => t('userRoles.agent', { ns: 'user' }),
  },
  [UserType.manager]: {
    color: 'magenta',
    label: (t) => t('userRoles.manager', { ns: 'user' }),
  },
  [UserType.admin]: {
    color: 'red',
    label: (t) => t('userRoles.administrator', { ns: 'user' }),
  },
};
