import * as React from 'react';

import { IconProps } from '../../types';

const RU: React.FC<IconProps> = ({ size = 20, ...props }) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4345_124391)">
        <circle cx="10" cy="10" r="10" fill="#D9D9D9" />
        <mask
          id="mask0_4345_124391"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="-6"
          y="-2"
          width="32"
          height="24"
        >
          <path d="M-5.48779 -1.81818H25.8626V21.6946H-5.48779V-1.81818Z" fill="white" />
        </mask>
        <g mask="url(#mask0_4345_124391)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-5.48779 -1.81818V21.6946H25.8626V-1.81818H-5.48779Z"
            fill="#3D58DB"
          />
          <mask
            id="mask1_4345_124391"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="-6"
            y="-2"
            width="32"
            height="24"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-5.48779 -1.81818V21.6946H25.8626V-1.81818H-5.48779Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_4345_124391)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-5.48779 -1.81818V6.01942H25.8626V-1.81818H-5.48779Z"
              fill="#F7FCFF"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-5.48779 13.8571V21.6947H25.8626V13.8571H-5.48779Z"
              fill="#C51918"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4345_124391">
          <rect width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RU;
