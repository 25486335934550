import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks';
import { TranslationNode } from '@/shared/types/localization';
import { isFunction } from '@/shared/types/typesGuard';

type TransCompProps = {
  children: TranslationNode;
};

const TransComp: React.FC<TransCompProps> = ({ children }) => {
  const { t } = useTypedTranslation();

  if (isFunction(children)) {
    return children(t);
  }

  return children;
};

export default TransComp;
