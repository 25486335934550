export const menu = {
  en: {
    clients: 'Clients',
    agents: 'Agents',
    trades: 'Trades',
    documents: 'Documents',
    profile: 'Profile',
    notifications: 'Notifications',
    bids: 'Bids',
    logout: 'Logout',
    chats: 'Chats',
  },
  ru: {
    clients: 'Клиенты',
    agents: 'Агенты',
    trades: 'Сделки',
    documents: 'Документы',
    profile: 'Профиль',
    notifications: 'Уведомления',
    bids: 'Заявки',
    logout: 'Выход',
    chats: 'Чаты',
  },
  zh: {
    clients: '客户',
    agents: '代办',
    trades: '交易',
    documents: '文件',
    profile: '个人资料',
    notifications: '通知',
    logout: '退出',
    chats: '聊天',
  },
};
