import { ApiStore, HttpAuthorizationScheme } from '@kts-front/call-api';
import { message } from 'antd';
import axios, { AxiosRequestTransformer } from 'axios';
import cn from 'classnames';
import { t } from 'i18next';
import { nanoid } from 'nanoid';

import { LSKey } from '@/shared/config/storageKeys';
import { ownLocalStorage, ownSessionStorage } from '@/shared/model/storage';
import s from '@/shared/styles/notification.module.scss';

import { ApiErrorCode, ApiErrorData } from '../types/api';
import { defaultLanguage } from '../types/localization';
import { Nullable } from '../types/values';

import { apiPrefix } from './urls';

const LOCALE_KEY = 'X-Arvar-Locale';

const transformRequestHeaders: Array<AxiosRequestTransformer> = [
  (data, headers) => {
    headers[LOCALE_KEY] = ownLocalStorage.getItem(LSKey.locale) || defaultLanguage;

    return data;
  },
];

let key: Nullable<string> = null;

export const apiStore = new ApiStore({
  baseUrl: apiPrefix,
  getAuthorizationCallback: () => {
    const token = ownSessionStorage.getItem(LSKey.accessToken) || ownLocalStorage.getItem(LSKey.accessToken);

    if (token === null) {
      return null;
    }

    return {
      scheme: HttpAuthorizationScheme.Bearer,
      data: token,
    };
  },
  transformRequest: axios.defaults.transformRequest
    ? [
        ...transformRequestHeaders,
        ...(Array.isArray(axios.defaults.transformRequest)
          ? axios.defaults.transformRequest
          : [axios.defaults.transformRequest]),
      ]
    : transformRequestHeaders,

  transformResponse: (stringifyResponse) => {
    const response = JSON.parse(stringifyResponse) as Nullable<ApiErrorData>;

    // В случае ошибки (протухший токен) показываем сообщение.
    if (response?.status === 'error' && response.code === ApiErrorCode.tokenNotValid && key === null) {
      key = nanoid();
      message.open({
        key,
        className: cn(s.message, s.message_error),
        type: 'error',
        content: t(`errors.${ApiErrorCode.tokenNotValid}`, { ns: 'login' }),
        onClose: () => {
          key = null;
        },
      });
    }

    return response;
  },
});
