export const confirmationStage = {
  en: {
    manager: {
      deliveryConfirmation: {
        title: 'Confirm delivery',
        description: 'Upload delivery confirmation and click “Approve”',
      },
      confirmationVerifying: {
        title: 'Verify delivery confirmation',
        description:
          'Download the document from the previous step. Check that all information is correct and click the “Everything is correct” button to proceed further.',
      },
      confirmationSigning: {
        title: 'Sign the delivery confirmation',
        description: 'Upload signed confirmation and click “Sign”',
        approveButton: 'Sign',
      },
    },
    supplier: {
      deliveryConfirmation: {
        title: 'Confirm delivery',
        description: 'Upload delivery confirmation and click “Approve”',
      },
      confirmationVerifying: {
        title: 'Waiting for confirmation check',
        waitDescription: 'Wait for the manager to approve your confirmation',
        approveDescription: 'Manager approved your confirmation',
      },
      confirmationSigning: {
        title: 'Waiting for signed confirmation',
        description: 'Wait for the manager to sign the confirmation',
      },
    },
  },
  ru: {
    manager: {
      deliveryConfirmation: {
        title: 'Подтвердите поставку',
        description: 'Загрузите подтверждение поставки и нажмите “Подтвердить”',
      },
      confirmationVerifying: {
        title: 'Проверьте подтверждение поставки',
        description:
          'Cкачайте документ из предыдущего шага. Проверьте, что вся информация верна и нажмите кнопку “Все правильно”, чтобы пройти дальше.',
      },
      confirmationSigning: {
        title: 'Подпишите подтверждение поставки',
        description: 'Загрузите подписанное подтверждение поставки и нажмите “Подписать”',
        approveButton: 'Подписать',
      },
    },
    supplier: {
      deliveryConfirmation: {
        title: 'Подтвердите поставку',
        description: 'Загрузите подтверждение поставки и нажмите “Подтвердить”',
      },
      confirmationVerifying: {
        title: 'Ожидание проверки подтверждения',
        waitDescription: 'Дождитесь, пока менеджер одобрит ваше подтверждение',
        approveDescription: 'Менеджер отметил документ правильным',
      },
      confirmationSigning: {
        title: 'Ожидание подписанного потверждения',
        description: 'Дождитесь, пока менеджер подпишет подтверждение',
      },
    },
  },
  zh: {
    manager: {
      deliveryConfirmation: {
        title: '确认交货',
        description: '请上传交货确认信息并点击“确认”',
      },
      confirmationVerifying: {
        title: '请检查交货确认',
        description: '下载上一步的文档。请检查所有信息是否正确，然后单击“一切正确”按钮以继续。',
      },
      confirmationSigning: {
        title: '签署交货确认',
        description: '请上传签署的交货确认信息并点击“签署”',
        approveButton: '签署',
      },
    },
    supplier: {
      deliveryConfirmation: {
        title: '确认交货',
        description: '请上传交货确认信息并点击“确认”',
      },
      confirmationVerifying: {
        title: '等待确认检查',
        waitDescription: '请等待经理批准您的确认',
        approveDescription: '经理将文档标记为正确',
      },
      confirmationSigning: {
        title: '等待已签署的确认',
        description: '请等待经理签字确认',
      },
    },
  },
};
