import { DropdownProps, MenuProps } from 'antd';
import { action, computed, makeObservable } from 'mobx';

import { ToggleModel } from '../../ToggleModel';
import { ValueModel } from '../../ValueModel';
import { CheckboxListModel, CheckboxListModelParams } from '../../form/CheckboxListModel';

import { getMenuItems } from './config';

export class CheckboxListFilterModel<Value extends string> extends CheckboxListModel<Value> {
  protected readonly _valueForJson: ValueModel<Array<Value>>;

  readonly dropdownState = new ToggleModel();

  constructor(params: CheckboxListModelParams<Value>) {
    super(params);

    this._valueForJson = new ValueModel(params.initialValue);

    makeObservable(this, {
      menu: computed,
      isFiltered: computed,
      changed: computed,
      toJson: computed,
      resetDisabled: computed,

      openChange: action.bound,
      reset: action.bound,
      apply: action.bound,
    });
  }

  get menu(): MenuProps {
    return {
      items: getMenuItems({
        values: this.value,
        options: this._options,
        onChange: this._onChange,
      }),
    };
  }

  get toJson(): Value[] {
    return this._valueForJson.value;
  }

  get isFiltered(): boolean {
    return this.toJson.length > 0;
  }

  get changed(): boolean {
    return (
      this._value.length !== this._valueForJson.value.length ||
      this._value.some((value, idx) => value !== this._valueForJson.value[idx])
    );
  }

  get resetDisabled(): boolean {
    return this._valueForJson.value.length === 0 && this._value.length === 0;
  }

  openChange: DropdownProps['onOpenChange'] = (open, { source }) => {
    if (source === 'trigger') {
      this.dropdownState.toggle();
      !open && this.change([...this._valueForJson.value]);
    }
  };

  reset(): void {
    this._valueForJson.change([]);
    this.change([]);
    this.dropdownState.close();
  }

  apply(): void {
    this._valueForJson.change([...this._value]);
    this.dropdownState.close();
  }

  protected readonly _onChange = (value: Value): void => {
    if (this._value.includes(value)) {
      this.change(this._value.filter((val) => val !== value));
    } else {
      this.change([...this._value, value]);
    }
  };

  static fromJson<T extends string>(params: CheckboxListModelParams<T>): CheckboxListFilterModel<T> {
    return new CheckboxListFilterModel<T>({
      ...params,
      initialValue: params.initialValue,
    });
  }
}
