import { DictionaryModel, DictionaryServer, IDictionary, UnitDictionaryServer } from '@/entities/dictionary';
import { StageType } from '@/entities/stage';
import { ICustomer, ISupplier } from '@/entities/user';
import { Amount, Nullable } from '@/shared/types/values';

import { IBaseTrade, TradeStatus } from '../types';

export abstract class BaseTradeModel<Trade extends IBaseTrade> {
  readonly id: number;
  readonly tradeStatus: TradeStatus;
  readonly stageType: StageType;
  readonly productTypeId: number;
  readonly productId: Nullable<number>;
  readonly weight: number;
  readonly ligatureWeight: Nullable<number>;
  readonly weightUnitId: number;
  readonly sellAmount: Nullable<Amount>;
  readonly sellAmountTotal: Nullable<Amount>;
  readonly customerPayment: Nullable<number>;
  readonly fixingDate: Date;
  readonly transitCountryCargoArrivalDate: Nullable<Date>;
  readonly transitCountryCargoShipmentDate: Nullable<Date>;
  readonly destinationCountryCargoArrivalDate: Nullable<Date>;
  readonly cargoDeliveredDate: Nullable<Date>;
  readonly paymentDate: Nullable<Date>;
  readonly customer: Nullable<ICustomer>;
  readonly supplier: Nullable<ISupplier>;
  readonly etdDate: Nullable<Date>;

  constructor(data: Trade) {
    this.id = data.id;
    this.tradeStatus = data.tradeStatus;
    this.stageType = data.stageType;
    this.sellAmount = data.sellAmount;
    this.sellAmountTotal = data.sellAmountTotal;
    this.productTypeId = data.productTypeId;
    this.productId = data.productId;
    this.fixingDate = data.fixingDate;
    this.weight = data.weight;
    this.ligatureWeight = data.ligatureWeight;
    this.weightUnitId = data.weightUnitId;
    this.customer = data.customer;
    this.supplier = data.supplier;
    this.transitCountryCargoArrivalDate = data.transitCountryCargoArrivalDate;
    this.transitCountryCargoShipmentDate = data.transitCountryCargoShipmentDate;
    this.destinationCountryCargoArrivalDate = data.destinationCountryCargoArrivalDate;
    this.cargoDeliveredDate = data.cargoDeliveredDate;
    this.customerPayment = data.customerPayment;
    this.paymentDate = data.paymentDate;
    this.etdDate = data.etdDate;
  }

  static dateFromJson(json: Nullable<string>): Nullable<Date> {
    return json ? new Date(json) : null;
  }

  static amountFromJson(price: Nullable<number>, unit: Nullable<UnitDictionaryServer>): Nullable<Amount> {
    const amount: Nullable<Amount> =
      price && unit
        ? {
            sum: price,
            currency: {
              code: unit.name,
              symbol: unit.slug,
            },
          }
        : null;

    return amount;
  }

  static dictionaryFromJson(json: Nullable<DictionaryServer>): Nullable<IDictionary> {
    return json ? DictionaryModel.fromJson(json) : null;
  }
}
