import { IRootStore } from '@/app/store';

import { SellBidServer } from '../types';

import { BidModel } from './BidModel';

export class SellBidModel extends BidModel {
  static fromJson(server: SellBidServer, rootStore: IRootStore): SellBidModel {
    return new SellBidModel({ ...BidModel.mapServerToClient({ server, rootStore }) });
  }
}
