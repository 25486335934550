import { BaseResponse } from '@kts-front/types';

type CheckResponseData<Data> = (data: Data) => boolean;

type Params<Data> = {
  request(): Promise<BaseResponse<Data>>;
  checkSuccessResponseData?: CheckResponseData<Data>;
  checkErrorResponseData?: CheckResponseData<Data>;
  timeout: number;
};

export class RequestPollingModel<Data = undefined> {
  private _timer: ReturnType<typeof setTimeout> | null = null;

  private readonly _timeout: number;
  private readonly _request: () => Promise<BaseResponse<Data>>;
  private readonly _checkSuccessResponseData: CheckResponseData<Data>;
  private readonly _checkErrorResponseData: CheckResponseData<Data>;

  constructor({ request, checkSuccessResponseData, checkErrorResponseData, timeout }: Params<Data>) {
    this._timeout = timeout;
    this._request = request;
    this._checkSuccessResponseData = checkSuccessResponseData ?? (() => false);
    this._checkErrorResponseData = checkErrorResponseData ?? (() => false);
  }

  async watchProgress<D extends Data = Data>(): Promise<BaseResponse<D>> {
    return new Promise((resolve) => {
      const request = async () => {
        const response = await this._request();

        const isHasData = 'data' in response;

        if (response.isError || (isHasData && this._checkErrorResponseData(response.data as Data))) {
          this.stopPolling();
          resolve({ isError: true });
        } else if (isHasData && this._checkSuccessResponseData(response.data as Data)) {
          this.stopPolling();
          resolve(response as BaseResponse<D>);
        } else {
          this._timer = setTimeout(request, this._timeout);
        }
      };

      request();
    });
  }

  stopPolling = () => {
    if (this._timer) {
      clearTimeout(this._timer);
    }
  };
}
