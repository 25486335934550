import { BaseResponse } from '@kts-front/types';
import { UploadFile } from 'antd';
import { t } from 'i18next';
import { action, autorun, computed, IReactionDisposer, makeObservable } from 'mobx';

import { INotificationsStore, MessageType } from '@/entities/message';
import { ITradeWorkflowStore } from '@/entities/trade';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, RequestPollingModel, ToggleModel, ValueModel } from '@/shared/model';
import { BaseFieldModelParams } from '@/shared/model/form/BaseFieldModel';
import { UploadFileModel } from '@/shared/model/form/UploadFileModel';
import { TFunctionType, TranslationString } from '@/shared/types/localization';
import { Nullable } from '@/shared/types/values';
import { renderTranslationString } from '@/shared/utils/renderTranslation';

import {
  DocumentFileType,
  FileGenerationServer,
  GenerationServer,
  GenerationStatus,
  SuccessGenerationServer,
} from '../types';

import { FileModel } from './FileModel';
import { GenerationFieldsModel } from './GenerationFieldsModel';

export type UploadOrGenerationDocumentModelParams<DocType> = BaseFieldModelParams<UploadFile[]> & {
  generation: Nullable<GenerationServer>;
  fileName: TranslationString;
  docType: DocType;
  tradeWorkflowStore: ITradeWorkflowStore;
};

export abstract class UploadOrGenerationDocumentModel<
  DocType extends DocumentFileType,
  Payload,
  FieldsModel extends GenerationFieldsModel<DocType, Payload>,
> extends UploadFileModel {
  private readonly _docType: DocType;
  private readonly _generation: ValueModel<Nullable<GenerationServer>>;
  readonly fileName: TranslationString;

  readonly pollingState = new LoadingStageModel();
  private readonly _modalState = new ToggleModel();

  private readonly _tradeWorkflowStore: ITradeWorkflowStore;

  private readonly _disposer: IReactionDisposer;

  constructor({
    fileName,
    docType,
    generation,
    tradeWorkflowStore,
    ...params
  }: UploadOrGenerationDocumentModelParams<DocType>) {
    super(params);

    this._tradeWorkflowStore = tradeWorkflowStore;
    this.fileName = fileName;
    this._docType = docType;
    this._generation = new ValueModel<Nullable<GenerationServer>>(generation);

    makeObservable(this, {
      canBeGenerated: computed,

      generate: action.bound,
    });

    this._disposer = autorun(() => {
      const generation = this._generation.value;

      switch (generation?.status) {
        case GenerationStatus.creating:
          this._pollingGeneration();

          break;

        case GenerationStatus.success:
          this.change([
            {
              uid: this._name,
              name: this._name,
              url: generation.link,
            },
          ]);

          break;

        case GenerationStatus.error:
          this.notificationsStore.addNotification({
            type: MessageType.error,
            message: (t) => t('messages.generationError', { ns: 'stage' }),
          });

          break;
      }
    });
  }

  abstract get generationFields(): FieldsModel;

  get notificationsStore(): INotificationsStore {
    return this._tradeWorkflowStore.rootStore.notificationsStore;
  }

  get isOpenModal() {
    return this._modalState.isOpen;
  }

  get canBeGenerated(): boolean {
    const { rootStore, tradeInfo } = this._tradeWorkflowStore;

    if (!rootStore.dictionariesStore.loadingStage.isSuccess) {
      return false;
    }

    const productType = rootStore.dictionariesStore.productTypes.list.getEntity(tradeInfo.productTypeId);

    return Boolean(productType?.productList.length) && !this.isUploaded;
  }

  closeModal = () => {
    this.generationFields.reset();
    this._modalState.close();
  };

  openModal = async () => {
    const response = await this.generationFields.loadFieldsData();

    if (response.isError) {
      this.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('messages.loadFieldsDataError', { ns: 'stage' }),
      });
    }

    this._modalState.open();
  };

  async generate(): Promise<BaseResponse> {
    if (this.generationFields.loadingState.isLoading) {
      return { isError: true };
    }

    const isError = this.generationFields.validate();

    if (isError) {
      return { isError: true };
    }

    const payload = this.generationFields.toJson();

    if (!payload) {
      return { isError: true };
    }

    this.generationFields.loadingState.loading();

    const response = await this._postGenerationRequest.call({
      url: apiUrls.document.generationByDocType(this._docType),
      data: {
        ...payload,
        trade_id: this._tradeWorkflowStore.tradeId,
      },
    });

    if (response.isError) {
      this.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('messages.generationError', { ns: 'stage' }),
      });

      this.generationFields.loadingState.error();

      return { isError: true };
    }

    this._generation.change(response.data);

    this.closeModal();

    this.generationFields.loadingState.success();

    return { isError: false };
  }

  private async _pollingGeneration(): Promise<BaseResponse> {
    const generationId = this._generation.value?.id ?? null;

    if (this.pollingState.isLoading || generationId === null) {
      return { isError: true };
    }

    this.pollingState.loading();

    const checkGeneration = async (): Promise<BaseResponse<GenerationServer>> => {
      const response = await this._getGenerationRequest.call({
        url: apiUrls.document.generationById(generationId),
      });

      return response as BaseResponse<GenerationServer>;
    };

    const requestEvent = new RequestPollingModel({
      request: checkGeneration,
      checkSuccessResponseData: (data) => data.status !== GenerationStatus.creating,
      timeout: 3000,
    });

    const watchProgress = await requestEvent.watchProgress<SuccessGenerationServer>();

    if (watchProgress.isError) {
      this.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('messages.generationError', { ns: 'stage' }),
      });

      this.pollingState.error();

      return { isError: true };
    }

    this._generation.change(watchProgress.data);

    this.pollingState.success();

    return { isError: false };
  }

  private get _name() {
    return renderTranslationString(this.fileName, t as unknown as TFunctionType);
  }

  private readonly _getGenerationRequest = apiStore.createRequest<GenerationServer>();

  private readonly _postGenerationRequest = apiStore.createRequest<GenerationServer>({
    method: 'POST',
  });

  destroy(): void {
    this._disposer();
  }

  static paramsFromJson<DocType>({
    data,
    ...params
  }: Omit<UploadOrGenerationDocumentModelParams<DocType>, 'generation' | 'initialValue'> & {
    data?: FileGenerationServer;
  }): UploadOrGenerationDocumentModelParams<DocType> {
    return {
      ...params,
      generation: data?.generation ?? null,
      initialValue: FileModel.fileListFromJson(data?.generation ? null : data),
    };
  }
}
