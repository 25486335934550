import { ApiListResponse } from '@/shared/types/api';
import { ID } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

export enum ProductType {
  gemstone = 'gemstone',
  metal = 'metal',
  mineral = 'mineral',
}

export enum ProductShapeType {
  granules = 'granules',
  ingot = 'ingot',
}

export enum ProductWeightType {
  gram = 'gram',
  kg = 'kg',
  oz = 'oz',
  ton = 'ton',
}

export enum ProductMetalType {
  gold = 'gold',
  palladium = 'palladium',
  platinum = 'platinum',
  silver = 'silver',
}

export type DictionaryServer<T extends ID = number> = {
  id: T;
  name: string;
};

export type UnitDictionaryServer<T extends ID = number, S extends string = string> = DictionaryServer<T> & {
  slug: S;
};

export type ProductTypeDictionaryServer = UnitDictionaryServer<number, ProductType> & {
  products: Nullable<Array<UnitDictionaryServer>>;
  units: Array<UnitDictionaryServer>;
};

type ProductPrice = {
  date: string;
  current: number;
  previous: Nullable<number>;
  currency: UnitDictionaryServer;
};

export type ProductDictionaryServer = UnitDictionaryServer & {
  price: {
    spot: Nullable<ProductPrice>;
    london: Nullable<ProductPrice>;
  };
  shapes: Array<UnitDictionaryServer<number, ProductShapeType>>;
  units: Array<UnitDictionaryServer<number, ProductWeightType>>;
};

export type SingatoryDictionaryServer = DictionaryServer & {
  type: Nullable<string>;
  sign: Nullable<{ link: string }>;
};

export type DictionaryListResponse<T extends ID = number> = ApiListResponse<DictionaryServer<T>>;
export type UnitDictionaryListResponse<T extends ID = number> = ApiListResponse<UnitDictionaryServer<T>>;
export type ProductTypeDictionaryListResponse = ApiListResponse<ProductTypeDictionaryServer>;
export type ProductDictionaryListResponse = ApiListResponse<ProductDictionaryServer>;
export type SingatoryDictionaryListResponse = ApiListResponse<SingatoryDictionaryServer>;

/**
 * Страна
 * @field id - Код страны в формате ISO 3166-1 alpha-3, например, "USA" или "GBR".
 */
export type CountryDictionaryServer = {
  id: string;
  eng: string;
  rus: string;
};

export type CountryDictionaryListResponse = CountryDictionaryServer[];
