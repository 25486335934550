import { Tag } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks';
import { Nullable } from '@/shared/types/values';
import { TransComp } from '@/shared/ui';

import { BidStatus } from '../../types';

import { mapStatusToConfig } from './config';

import s from './BidStatusTag.module.scss';

type BidStatusTagProps = {
  className?: string;
  status: BidStatus;
  replyCount?: Nullable<number>;
};

const BidStatusTag = ({ className, status, replyCount }: BidStatusTagProps): React.ReactElement<BidStatusTagProps> => {
  const { t } = useTypedTranslation('bids');
  const { color, label } = mapStatusToConfig[status];

  return (
    <Tag className={cn(s.tag, className)} color={color}>
      <TransComp>
        {replyCount && status === BidStatus.inProgress ? t('replyCount.reply', { count: replyCount }) : label}
      </TransComp>
    </Tag>
  );
};

export default React.memo(BidStatusTag);
