import { ShipmentType } from '@/entities/stage/types';

const transitRequiredFields = [
  'customerAcceptanceCertificate',
  'customerAdditionalAgreement',
  'customerCommercialInvoice',
  'customerSpecification',
  'exportInvoice',
] as const;

const directRequiredFields = [
  'customerAcceptanceCertificate',
  'customerAdditionalAgreement',
  'customerCommercialInvoice',
  'customerSpecification',
] as const;

export const mapShipmentTypeToRequiredFields = {
  [ShipmentType.transit]: transitRequiredFields,
  [ShipmentType.direct]: directRequiredFields,
};
