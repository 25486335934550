import { Flex } from 'antd';
import * as React from 'react';

import { BidFixingType, mapFixingTypeToLabel } from '@/entities/bid';
import { Nullable } from '@/shared/types/values';
import { CustomTypographyText } from '@/shared/ui';
import { formatDate } from '@/shared/utils';

type Props = {
  fixingType: BidFixingType;
  fixingDate: Nullable<Date>;
};

const BidFixingTypeCell: React.FC<Props> = ({ fixingType, fixingDate }) => {
  const fixingTypeLabel = <CustomTypographyText>{mapFixingTypeToLabel(fixingType)}</CustomTypographyText>;

  if (!fixingDate) {
    return fixingTypeLabel;
  }

  const formattedDate = formatDate(fixingDate, 'DD/MM/YY');

  return (
    <Flex vertical>
      {fixingTypeLabel}
      <CustomTypographyText type="secondary">{formattedDate}</CustomTypographyText>
    </Flex>
  );
};

export default React.memo(BidFixingTypeCell);
