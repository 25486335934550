import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { StageModel, StepComponentProps } from '@/entities/stage';
import { StepDescription } from '@/shared/ui';
import { FormUpload } from '@/shared/ui/form/FormUpload';

import { WaitingFieldsWithFileModel } from '../../model/fieldsModel/WaitingFieldsWithFileModel';

type Props<StageModel> = StepComponentProps<StageModel>;

const WaitingWithFileStep = <Model extends StageModel>({ model, fieldKey, description }: Props<Model>) => {
  const fields = fieldKey && model[fieldKey];

  if (!(fields instanceof WaitingFieldsWithFileModel)) {
    return null;
  }

  return (
    <StepDescription description={description}>
      {fields.file.isUploaded && <FormUpload model={fields.file} disabled={true} />}
    </StepDescription>
  );
};

export default observer(WaitingWithFileStep);
