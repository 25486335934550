import { Empty, EmptyProps } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { TranslationNode } from '@/shared/types/localization';
import { TransComp } from '@/shared/ui';

import s from './CustomEmpty.module.scss';

type CustomEmptyProps = Omit<EmptyProps, 'description'> & {
  description?: TranslationNode;
};

const CustomEmpty: React.FC<CustomEmptyProps> = ({
  className,
  description,
  image = Empty.PRESENTED_IMAGE_SIMPLE,
  ...props
}) => {
  return (
    <Empty
      className={cn(s.empty, className)}
      image={image}
      description={description && <TransComp>{description}</TransComp>}
      {...props}
    />
  );
};

export default React.memo(CustomEmpty);
