import { ValueModel } from '@/shared/model';

import { IBaseUser, UserBaseServer, UserType } from '../../types';

export class UserModel<T extends UserType> implements IBaseUser<T> {
  readonly type: T;
  readonly id: number;
  readonly name: string;
  readonly email: string;
  readonly phone: string;
  readonly address: string;
  readonly accepted: boolean;
  readonly newNotificationsCount: ValueModel<number>;

  constructor(data: IBaseUser<T>) {
    this.type = data.type;
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.phone = data.phone;
    this.address = data.address;
    this.accepted = data.accepted;
    this.newNotificationsCount = data.newNotificationsCount;
  }

  static baseUserFromJson<T extends UserType>(data: UserBaseServer<T>): IBaseUser<T> {
    return {
      type: data.type,
      id: data.id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      address: data.address,
      accepted: data.accepted,
      newNotificationsCount: new ValueModel(data.new_notifications_count),
    };
  }
}
