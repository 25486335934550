import { UnitDictionaryServer } from '@/entities/dictionary';
import { UserType } from '@/entities/user';
import { Nullable } from '@/shared/types/values';

import { BaseStageDataServer, TradeCloseCustomerDocsServer, TradeCloseSupplierDocsServer } from './baseStage';

export enum ManagerFinalizationStep {
  cargoDelivered = 'cargo_delivery_confirmation',
  tradeCloseCustomerDocs = 'upload_documents_for_trade_close_customer',
  customerPayments = 'transactions',
}

export enum ManagerTransactionAction {
  addTransaction = 'add_transaction',
  deleteTransaction = 'delete_transaction',
  finish = 'finish',
}

export type ManagerFinalizationAction =
  | Exclude<ManagerFinalizationStep, ManagerFinalizationStep.customerPayments>
  | ManagerTransactionAction;

export enum SupplierFinalizationStep {
  cargoArrival = 'cargo_arrival_confirmation',
  tradeCloseSupplierDocs = 'upload_documents_for_trade_close_supplier',
  finish = 'finish',
}

export enum CustomerFinalizationStep {
  cargoDelivery = 'cargo_delivery_confirmation',
  tradeCloseCustomerDocs = 'upload_documents_for_trade_close_customer',
  finish = 'finish',
}

export type CustomerPaymentServer = {
  id: number;
  identifier: string;
  created_at: string;
  amount: number;
  price_unit: UnitDictionaryServer;
  amount_usd: number;
  description: string;
};

export type MapUserTypeToFinalizationStageData = {
  [UserType.admin]: {
    [ManagerFinalizationStep.cargoDelivered]: Nullable<{ delivered: boolean }>;
    [ManagerFinalizationStep.tradeCloseCustomerDocs]: Nullable<TradeCloseCustomerDocsServer>;
    [ManagerFinalizationStep.customerPayments]: Nullable<CustomerPaymentServer[]>;
  };
  [UserType.manager]: {
    [ManagerFinalizationStep.cargoDelivered]: Nullable<{ delivered: boolean }>;
    [ManagerFinalizationStep.tradeCloseCustomerDocs]: Nullable<TradeCloseCustomerDocsServer>;
    [ManagerFinalizationStep.customerPayments]: Nullable<CustomerPaymentServer[]>;
  };
  [UserType.supplier]: {
    [SupplierFinalizationStep.cargoArrival]: Nullable<{ arrived: boolean }>;
    [SupplierFinalizationStep.tradeCloseSupplierDocs]: Nullable<TradeCloseSupplierDocsServer>;
  };
  [UserType.customer]: {
    [CustomerFinalizationStep.cargoDelivery]: Nullable<{ delivered: boolean }>;
    [CustomerFinalizationStep.tradeCloseCustomerDocs]: Nullable<TradeCloseCustomerDocsServer>;
  };
  [UserType.agent]: null;
};

type BaseFinalizationStageDataServer<U extends UserType, Step> = BaseStageDataServer<
  Step,
  MapUserTypeToFinalizationStageData[U]
>;
export type ManagerFinalizationStageDataServer = BaseFinalizationStageDataServer<
  UserType.manager | UserType.admin,
  ManagerFinalizationStep
>;
export type SupplierFinalizationStageDataServer = BaseFinalizationStageDataServer<
  UserType.supplier,
  SupplierFinalizationStep
>;
export type CustomerFinalizationStageDataServer = BaseFinalizationStageDataServer<
  UserType.customer,
  CustomerFinalizationStep
>;

export type FinalizationStageDataServer =
  | ManagerFinalizationStageDataServer
  | SupplierFinalizationStageDataServer
  | CustomerFinalizationStageDataServer;

export type FinalizationAction = ManagerFinalizationAction;
