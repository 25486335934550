import { BaseResponse } from '@kts-front/types';

import { ClientsListResponse, UserType } from '@/entities/user';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, ValueModel } from '@/shared/model';

import { DictionaryField, DictionaryFielsdArgs, IDictionariesStore, isDictionaryFieldNeedArgs } from '../../types';
import { DictionaryModel } from '../DictionaryModel';

import { ClientsStore } from './ClientsStore';
import { CountriesStore } from './CountriesStore';
import { CurrenciesStore } from './CurrenciesStore';
import { LegalCompaniesStore } from './LegalCompaniesStore';
import { LogisticCompaniesStore } from './LogisticCompaniesStore';
import { ProductTypesStore } from './ProductTypesStore';
import { ProductsStore } from './ProductsStore';
import { SignatoriesStore } from './SignatoriesStore';
import { defaultParams } from './config';

export class DictionariesStore implements IDictionariesStore {
  readonly legalCompanies = new LegalCompaniesStore();
  readonly logisticCompanies = new LogisticCompaniesStore();
  readonly currencies = new CurrenciesStore();
  readonly productTypes = new ProductTypesStore();
  readonly products = new ProductsStore();
  readonly countries = new CountriesStore();
  readonly signatories = new SignatoriesStore();
  readonly customers: ClientsStore;
  readonly suppliers: ClientsStore;

  readonly loadingStage = new LoadingStageModel();

  private readonly _loadingClientsStage = new LoadingStageModel();

  private readonly _initialized = new ValueModel<boolean>(false);

  private readonly clientListRequest = apiStore.createRequest<ClientsListResponse>({
    url: apiUrls.client.list,
  });

  constructor() {
    this.customers = new ClientsStore(this);
    this.suppliers = new ClientsStore(this);
  }

  get isInitialLoading(): boolean {
    return !this._initialized.value && this.loadingStage.isLoading;
  }

  loadClients = async (): Promise<BaseResponse> => {
    if (this._loadingClientsStage.isLoading) {
      return { isError: false };
    }

    this._loadingClientsStage.loading();

    const response = await this.clientListRequest.call({
      params: defaultParams,
    });

    if (response.isError) {
      this._loadingClientsStage.error();

      return { isError: true };
    }

    this.customers.list.reset();
    this.suppliers.list.reset();

    response.data.results.forEach((client) => {
      if (client.type === UserType.customer) {
        this.customers.list.addEntity({ entity: DictionaryModel.fromJson(client), key: client.id });
      } else if (client.type === UserType.supplier) {
        this.suppliers.list.addEntity({ entity: DictionaryModel.fromJson(client), key: client.id });
      }
    });

    this._loadingClientsStage.success();

    return { isError: false };
  };

  async loadDictionaries(fields: DictionaryField[], fieldsArgs: DictionaryFielsdArgs = {}): Promise<BaseResponse> {
    if (this.loadingStage.isLoading) {
      return { isError: true };
    }

    this.loadingStage.loading();

    const responses = await Promise.all(
      fields.map((field) => {
        if (isDictionaryFieldNeedArgs(field)) {
          const args = fieldsArgs[field];

          if (!args) {
            return Promise.resolve({ isError: true });
          }

          return this[field].load(...args);
        }

        return this[field].load();
      }),
    );

    if (responses.some((response) => response.isError)) {
      this.loadingStage.error();

      return { isError: true };
    }

    this._initialized.change(true);

    this.loadingStage.success();

    return { isError: false };
  }
}
