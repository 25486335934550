import * as React from 'react';

import { IconProps } from '../../types';

const ZH: React.FC<IconProps> = ({ size = 20, ...props }) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7851_11189)">
        <path d="M0 0H20V20H0V0Z" fill="#EE1C25" />
        <path d="M8 4.1L2.9 7.4L5 2L6.8 7.4L2 4.1H8Z" fill="#FFFF00" />
        <path
          d="M9.24125 1.29098L11.0585 2.18102L9.15633 2.50785L10.3894 1.06746L10.2706 3.00416L9.24125 1.29098Z"
          fill="#FFFF00"
        />
        <path
          d="M11.5574 3.05502L12.886 4.58307L11.0053 4.14367L12.7028 3.29604L11.8392 5.03507L11.5574 3.05502Z"
          fill="#FFFF00"
        />
        <path
          d="M11.9755 5.95825L12.5647 7.89663L11.0265 6.72698L12.9232 6.64632L11.424 7.8818L11.9755 5.95825Z"
          fill="#FFFF00"
        />
        <path
          d="M10.391 8.0325L10.1865 10.046L9.21997 8.375L10.999 9.032L9.14097 9.5925L10.391 8.0325Z"
          fill="#FFFF00"
        />
      </g>
      <defs>
        <clipPath id="clip0_7851_11189">
          <rect width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ZH;
