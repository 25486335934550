import { BaseResponse } from '@kts-front/types';
import { reaction } from 'mobx';
import { nanoid } from 'nanoid';

import { DocumentFileType, GenerationFieldsModel, UploadOrGenerationDocumentModelParams } from '@/entities/file';
import { IngotModel } from '@/entities/file/model/IngotModel';
import { CustomerSpecificationDocPayload, CustomerSpecificationDocResponse, IngotIdType } from '@/entities/stage/types';
import { RadioListModel } from '@/shared/model/form/RadioListModel';
import { SelectModel } from '@/shared/model/form/SelectModel';
import { Nullable } from '@/shared/types/values';
import { formatDate } from '@/shared/utils';
import { emptyValueValidator } from '@/shared/utils/validators';

import { IngotListModel } from './IngotListModel';
import { ingotIdTypeOptions } from './config';

export class CustomerSpecificationFieldsModel extends GenerationFieldsModel<
  DocumentFileType.customerSpecification,
  CustomerSpecificationDocPayload
> {
  readonly signatureId = new SelectModel<number>({
    initialValue: null,
    label: (t) => t('customerSpecification.signatory', { ns: 'stage' }),
    placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly yearOrUin = new RadioListModel({
    initialValue: IngotIdType.year,
    label: (t) => t('customerSpecification.specify', { ns: 'stage' }),
    options: ingotIdTypeOptions,
    required: true,
  });

  readonly ingots = new IngotListModel({
    yearOrUin: this.yearOrUin,
  });

  constructor(params: UploadOrGenerationDocumentModelParams<DocumentFileType.customerSpecification>) {
    super(params);

    this.addReactions([
      reaction(
        () => this.yearOrUin.value,
        (yearOrUin) => {
          const field = yearOrUin === IngotIdType.year ? 'year' : 'uin';

          this.ingots.list.items.forEach((item) => item[field].reset());
        },
      ),
    ]);
  }

  //обязательный метод для GenerationFieldsModel
  //чтобы форма при отправке формы не проверять скрытые поля
  get fields() {
    return [
      this.signatureId,
      this.yearOrUin,
      ...this.ingots.list.items.flatMap((item) =>
        this.yearOrUin.value === IngotIdType.year ? item.fieldYear : item.fieldUin,
      ),
    ];
  }

  toJson(): Nullable<CustomerSpecificationDocPayload> {
    if (!this.signatureId.value || !this.yearOrUin.value || !this.ingots.isEmptyValue) return null;

    const isYear = this.yearOrUin.value === IngotIdType.year;

    const bars = this.ingots.list.items.map((item) => {
      return {
        bar_number: item.ingotNumber.value,
        weight: Number(item.weight.value),
        ligature_weight: Number(item.ligatureWeight.value),
        metal_purity: Number(item.metalPurity.value),
        ...(isYear && item.year.value ? { year: formatDate(item.year.value, 'YYYY') } : {}),
        ...(!isYear && item.uin.value ? { uin: item.uin.value } : {}),
      };
    });

    return {
      signatory_id: this.signatureId.value,
      bars: bars,
    };
  }

  async loadFieldsData(): Promise<BaseResponse> {
    if (this.loadingState.isLoading) {
      this._fieldsDataRequest.cancel();
    }

    this.loadingState.loading();

    const response = await this._fieldsDataRequest.call<CustomerSpecificationDocResponse>();

    if (response.isError) {
      this.loadingState.error();
    } else {
      this._fillFieldsByResponseData(response.data);
      this.loadingState.success();
    }

    return response;
  }

  private readonly _fillFieldsByResponseData = (data: CustomerSpecificationDocResponse): void => {
    const bars = data.bars ?? [];

    if (bars.length === 0) return;

    this.signatureId.change(data.signatory_id ?? null);

    this.yearOrUin.change(bars[0]?.year !== null ? IngotIdType.year : IngotIdType.uin);

    this.ingots.list.fillByRawData(
      bars,
      (rawItem) => ({
        entity: IngotModel.formJson(rawItem),
        key: nanoid(),
      }),
      true,
    );
  };
}
