import { Checkbox } from 'antd';
import { CheckboxGroupProps, CheckboxOptionType } from 'antd/es/checkbox';
import * as React from 'react';

import { ID } from '@/shared/types/meta';
import { Options } from '@/shared/types/values';

import { TransComp } from '../../TransComp';

type CustomCheckboxGroupProps<Value extends ID> = Omit<CheckboxGroupProps<Value>, 'options'> & {
  options?: Options<Value>;
};

const CustomCheckboxGroup = <Value extends ID>({ options = [], ...props }: CustomCheckboxGroupProps<Value>) => {
  const optionsList: Array<CheckboxOptionType<Value>> = React.useMemo(() => {
    return options.map((option) => ({
      ...option,
      label: <TransComp>{option.label}</TransComp>,
    }));
  }, [options]);

  return <Checkbox.Group {...props} options={optionsList} />;
};

export default CustomCheckboxGroup;
