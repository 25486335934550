import { extend } from 'dayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/zh';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

extend(isToday);
extend(isYesterday);
extend(isSameOrAfter);
extend(utc);
extend(timezone);
