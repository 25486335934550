import { CheckCircleFilled } from '@ant-design/icons';
import { DatePicker, Flex } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { CustomTypographyText } from '@/shared/ui';
import { CustomFormItem } from '@/shared/ui/form/CustomFormItem';

import { ExpectedDeliveryTimeModel } from '../../model/ExpectedDeliveryTimeModel';

type WaitingExpectedDeliveryTimeProps = {
  className?: string;
  model: ExpectedDeliveryTimeModel;
};

const WaitingExpectedDeliveryTime: React.FC<WaitingExpectedDeliveryTimeProps> = ({ className, model }) => {
  return (
    <CustomFormItem className={className} label={model.label}>
      <Flex vertical gap={8}>
        <CustomTypographyText type="secondary">{model.description}</CustomTypographyText>
        {model.props.value && (
          <DatePicker value={model.props.value} suffixIcon={<CheckCircleFilled />} showTime disabled />
        )}
      </Flex>
    </CustomFormItem>
  );
};

export default observer(WaitingExpectedDeliveryTime);
