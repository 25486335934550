import { SupplierConfirmationStep } from '@/entities/stage/types';
import { Nullable } from '@/shared/types/values';

const STEPS_ORDER_WITHOUT_VERIFYING: Record<SupplierConfirmationStep, number> = {
  [SupplierConfirmationStep.deliveryConfirmation]: 0,
  [SupplierConfirmationStep.confirmationVerifying]: 0,
  [SupplierConfirmationStep.confirmationSigning]: 1,
  [SupplierConfirmationStep.transfer]: 2,
};

const STEPS_ORDER_WITH_VERIFYING: Record<SupplierConfirmationStep, number> = {
  [SupplierConfirmationStep.deliveryConfirmation]: 0,
  [SupplierConfirmationStep.confirmationVerifying]: 1,
  [SupplierConfirmationStep.confirmationSigning]: 2,
  [SupplierConfirmationStep.transfer]: 3,
};

export const mapSupplierStepToNumber = (step: Nullable<SupplierConfirmationStep>, isWaitVerifying: boolean): number => {
  if (!step) {
    return -1;
  }

  const stepNumber = isWaitVerifying ? STEPS_ORDER_WITH_VERIFYING[step] : STEPS_ORDER_WITHOUT_VERIFYING[step];

  return stepNumber ?? -1;
};
