import cn from 'classnames';
import * as React from 'react';

import { Loader } from '../Loader';

import CrossComp from './CrossComp';

import s from './InfiniteScroll.module.scss';

export type InfiniteScrollProps = React.PropsWithChildren<{
  className?: string;
  isUpLoading?: boolean;
  isDownLoading?: boolean;
  isReloading?: boolean;
  hasMore?: boolean;
  scrollUpAction?: () => Promise<unknown>;
  scrollDownAction?: () => Promise<unknown>;
  withoutLoaders?: boolean;
  rootMargin?: number;
}>;

const InfiniteScroll = React.forwardRef<HTMLDivElement, InfiniteScrollProps>(
  (
    {
      className,
      children,
      hasMore,
      isUpLoading,
      isDownLoading,
      isReloading,
      scrollUpAction,
      scrollDownAction,
      withoutLoaders,
      rootMargin = 100,
    },
    outerRef,
  ) => {
    const scrollRef = React.useRef<HTMLDivElement>(null);
    React.useImperativeHandle(outerRef, () => scrollRef.current!, []);

    React.useEffect(() => {
      if (isReloading) {
        scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }, [isReloading]);

    const scrollUp = async () => {
      if (!scrollUpAction) {
        return;
      }

      const container = scrollRef.current;

      if (!container) {
        return;
      }

      const previousScrollHeight = container.scrollHeight;
      const previousScrollTop = container.scrollTop;

      await scrollUpAction();

      const newScrollHeight = container.scrollHeight;
      container.scrollTop = newScrollHeight - previousScrollHeight + previousScrollTop;
    };

    return (
      <div className={cn(s['infinite-scroll'], className)} ref={scrollRef}>
        {scrollUpAction && (
          <>
            <CrossComp
              omitInitialLoad
              hasMore={hasMore}
              scrollAction={scrollUp}
              options={{ root: scrollRef?.current, threshold: 0, rootMargin: `${rootMargin}px 0px 0px` }}
            />
            {isUpLoading && !withoutLoaders && <Loader className={s.loader} size={24} />}
          </>
        )}
        {children}
        {scrollDownAction && (
          <>
            {isDownLoading && !withoutLoaders && <Loader className={s.loader} size={24} />}
            <CrossComp
              hasMore={hasMore}
              scrollAction={scrollDownAction}
              options={{ root: scrollRef?.current, threshold: 0, rootMargin: `0px 0px ${rootMargin}px` }}
            />
          </>
        )}
      </div>
    );
  },
);

InfiniteScroll.displayName = 'InfiniteScroll';

export default InfiniteScroll;
