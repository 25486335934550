import { TradeInfoServer } from '@/entities/trade';
import { UserType } from '@/entities/user';

import { BaseStageDataServer } from './baseStage';

export enum ManagerCreationStep {
  createTrade = 'create_trade',
}

export type ManagerCreationAction = ManagerCreationStep;

type MapUserTypeToCreationStageData = {
  [UserType.admin]: {
    [ManagerCreationStep.createTrade]: TradeInfoServer;
  };
  [UserType.manager]: {
    [ManagerCreationStep.createTrade]: TradeInfoServer;
  };
  [UserType.customer]: null;
  [UserType.agent]: null;
  [UserType.supplier]: null;
};

type BaseCreationStageDataServer<U extends UserType, Step> = BaseStageDataServer<
  Step,
  MapUserTypeToCreationStageData[U]
>;

export type ManagerCreationStageDataServer = BaseCreationStageDataServer<
  UserType.manager | UserType.admin,
  ManagerCreationStep
>;
export type OtherCreationStageDataServer = BaseCreationStageDataServer<
  Exclude<UserType, UserType.manager | UserType.admin>,
  null
>;

export type CreationStageDataServer = ManagerCreationStageDataServer | OtherCreationStageDataServer;

export type CreationAction = ManagerCreationAction;
