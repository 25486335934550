import * as React from 'react';

import { IconProps } from '../types';

const OpenLink: React.FC<IconProps> = ({ size = 16, ...props }) => {
  return (
    <svg {...props} width={size} height={size} fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C10 1.72386 10.2239 1.5 10.5 1.5H14C14.2761 1.5 14.5 1.72386 14.5 2V5.5C14.5 5.77614 14.2761 6 14 6C13.7239 6 13.5 5.77614 13.5 5.5V3.20711L7.35355 9.35355C7.15829 9.54882 6.84171 9.54882 6.64645 9.35355C6.45118 9.15829 6.45118 8.84171 6.64645 8.64645L12.7929 2.5H10.5C10.2239 2.5 10 2.27614 10 2ZM3.25 4.5C3.05109 4.5 2.86032 4.57902 2.71967 4.71967C2.57902 4.86032 2.5 5.05109 2.5 5.25V12.75C2.5 12.9489 2.57902 13.1397 2.71967 13.2803C2.86032 13.421 3.05109 13.5 3.25 13.5H10.75C10.8485 13.5 10.946 13.4806 11.037 13.4429C11.128 13.4052 11.2107 13.35 11.2803 13.2803C11.35 13.2107 11.4052 13.128 11.4429 13.037C11.4806 12.946 11.5 12.8485 11.5 12.75V7C11.5 6.72386 11.7239 6.5 12 6.5C12.2761 6.5 12.5 6.72386 12.5 7V12.75C12.5 12.9798 12.4547 13.2074 12.3668 13.4197C12.2788 13.632 12.1499 13.8249 11.9874 13.9874C11.8249 14.1499 11.632 14.2788 11.4197 14.3668C11.2074 14.4547 10.9798 14.5 10.75 14.5H3.25C2.78587 14.5 2.34075 14.3156 2.01256 13.9874C1.68437 13.6592 1.5 13.2141 1.5 12.75V5.25C1.5 4.78587 1.68437 4.34075 2.01256 4.01256C2.34075 3.68437 2.78587 3.5 3.25 3.5H8.48375C8.75989 3.5 8.98375 3.72386 8.98375 4C8.98375 4.27614 8.75989 4.5 8.48375 4.5H3.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default React.memo(OpenLink);
