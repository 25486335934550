import { Flex, UploadFile } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { ArbitraryDocOptionsModel } from '@/entities/file';
import { CustomTypographyText } from '@/shared/ui';
import { CustomFormItem } from '@/shared/ui/form/CustomFormItem';
import { CustomUpload } from '@/shared/ui/form/CustomUpload';
import { FormCheckboxList } from '@/shared/ui/form/FormCheckboxList';
import { FormInput } from '@/shared/ui/form/FormInput';
import { FormInputTextArea } from '@/shared/ui/form/FormInputTextArea';

import s from './ArbitraryDocsForm.module.scss';

type ArbitraryDocProps = {
  file: UploadFile;
  options: ArbitraryDocOptionsModel;
  onRemove: (file: UploadFile) => void;
  disabled?: boolean;
  isEdit?: boolean;
};

const ArbitraryDoc: React.FC<ArbitraryDocProps> = ({ file, options, onRemove, disabled, isEdit }) => {
  const { userStore } = useRootStore();

  if (file.error) {
    return (
      <Flex vertical gap={8}>
        <CustomUpload fileList={[file]} onRemove={onRemove} disabled={disabled} />
        <CustomTypographyText type="secondary">{file.error}</CustomTypographyText>
      </Flex>
    );
  }

  return (
    <div className={s.upload_list_item} ref={options.docOptionsRef}>
      <CustomFormItem label={(t) => t('documentForm.docFile.label', { ns: 'file' })} required>
        <CustomUpload fileList={[file]} onRemove={onRemove} disabled={disabled} />
      </CustomFormItem>
      <FormInput model={options.docCustomType} disabled={disabled || isEdit} />
      <FormInputTextArea model={options.docComment} disabled={disabled || isEdit} />
      {userStore.isMainRole && <FormCheckboxList model={options.docVisibility} disabled={disabled || isEdit} />}
    </div>
  );
};

export default observer(ArbitraryDoc);
