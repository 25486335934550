import c from './colors.module.scss';

export const colors = {
  gray_1: c.gray_1,
  gray_2: c.gray_2,
  gray_3: c.gray_3,
  gray_4: c.gray_4,
  gray_5: c.gray_5,
  gray_6: c.gray_6,
  gray_8: c.gray_8,

  black: c.black,

  border: c.border,
  border_secondary: c.border_secondary,

  text: c.text,
  text_secondary: c.text_secondary,
  text_placeholder: c.text_placeholder,

  hover: c.hover,
  hover_primary: c.hover_primary,

  primary: c.primary,
  primary_secondary: c.primary_secondary,
  primary_tertiary: c.primary_tertiary,
  primary_6: c.primary_6,
  primary_9: c.primary_9,
  primary_10: c.primary_10,

  error_base: c.error_base,
  error_active: c.error_active,

  blue_1: c.blue_1,
  blue_4: c.blue_4,
  blue_6: c.blue_6,

  green_6: c.green_6,

  success_bg: c.success_bg,
  success_border: c.success_border,

  error_bg: c.error_bg,
  error_border: c.error_border,

  warning_bg: c.warning_bg,
  warning_border: c.warning_border,

  info_bg: c.info_bg,
  info_border: c.info_border,
};
