import {
  ManagerConfirmationAction,
  ManagerConfirmationStageDataServer,
  ManagerConfirmationStep,
  ManagerConfirmationVerifyingAction,
  StageModelJsonParams,
  StageType,
} from '@/entities/stage';

import { BaseManagerStageModel, BaseManagerStageModelParams } from '../../BaseManagerStageModel';
import { BaseUploadFileFieldsModel } from '../../fieldsModel/BaseUploadFileFieldsModel';
import { DocumentVerifyingFieldsModel } from '../../fieldsModel/DocumentVerifyingFieldsModel';

import { mapManagerStepToNumber } from './config';

type Params = BaseManagerStageModelParams<ManagerConfirmationStep> & {
  deliveryConfirmFields: BaseUploadFileFieldsModel<ManagerConfirmationStep, ManagerConfirmationAction>;
  confirmVerifyingFields: DocumentVerifyingFieldsModel<ManagerConfirmationStep, ManagerConfirmationAction>;
  confirmSigningFields: BaseUploadFileFieldsModel<ManagerConfirmationStep, ManagerConfirmationAction>;
};

export class ManagerConfirmationStageModel extends BaseManagerStageModel<
  StageType.confirmation,
  ManagerConfirmationStep
> {
  readonly deliveryConfirmFields: BaseUploadFileFieldsModel<ManagerConfirmationStep, ManagerConfirmationAction>;
  readonly confirmVerifyingFields: DocumentVerifyingFieldsModel<ManagerConfirmationStep, ManagerConfirmationAction>;
  readonly confirmSigningFields: BaseUploadFileFieldsModel<ManagerConfirmationStep, ManagerConfirmationAction>;

  constructor({ deliveryConfirmFields, confirmVerifyingFields, confirmSigningFields, ...params }: Params) {
    super({
      type: StageType.confirmation,
      ...params,
    });

    this.deliveryConfirmFields = deliveryConfirmFields;
    this.confirmVerifyingFields = confirmVerifyingFields;
    this.confirmSigningFields = confirmSigningFields;
  }

  get currentStep(): number {
    return mapManagerStepToNumber(this.step, this.confirmVerifyingFields.isNeedVerifying);
  }

  get steps(): ManagerConfirmationStep[] {
    let steps: ManagerConfirmationStep[] = [];

    if (this.confirmVerifyingFields.isNeedVerifying) {
      steps = [
        ManagerConfirmationStep.deliveryConfirmation,
        ManagerConfirmationStep.confirmationVerifying,
        ManagerConfirmationStep.confirmationSigning,
      ];
    } else {
      steps = [ManagerConfirmationStep.deliveryConfirmation, ManagerConfirmationStep.confirmationSigning];
    }

    if (this.step === ManagerConfirmationStep.transfer) {
      steps.push(ManagerConfirmationStep.transfer);
    }

    return steps;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<ManagerConfirmationStageDataServer>): ManagerConfirmationStageModel {
    return new ManagerConfirmationStageModel({
      deliveryConfirmFields: BaseUploadFileFieldsModel.fromJson({
        data: dataServer.steps[ManagerConfirmationStep.deliveryConfirmation],
        step: ManagerConfirmationStep.deliveryConfirmation,
        approveAction: ManagerConfirmationStep.deliveryConfirmation,
        tradeWorkflowStore,
      }),
      confirmVerifyingFields: DocumentVerifyingFieldsModel.fromJson({
        data: dataServer.steps[ManagerConfirmationStep.deliveryConfirmation],
        step: ManagerConfirmationStep.confirmationVerifying,
        approveAction: ManagerConfirmationVerifyingAction.approveConfirmationVerifying,
        rejectAction: ManagerConfirmationVerifyingAction.rejectConfirmationVerifying,
        tradeWorkflowStore,
      }),
      confirmSigningFields: BaseUploadFileFieldsModel.fromJson({
        data: dataServer.steps[ManagerConfirmationStep.confirmationSigning],
        step: ManagerConfirmationStep.confirmationSigning,
        approveAction: ManagerConfirmationStep.confirmationSigning,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
