import { ManagerPaymentStep } from '@/entities/stage/types';
import { Nullable } from '@/shared/types/values';

export const mapManagerStepToNumber = (step: Nullable<ManagerPaymentStep>): number => {
  switch (step) {
    case ManagerPaymentStep.uploadBill:
      return 0;

    case ManagerPaymentStep.payBill:
      return 1;

    case ManagerPaymentStep.transfer:
      return 2;

    default:
      return -1;
  }
};
