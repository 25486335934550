import { Input } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks';
import { TranslationString } from '@/shared/types/localization';
import { renderTranslationString } from '@/shared/utils/renderTranslation';

import s from './CustomInput.module.scss';

type CustomInputProps = Omit<React.ComponentProps<typeof Input>, 'placeholder'> & {
  placeholder?: TranslationString;
};

const CustomInput: React.FC<CustomInputProps> = ({ className, placeholder = '', ...props }) => {
  const { t } = useTypedTranslation();

  placeholder = renderTranslationString(placeholder, t);

  return <Input className={cn(s.input, className)} placeholder={placeholder} {...props} />;
};

export default CustomInput;
