import { SupplierShipmentStep } from '@/entities/stage/types';

export const stepsOrder: SupplierShipmentStep[] = [
  SupplierShipmentStep.uploadSpec,
  SupplierShipmentStep.specVerifying,
  SupplierShipmentStep.transitCountry,
  SupplierShipmentStep.transitCountryAwb,
  SupplierShipmentStep.transitCountryAwbVerifying,
  SupplierShipmentStep.cargoShipment,
  SupplierShipmentStep.transfer,
];
