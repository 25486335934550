import { IngotIdType } from '@/entities/stage/types';
import { TranslationString } from '@/shared/types/localization';
import { Options } from '@/shared/types/values';

export const mapCustomerSpecificationTypeToLabel: Record<IngotIdType, TranslationString> = {
  [IngotIdType.year]: (t) => t('customerSpecification.year', { ns: 'stage' }),
  [IngotIdType.uin]: (t) => t('customerSpecification.uin', { ns: 'stage' }),
};

export const ingotIdTypeOptions: Options<IngotIdType> = [
  {
    label: mapCustomerSpecificationTypeToLabel[IngotIdType.year],
    value: IngotIdType.year,
  },
  {
    label: mapCustomerSpecificationTypeToLabel[IngotIdType.uin],
    value: IngotIdType.uin,
  },
];
