import { Flex, Tooltip } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { CustomModal } from '@/shared/ui';
import { CustomButton } from '@/shared/ui/form/CustomButton';
import { CustomFormItem } from '@/shared/ui/form/CustomFormItem';
import { CustomUpload } from '@/shared/ui/form/CustomUpload';
import { UploadButton } from '@/shared/ui/form/buttons';

import { GenerationFieldsModel, UploadOrGenerationDocumentModel } from '../../model';
import { DocumentFileType } from '../../types';

import s from './UploadOrGenerationDocument.module.scss';

type UploadOrGenerationDocumentProps<
  DocType extends DocumentFileType,
  Payload,
  FieldsModel extends GenerationFieldsModel<DocType, Payload>,
> = {
  className?: string;
  classNameModal?: string;
  model: UploadOrGenerationDocumentModel<DocType, Payload, FieldsModel>;
  disabled?: boolean;
  width?: number;
  height?: number;
  FieldsComponent: React.ComponentType<{
    model: FieldsModel;
  }>;
  tooltip?: React.ReactNode;
};

const UploadOrGenerationDocument = <
  DocType extends DocumentFileType,
  Payload,
  FieldsModel extends GenerationFieldsModel<DocType, Payload>,
>({
  className,
  model,
  disabled,
  FieldsComponent,
  width,
  height,
  classNameModal,
  tooltip,
}: UploadOrGenerationDocumentProps<DocType, Payload, FieldsModel>) => {
  const {
    canBeGenerated,
    fileName,
    isUploaded,
    isOpenModal,
    generationFields,
    pollingState,
    openModal,
    closeModal,
    generate,
  } = model;

  const generationButton = React.useMemo(() => {
    return (
      <CustomButton
        size="small"
        loading={pollingState.isLoading || generationFields.loadingState.isLoading}
        disabled={disabled}
        onClick={openModal}
      >
        {(t) => t('buttons.generate', { ns: 'shared' })}
      </CustomButton>
    );
  }, [openModal, disabled, generationFields.loadingState.isLoading, pollingState.isLoading]);

  return (
    <>
      <CustomModal
        className={classNameModal}
        width={width}
        style={{ height: height }}
        title={fileName}
        open={isOpenModal}
        onCancel={closeModal}
        cancelButtonProps={{ disabled: generationFields.loadingState.isLoading }}
        onOk={generate}
        okText={(t) => t('buttons.create', { ns: 'shared' })}
        okButtonProps={{
          loading: generationFields.loadingState.isLoading,
          disabled: !generationFields.isFilled || generationFields.isError,
        }}
      >
        <FieldsComponent model={generationFields} />
      </CustomModal>
      <CustomFormItem className={cn(s.upload, className)} model={model}>
        <Flex gap={8} flex={1}>
          <CustomUpload
            className={cn(isUploaded && s.upload_fullWidth)}
            {...model.props}
            disabled={disabled || pollingState.isLoading || generationFields.loadingState.isLoading}
          >
            {model.props.openFileDialogOnClick && (
              <UploadButton
                disabled={
                  disabled || model.props.disabled || pollingState.isLoading || generationFields.loadingState.isLoading
                }
              />
            )}
          </CustomUpload>
          {canBeGenerated &&
            (tooltip ? (
              <Tooltip placement="top" title={tooltip}>
                {generationButton}
              </Tooltip>
            ) : (
              generationButton
            ))}
        </Flex>
      </CustomFormItem>
    </>
  );
};

export default observer(UploadOrGenerationDocument);
