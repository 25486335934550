import { action, computed, makeObservable } from 'mobx';

import { DocumentCheckStatus, DocumentWithStatusServer } from '@/entities/file';
import { UserType } from '@/entities/user';
import { ValueModel } from '@/shared/model';
import { InputTextAreaModel } from '@/shared/model/form/InputTextAreaModel';
import { Nullable } from '@/shared/types/values';
import { emptyValueValidator } from '@/shared/utils/validators';

import { BaseFieldsModel, BaseFieldsModelParams } from '../BaseFieldsModel';

type Params<Step, Action> = BaseFieldsModelParams<Step, Action> & {
  status: Nullable<DocumentCheckStatus>;
  error: InputTextAreaModel;
  uploadedBy: Nullable<UserType.admin | UserType.manager | UserType.supplier>;
};

type Payload<Action> = {
  action: Action;
  description: string;
};

export class DocumentVerifyingFieldsModel<Step, Action> extends BaseFieldsModel<Step, Action, Payload<Action>> {
  readonly status: Nullable<DocumentCheckStatus>;
  readonly error: InputTextAreaModel;
  readonly uploadedBy: Nullable<UserType.admin | UserType.manager | UserType.supplier>;

  private readonly _modalState = new ValueModel<boolean>(false);

  protected toRejectJson() {
    if (!this.rejectAction || !this.error.value) {
      return null;
    }

    return {
      action: this.rejectAction,
      description: this.error.value,
    };
  }

  constructor({ status, error, uploadedBy, ...params }: Params<Step, Action>) {
    super(params);

    this.status = status;
    this.error = error;
    this.uploadedBy = uploadedBy;

    makeObservable(this, {
      isNeedVerifying: computed,
      isWaiting: computed,
      isRejected: computed,
      isApproved: computed,
      isOpenModal: computed,

      onChangeError: action.bound,
      closeModal: action.bound,
      openModal: action.bound,
    });
  }

  get isNeedVerifying(): boolean {
    const { isMainRole } = this.tradeWorkflowStore.rootStore.userStore;

    return this.uploadedBy === UserType.supplier && isMainRole;
  }

  get isWaiting(): boolean {
    return this.status === DocumentCheckStatus.waiting;
  }

  get isRejected(): boolean {
    return this.status === DocumentCheckStatus.rejected;
  }

  get isApproved(): boolean {
    return this.status === DocumentCheckStatus.approved;
  }

  get isOpenModal(): boolean {
    return this._modalState.value;
  }

  onChangeError(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.error.change(e.target.value);
  }

  closeModal() {
    if (!this.isRejected) {
      this.error.resetValue();
    }

    this._modalState.change(false);
  }

  openModal() {
    this._modalState.change(true);
  }

  static fromJson<Step, Action>({
    data,
    ...params
  }: {
    data: Nullable<DocumentWithStatusServer>;
  } & BaseFieldsModelParams<Step, Action>): DocumentVerifyingFieldsModel<Step, Action> {
    return new DocumentVerifyingFieldsModel({
      status: data?.status ?? null,
      error: new InputTextAreaModel({
        initialValue: data?.error_message ?? '',
        label: (t) => t('documentVerifyingForm.label', { ns: 'file' }),
        placeholder: (t) => t('documentVerifyingForm.placeholder', { ns: 'file' }),
        validators: [emptyValueValidator()],
        required: true,
      }),
      uploadedBy: data?.document?.uploaded_by.role ?? null,
      ...params,
    });
  }
}
