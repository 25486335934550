import { action, makeObservable } from 'mobx';

import { Options } from '@/shared/types/values';

import { BaseFieldModel, BaseFieldModelParams } from './BaseFieldModel';

export type RadioListModelParams<Value> = BaseFieldModelParams<Value> & {
  options: Options<Value>;
};

type RadioListModelProps<Value> = {
  value: Value;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options: Options<Value>;
};

export class RadioListModel<Value> extends BaseFieldModel<Value> {
  protected readonly _options: Options<Value>;

  constructor({ options, ...params }: RadioListModelParams<Value>) {
    super(params);

    this._options = options;

    makeObservable(this, {
      onChange: action.bound,
    });
  }

  get props(): RadioListModelProps<Value> {
    return {
      value: this._value,
      onChange: this.onChange,
      options: this._options,
    };
  }

  onChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.change(e.target.value as Value);
  }
}
