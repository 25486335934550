import { Typography } from 'antd';
import { LinkProps } from 'antd/es/typography/Link';
import * as React from 'react';

import { TranslationNode } from '@/shared/types/localization';

import { TransComp } from '../TransComp';

type CustomTypographyLinkProps = Omit<LinkProps, 'children'> & {
  children: TranslationNode;
};

const CustomTypographyLink: React.FC<CustomTypographyLinkProps> = ({ children, ...props }) => {
  return (
    <Typography.Link {...props}>
      <TransComp>{children}</TransComp>
    </Typography.Link>
  );
};

export default CustomTypographyLink;
