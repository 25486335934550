import { action, computed, makeObservable, observable } from 'mobx';
import { createRef } from 'react';

import { TranslationString } from '@/shared/types/localization';
import { Validator } from '@/shared/types/validator';

import { ValueModel } from '../ValueModel';

export type BaseFieldModelParams<V = string> = {
  initialValue: V;
  label?: TranslationString;
  description?: TranslationString;
  disabled?: boolean;
  required?: boolean;
  validators?: Validator<V>[];
  placeholder?: TranslationString;
  tooltip?: TranslationString;
  ignoreOnBlurValidation?: boolean;
};

export class BaseFieldModel<V = string, E extends HTMLElement = HTMLDivElement> extends ValueModel<V> {
  private _required: boolean;
  private _tooltip: TranslationString;
  private _label: TranslationString;
  private _description: TranslationString;
  private _placeholder: TranslationString;
  private _initializedByValue: boolean;
  private _ignoreOnBlurValidation: boolean;

  readonly ref = createRef<E>();

  readonly disabled: boolean;

  constructor({
    initialValue,
    label = '',
    description = '',
    disabled = false,
    required = false,
    validators = [],
    placeholder = '',
    tooltip = '',
    ignoreOnBlurValidation = false,
  }: BaseFieldModelParams<V>) {
    super(initialValue, validators);

    this._required = required;
    this._tooltip = tooltip;
    this._label = label;
    this._description = description;
    this._placeholder = placeholder;
    this._initializedByValue = Boolean(Array.isArray(initialValue) ? initialValue.length : initialValue);
    this._ignoreOnBlurValidation = ignoreOnBlurValidation;

    this.disabled = disabled;

    makeObservable<
      this,
      | '_required'
      | '_tooltip'
      | '_label'
      | '_description'
      | '_placeholder'
      | '_initializedByValue'
      | '_ignoreOnBlurValidation'
    >(this, {
      _required: observable,
      _tooltip: observable,
      _label: observable,
      _description: observable,
      _placeholder: observable,
      _initializedByValue: observable,
      _ignoreOnBlurValidation: observable,

      required: computed,
      tooltip: computed,
      label: computed,
      description: computed,
      placeholder: computed,
      isInitializedByValue: computed,
      ignoreOnBlurValidation: computed,

      changeRequired: action.bound,
      changeLabel: action.bound,
      changeDescription: action.bound,
      changePlaceholder: action.bound,
      changeInitializedByValue: action.bound,
      changeIgnoreOnBlurValidation: action.bound,
      scrollToField: action.bound,
    });
  }

  get tooltip(): TranslationString {
    return this._tooltip;
  }

  get required(): boolean {
    return this._required;
  }

  get label(): TranslationString {
    return this._label;
  }

  get description(): TranslationString {
    return this._description;
  }

  get placeholder(): TranslationString {
    return this._placeholder;
  }

  get isInitializedByValue(): boolean {
    return this._initializedByValue;
  }

  get ignoreOnBlurValidation(): boolean {
    return this._ignoreOnBlurValidation;
  }

  changeRequired(value: boolean): void {
    this._required = value;
  }

  changeLabel(value: TranslationString): void {
    this._label = value;
  }

  changeDescription(value: TranslationString): void {
    this._description = value;
  }

  changePlaceholder(value: TranslationString): void {
    this._placeholder = value;
  }

  changeInitializedByValue(initial: boolean): void {
    this._initializedByValue = initial;
  }

  changeIgnoreOnBlurValidation(value: boolean): void {
    this._ignoreOnBlurValidation = value;
  }

  scrollToField(position: ScrollLogicalPosition = 'center'): void {
    setTimeout(() => {
      if (this.ref.current) {
        this.ref.current.scrollIntoView({ behavior: 'smooth', block: position });
      }
    }, 0);
  }
}
