import { type Location } from 'react-router';

import { isString } from '../types/typesGuard';

type Params = {
  location: Location | string;
  prefixForOmit?: string;
  preferredKey?: string;
};

const omitPrefix = (pathname: string, prefix?: string) => (prefix ? pathname.replace(prefix, '') : pathname);

const getPathnameFromString = (location: string, preferredKey?: string): string => {
  const rootPathname = location.split('/')[1];
  const nextPathname = location.split('/')[2];

  return nextPathname && preferredKey === nextPathname ? nextPathname : rootPathname;
};

export const getPathname = (args: Params | Params['location']): string => {
  const { location, prefixForOmit, preferredKey }: Params =
    isString(args) || 'pathname' in args ? { location: args } : args;

  if (isString(location)) {
    return getPathnameFromString(omitPrefix(location, prefixForOmit), preferredKey);
  }

  return getPathnameFromString(omitPrefix(location.pathname, prefixForOmit), preferredKey);
};
