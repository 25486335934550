import { DocumentCheckStatus, DocumentServer, DocumentWithStatusServer, FileModel } from '@/entities/file';
import { AwbType } from '@/entities/stage/types';
import { ValueModel } from '@/shared/model';
import { InputModel } from '@/shared/model/form/InputModel';
import { UploadFileModel } from '@/shared/model/form/UploadFileModel';
import { Nullable } from '@/shared/types/values';

import { BaseFieldsModelParams } from '../BaseFieldsModel';
import {
  BaseUploadFileFieldsModel,
  BaseUploadFileFieldsModelParams,
  BaseUploadFilePayload,
} from '../BaseUploadFileFieldsModel';

type AwbPayload<FileAction, NumberAction> =
  | BaseUploadFilePayload<FileAction>
  | (BaseUploadFilePayload<NumberAction> & {
      flight_number?: string;
    });

type OmitFields = 'approveAction' | 'rejectAction';

type AwbParams<Step, FileAction, NumberAction> = Omit<
  BaseUploadFileFieldsModelParams<Step, FileAction | NumberAction>,
  OmitFields
> & {
  awbType: ValueModel<AwbType>;
  flightNumber: InputModel;
  approveFileAction: FileAction;
  approveNumberAction: NumberAction;
};

export class UploadAwbOrFlightNumberModel<Step, FileAction, NumberAction> extends BaseUploadFileFieldsModel<
  Step,
  FileAction | NumberAction,
  AwbPayload<FileAction, NumberAction>
> {
  readonly awbType: ValueModel<AwbType>;
  readonly flightNumber: InputModel;
  private readonly approveFileAction: FileAction;
  private readonly approveNumberAction: NumberAction;

  constructor({
    awbType,
    flightNumber,
    approveFileAction,
    approveNumberAction,
    ...params
  }: AwbParams<Step, FileAction, NumberAction>) {
    super({
      ...params,
      approveAction: approveFileAction || approveNumberAction,
    });

    this.approveFileAction = approveFileAction;
    this.approveNumberAction = approveNumberAction;
    this.awbType = awbType;
    this.flightNumber = flightNumber;
  }

  get approveDisabled(): boolean {
    if (this.awbType.value === AwbType.file) {
      return !this.file.isUploaded || this.file.isError;
    }

    if (this.awbType.value === AwbType.number) {
      return !this.flightNumber.value.trim();
    }

    return true;
  }

  toApproveJson(): AwbPayload<FileAction, NumberAction> {
    const files: File[] = [];
    this.file.value.forEach(({ originFileObj }) => {
      originFileObj && files.push(originFileObj);
    });

    return (
      this.awbType.value === AwbType.file
        ? { action: this.approveFileAction, files }
        : {
            action: this.approveNumberAction,
            flight_number: this.flightNumber.value,
          }
    ) as AwbPayload<FileAction, NumberAction>;
  }

  reset() {
    this.flightNumber.reset();
    this.file.reset();
  }

  static fromServer<Step, FileAction, NumberAction>({
    data,
    ...params
  }: {
    data: Nullable<
      | DocumentServer
      | DocumentWithStatusServer
      | { flight_number: string; status: DocumentCheckStatus; error_message: Nullable<string> }
    >;
    approveFileAction: FileAction;
    approveNumberAction: NumberAction;
  } & Omit<BaseFieldsModelParams<Step, FileAction | NumberAction>, OmitFields>): UploadAwbOrFlightNumberModel<
    Step,
    FileAction,
    NumberAction
  > {
    const isDataDocument = !!data && 'document' in data;
    const isDataFlightNumber = !!data && 'flight_number' in data && data.flight_number !== null;

    const awbType: AwbType = isDataFlightNumber ? AwbType.number : AwbType.file;

    return new UploadAwbOrFlightNumberModel({
      awbType: new ValueModel(awbType),
      flightNumber: new InputModel({
        initialValue: isDataFlightNumber ? data.flight_number : '',
        description: (t) => t('requiredStep.awbDoc.descriptionNumber', { ns: 'stage' }),
        placeholder: (t) => t('requiredStep.awbDoc.numberPlaceholder', { ns: 'stage' }),
        required: true,
        ...params,
      }),
      fileModel: new UploadFileModel({
        initialValue: FileModel.fileListFromJson(isDataDocument ? data.document : null),
        description: (t) => t('requiredStep.awbDoc.descriptionFile', { ns: 'stage' }),
        required: true,
      }),
      status: data && 'status' in data ? data.status : null,
      error: data && 'error_message' in data ? data.error_message : null,
      uploadedBy: isDataDocument && data.document ? data.document.uploaded_by.role : null,

      ...params,
    });
  }
}
