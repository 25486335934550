import { useRef } from 'react';

export const useLastKnown = <T>(value: T | null) => {
  const previousRef = useRef<T | null>(null);

  if (value !== null) {
    previousRef.current = value;
  }

  return previousRef.current;
};
