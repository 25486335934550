import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { UploadOrGenerationDocument } from '@/entities/file';

import { AdditionalAgreementModel } from '../../model/fieldsModel/AdditionalAgreementModel';

import AdditionalAgreementForm from './AdditionalAgreementForm';

type AdditionalAgreementProps = {
  model: AdditionalAgreementModel;
  disabled?: boolean;
};

const AdditionalAgreement: React.FC<AdditionalAgreementProps> = ({ model, disabled }) => {
  return <UploadOrGenerationDocument model={model} disabled={disabled} FieldsComponent={AdditionalAgreementForm} />;
};

export default observer(AdditionalAgreement);
