import { action, computed, makeObservable } from 'mobx';

import { CustomerPaymentServer } from '@/entities/stage';
import { ListModel, ToggleModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import { BaseFieldsModel, BaseFieldsModelParams } from '../../../fieldsModel/BaseFieldsModel';
import { CustomerPaymentModel } from '../CustomerPaymentModel';

type Params<Step, Action> = BaseFieldsModelParams<Step, Action> & {
  paymentList: ListModel<CustomerPaymentModel, number>;
};

export class CustomerPaymentFieldsModel<Step, Action> extends BaseFieldsModel<Step, Action> {
  readonly paymentList: ListModel<CustomerPaymentModel, number>;

  readonly modalState = new ToggleModel();

  constructor({ paymentList, ...params }: Params<Step, Action>) {
    super(params);

    this.paymentList = paymentList;

    makeObservable(this, {
      isError: computed,
      isApproved: computed,

      addPayment: action.bound,
      removePayment: action.bound,
      openModal: action.bound,
      completeTrade: action.bound,
    });
  }

  get isError(): boolean {
    return this.paymentList.items.some((payment) => payment.isError);
  }

  get isApproved(): boolean {
    return this.paymentList.items.every((payment) => payment.isApproved);
  }

  get approveDisabled(): boolean {
    return !this.paymentList.length || this.isError || !this.isApproved;
  }

  get successModalState(): ToggleModel {
    return this.tradeWorkflowStore.successModalState;
  }

  private _validatePayments = (): void => {
    this.paymentList.items.forEach((payment) => {
      payment.validate();
    });
  };

  openModal(): void {
    this._validatePayments();

    if (!this.isError) {
      this.modalState.open();
    }
  }

  addPayment(): void {
    const paymentId = this.paymentList.length + 1;
    this.paymentList.addEntity({
      entity: CustomerPaymentModel.fromJson({
        id: paymentId,
        tradeWorkflowStore: this.tradeWorkflowStore,
      }),
      key: paymentId,
    });
  }

  removePayment(id: number): void {
    this.paymentList.removeEntity(id);
  }

  async completeTrade(): Promise<void> {
    const { isError } = await this.approveStep();

    if (!isError) {
      this.modalState.close();
      this.tradeWorkflowStore.successModalState.open();
    }
  }

  static fromJson<Step, Action>({
    data: payments,
    ...params
  }: { data?: Nullable<CustomerPaymentServer[]> } & BaseFieldsModelParams<Step, Action>): CustomerPaymentFieldsModel<
    Step,
    Action
  > {
    const paymentList = new ListModel<CustomerPaymentModel, number>();

    if (payments && payments.length) {
      payments.forEach((payment, index) => {
        const paymentId = index + 1;
        paymentList.addEntity({
          entity: CustomerPaymentModel.fromJson({
            id: paymentId,
            payment,
            tradeWorkflowStore: params.tradeWorkflowStore,
          }),
          key: paymentId,
        });
      });
    } else {
      paymentList.addEntity({
        entity: CustomerPaymentModel.fromJson({
          id: 1,
          tradeWorkflowStore: params.tradeWorkflowStore,
        }),
        key: 0,
      });
    }

    return new CustomerPaymentFieldsModel({
      paymentList,
      ...params,
    });
  }
}
