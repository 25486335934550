import { UploadFile } from 'antd';
import { computed, makeObservable } from 'mobx';

import { IDictionariesStore } from '@/entities/dictionary';
import { FileModel } from '@/entities/file';
import { InputModel, InputType } from '@/shared/model/form/InputModel';
import { SelectModel } from '@/shared/model/form/SelectModel';
import { UploadFileModel } from '@/shared/model/form/UploadFileModel';
import { Nullable, Options } from '@/shared/types/values';
import {
  AcceptFileFormats,
  emailValidator,
  emptyValueValidator,
  phoneValidator,
  stringLengthValidator,
} from '@/shared/utils/validators';

import { CompanyEditPayload, CompanyServer, TaxType } from '../types';

import { CompanyFieldsModel, CompanyFieldsParams } from './CompanyFieldsModel';

export type CompanyEditFieldsParams = CompanyFieldsParams & {
  countryId: Nullable<number>;
  phone: string;
  taxValue: string;
  taxType: Nullable<TaxType>;
  email: string;
  image: UploadFile[];
  dictionariesStore: IDictionariesStore;
};

const T_OPTIONS = { ns: 'company' } as const;

export class CompanyEditFieldsModel extends CompanyFieldsModel {
  readonly countryId: SelectModel<number>;
  readonly taxType: SelectModel<TaxType>;
  readonly email: InputModel;
  readonly taxValue: InputModel;
  readonly phone: InputModel;
  readonly image: UploadFileModel;

  private readonly _dictionariesStore: IDictionariesStore;

  constructor({
    countryId,
    phone,
    taxValue,
    taxType,
    email,
    image,
    dictionariesStore,
    ...params
  }: CompanyEditFieldsParams) {
    super(params);

    this._dictionariesStore = dictionariesStore;

    this.taxValue = new InputModel({
      initialValue: taxValue,
      label: (t) => t('fields.tax', T_OPTIONS),
      placeholder: (t) => t('fields.tax', T_OPTIONS),
      required: true,
      validators: [emptyValueValidator(), stringLengthValidator(128)],
    });

    this.email = new InputModel({
      initialValue: email,
      label: (t) => t('fields.email', T_OPTIONS),
      placeholder: (t) => t('fields.email', T_OPTIONS),
      required: true,
      validators: [emptyValueValidator(), emailValidator, stringLengthValidator(128)],
    });

    this.phone = new InputModel({
      initialValue: phone,
      type: InputType.phone,
      label: (t) => t('fields.phone', T_OPTIONS),
      placeholder: (t) => t('fields.phone', T_OPTIONS),
      validators: [emptyValueValidator(), phoneValidator],
      required: true,
    });

    this.taxType = new SelectModel<TaxType>({
      initialValue: taxType,
      required: true,
      validators: [emptyValueValidator()],
      ignoreOnBlurValidation: true,
    });

    this.countryId = new SelectModel({
      initialValue: countryId,
      label: (t) => t('fields.country', T_OPTIONS),
      placeholder: (t) => t('fields.selectFormCountry', T_OPTIONS),
      required: true,
      validators: [emptyValueValidator()],
      ignoreOnBlurValidation: true,
    });

    this.image = new UploadFileModel({
      initialValue: image,
      acceptFileFormats: [AcceptFileFormats.jpg, AcceptFileFormats.jpeg, AcceptFileFormats.png],
    });

    this.fields = [
      this.address,
      this.name,
      this.image,
      this.taxValue,
      this.email,
      this.taxType,
      this.phone,
      this.countryId,
    ];

    makeObservable(this, {
      countryOptions: computed,
    });
  }

  get countryOptions(): Options<number> {
    return this._dictionariesStore.countries.list.items;
  }

  toJson = (): Nullable<CompanyEditPayload> => {
    if (!this.taxType.value || !this.countryId.value) {
      return null;
    }

    // Для удаления картинки необходимо отправлять пустую строку
    const imageFile = this.image.value.length ? this.image.value[0].originFileObj : '';

    return {
      name: this.name.value,
      address: this.address.value,
      email: this.email.value,
      phone: this.phone.value,
      tax_attribute_identifier: this.taxValue.value,
      tax_attribute_slug: this.taxType.value,
      country_id: this.countryId.value,
      ...(imageFile === undefined ? {} : { image: imageFile }),
    };
  };

  static fromJson({
    data,
    dictionariesStore,
  }: {
    data: CompanyServer;
    dictionariesStore: IDictionariesStore;
  }): CompanyEditFieldsModel {
    const image = data?.image
      ? {
          id: 0,
          name: data.image.link,
          link: data.image.link,
        }
      : null;

    return new CompanyEditFieldsModel({
      name: data.name,
      address: data.address,
      email: data.email ?? '',
      phone: data.phone ?? '',
      taxValue: data.tax_attribute?.identifier ?? '',
      taxType: data.tax_attribute?.slug ?? null,
      countryId: data.country?.id ?? null,
      image: FileModel.fileListFromJson(image),
      dictionariesStore,
    });
  }
}
