import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import s from './Loader.module.scss';

type LoaderProps = {
  className?: string;
  inline?: boolean;
  size?: number;
  fixed?: boolean;
  minHeight?: number;
};

const Loader = ({ className, inline, fixed, size = 40, minHeight }: LoaderProps): React.ReactElement => {
  const loaderElement = <Spin indicator={<LoadingOutlined style={{ fontSize: size }} spin />} />;

  if (inline) {
    return loaderElement;
  }

  return (
    <div className={cn(s.loader, fixed && s.loader_fixed, className)} style={{ minHeight }}>
      {loaderElement}
    </div>
  );
};

export default React.memo(Loader);
