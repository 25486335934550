import { ManagerFinalizationStep } from '@/entities/stage/types';
import { Nullable } from '@/shared/types/values';

export const mapManagerStepToNumber = (step: Nullable<ManagerFinalizationStep>): number => {
  switch (step) {
    case ManagerFinalizationStep.cargoDelivered:
      return 0;

    case ManagerFinalizationStep.tradeCloseCustomerDocs:
      return 1;

    case ManagerFinalizationStep.customerPayments:
      return 2;

    default:
      return -1;
  }
};
