import { Flex } from 'antd';
import * as React from 'react';

import { TranslationString } from '@/shared/types/localization';
import { CustomTypographyText, CustomTypographyTitle } from '@/shared/ui';

type Props = {
  title?: TranslationString;
  message: TranslationString;
};

export const getContent = ({ title, message }: Props) => {
  return (
    <Flex vertical gap={4} align="start">
      {title && <CustomTypographyTitle level={5}>{title}</CustomTypographyTitle>}
      <CustomTypographyText>{message}</CustomTypographyText>
    </Flex>
  );
};
