import { BaseResponse } from '@kts-front/types';

import { apiStore, apiUrls } from '@/shared/api';
import { ListModel } from '@/shared/model';

import { IDictionaryStore, UnitDictionaryListResponse } from '../../types';
import { DictionaryModel } from '../DictionaryModel';

import { defaultParams } from './config';

export class CurrenciesStore implements IDictionaryStore<DictionaryModel> {
  readonly list = new ListModel<DictionaryModel, number>();

  private readonly currencyListRequest = apiStore.createRequest<UnitDictionaryListResponse>({
    url: apiUrls.dictionaries.currencyList,
  });

  async load(): Promise<BaseResponse> {
    if (!this.list.initial) {
      return { isError: false };
    }

    const response = await this.currencyListRequest.call({
      params: defaultParams,
    });

    if (response.isError) {
      return { isError: true };
    }

    this.list.fillByRawData(
      response.data.results,
      (raw) => ({
        entity: DictionaryModel.fromJson(raw),
        key: raw.id,
      }),
      true,
    );

    this.list.changeInitial(false);

    return { isError: false };
  }
}
