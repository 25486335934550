import { Flex } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { CreateTradeFieldsModel } from '@/entities/stage';
import { CustomModal } from '@/shared/ui/CustomModal';
import { CustomFormItem } from '@/shared/ui/form/CustomFormItem';
import { CustomUpload } from '@/shared/ui/form/CustomUpload';
import { DeleteButton } from '@/shared/ui/form/buttons';

type Props = {
  className?: string;
  model: CreateTradeFieldsModel;
  disabled: boolean;
};

const SupplierSpecificationModal: React.FC<Props> = ({ className, model, disabled }) => {
  const { formDisabled, supplierSpec, deleteSupplierSpecStage, deleteSupplierSpecModalState, deleteSupplierSpec } =
    model;

  return (
    <>
      <CustomModal
        open={deleteSupplierSpecModalState.isOpen}
        width={500}
        danger
        title={(t) => t('documentDeleteModal.title', { ns: 'file' })}
        onCancel={deleteSupplierSpecModalState.close}
        cancelButtonProps={{ disabled: deleteSupplierSpecStage.isLoading }}
        okText={(t) => t('buttons.delete', { ns: 'shared' })}
        onOk={deleteSupplierSpec}
        okButtonProps={{ loading: deleteSupplierSpecStage.isLoading }}
      />
      <CustomFormItem className={className} label={supplierSpec.label} required>
        <Flex gap={8} align="center">
          <CustomUpload fileList={supplierSpec.value} disabled />
          <DeleteButton
            disabled={disabled || formDisabled || deleteSupplierSpecStage.isLoading}
            onClick={deleteSupplierSpecModalState.open}
          />
        </Flex>
      </CustomFormItem>
    </>
  );
};

export default observer(SupplierSpecificationModal);
