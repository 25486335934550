import { DeleteOutlined } from '@ant-design/icons';
import * as React from 'react';

import { CustomButton } from '../../CustomButton';
import { BaseButtonProps } from '../types';

type DeleteButtonProps = BaseButtonProps & {
  danger?: boolean;
};

const DeleteButton: React.FC<DeleteButtonProps> = ({
  text = (t) => t('buttons.delete', { ns: 'shared' }),
  danger = true,
  ...props
}) => {
  return (
    <CustomButton danger={danger} size="small" icon={danger && <DeleteOutlined />} {...props}>
      {text}
    </CustomButton>
  );
};

export default React.memo(DeleteButton);
