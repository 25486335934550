import { UploadFile } from 'antd';
import { computed, makeObservable } from 'mobx';

import { FileWithDocType } from '@/entities/file';
import { UserType } from '@/entities/user';
import { TranslationString } from '@/shared/types/localization';
import { Nullable } from '@/shared/types/values';

import { HistoryActions, LogServer } from '../types';

import { mapHistoryActionToLabel } from './config';

type Params = {
  id: number;
  action: HistoryActions;
  createdAt: string;
  files: Nullable<Array<FileWithDocType>>;
  tradeId: number;
  userType: Nullable<UserType>;
};

export class TradeLogModel {
  readonly id: number;
  readonly action: HistoryActions;
  readonly createdAt: string;
  readonly files: Nullable<Array<FileWithDocType>>;
  readonly comment: Nullable<string> = null;
  readonly roles: Nullable<Array<UserType>> = null;

  readonly tradeId: number;
  readonly userType: Nullable<UserType>;

  constructor({ id, action, createdAt, files, userType, tradeId }: Params) {
    this.id = id;
    this.action = action;
    this.createdAt = createdAt;
    this.files = files;

    this.tradeId = tradeId;
    this.userType = userType;

    makeObservable(this, {
      label: computed,
    });
  }

  get label(): TranslationString {
    return mapHistoryActionToLabel(this.tradeId, this.userType)[this.action];
  }

  get fileList(): Array<UploadFile> {
    return this.files
      ? this.files.map((file) => ({
          uid: String(file.id),
          name: file.name,
          url: file.url,
        }))
      : [];
  }

  static fromJson({
    server,
    ...params
  }: {
    server: LogServer;
    tradeId: number;
    userType: Nullable<UserType>;
  }): TradeLogModel {
    return new TradeLogModel({
      ...params,
      id: server.id,
      action: server.action,
      createdAt: server.created_at,
      files: server.files?.length
        ? server.files.map((file) => ({
            id: file.id,
            name: file.name,
            url: file.link,
            docType: file.doc_type,
          }))
        : null,
    });
  }
}
