import { DocumentFileType, FileServer } from '@/entities/file';
import { StageAction } from '@/entities/stage';
import { ApiListResponse } from '@/shared/types/api';

export enum HistoryAction {
  transferToPayment = 'transfer_to_payment',
  transferToShipment = 'transfer_to_shipment',
  transferToTransportation = 'transfer_to_transportation',
  transferToFinalization = 'transfer_to_finalization',

  cargoDirectShipmentConfirm = 'cargo_direct_shipment_confirmation',
  cargoTransitShipmentConfirm = 'cargo_transit_shipment_confirmation',
}

export type HistoryActions = Exclude<StageAction, 'transfer'> | HistoryAction;

export type TradeHistoryFileServer = FileServer & { doc_type: DocumentFileType };

export type LogServer = {
  id: number;
  action: HistoryActions;
  created_at: string;
  files?: Array<TradeHistoryFileServer>;
};

export type HistoryListResponse = ApiListResponse<LogServer>;
