import { DeleteOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import * as React from 'react';

import { IngotIdType } from '@/entities/stage/types/server/customerSpecification';
import { TranslationString } from '@/shared/types/localization';
import { TransComp } from '@/shared/ui';
import { ColumnsData } from '@/shared/ui/Table';
import { CustomButton } from '@/shared/ui/form/CustomButton';
import { FormDatePicker } from '@/shared/ui/form/FormDatePicker';
import { FormInput } from '@/shared/ui/form/FormInput';

import { IngotListModel } from './IngotListModel';
import { IIngotsTableData } from './types';

const T_OPTIONS = { ns: 'stage' } as const;

const Title = (props: { title: TranslationString }) => {
  const title = <TransComp>{props.title}</TransComp>;

  return <Typography.Text ellipsis={{ tooltip: title }}>{title}</Typography.Text>;
};

const formInputRender = ({
  ingotList,
  key,
  field,
}: {
  ingotList: IngotListModel;
  key: string;
  field: Exclude<keyof IIngotsTableData, 'key' | 'year'>;
}) => {
  const ingotModel = ingotList.list.getEntity(key);

  return ingotModel && <FormInput model={ingotModel[field]} />;
};

const formDateRender = ({ ingotList, key, field }: { ingotList: IngotListModel; key: string; field: 'year' }) => {
  const ingotModel = ingotList.list.getEntity(key);

  return ingotModel ? <FormDatePicker model={ingotModel[field]} picker={field} /> : null;
};

const yearFields = ['ingotNumber', 'weight', 'ligatureWeight', 'metalPurity', 'year', 'key'] as const;
const uinFields = ['ingotNumber', 'weight', 'ligatureWeight', 'metalPurity', 'uin', 'key'] as const;

export const getColumns = (ingotList: IngotListModel): ColumnsData<IIngotsTableData> => {
  const mapColumnKeyToColumnData: Record<keyof IIngotsTableData, ColumnsData<IIngotsTableData>[number]> = {
    ingotNumber: {
      title: <Title title={(t) => t('customerSpecification.ingotNumber', T_OPTIONS)} />,
      dataIndex: 'ingotNumber',
      key: 'ingotNumber',
      width: 140,
      render: (key: string) => formInputRender({ ingotList, key, field: 'ingotNumber' }),
    },
    weight: {
      title: <Title title={(t) => t('customerSpecification.weight', T_OPTIONS)} />,
      dataIndex: 'weight',
      key: 'weight',
      width: 140,
      render: (key: string) => formInputRender({ ingotList, key, field: 'weight' }),
    },
    ligatureWeight: {
      title: <Title title={(t) => t('customerSpecification.ligatureWeight', T_OPTIONS)} />,
      dataIndex: 'ligatureWeight',
      key: 'ligatureWeight',
      width: 140,
      render: (key: string) => formInputRender({ ingotList, key, field: 'ligatureWeight' }),
    },
    metalPurity: {
      title: <Title title={(t) => t('customerSpecification.metalPurity', T_OPTIONS)} />,
      dataIndex: 'metalPurity',
      key: 'metalPurity',
      width: 140,
      render: (key: string) => formInputRender({ ingotList, key, field: 'metalPurity' }),
    },
    year: {
      title: <Title title={(t) => t('customerSpecification.yearOfProduction', T_OPTIONS)} />,
      dataIndex: 'year',
      key: 'year',
      width: 140,
      render: (key: string) => formDateRender({ ingotList, key, field: 'year' }),
    },
    uin: {
      title: <Title title={(t) => t('customerSpecification.uin', T_OPTIONS)} />,
      dataIndex: 'uin',
      key: 'uin',
      width: 140,
      render: (key: string) => formInputRender({ ingotList, key, field: 'uin' }),
    },
    key: {
      title: null,
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      width: 64,
      render: (key: string) => (
        <CustomButton
          disabled={ingotList.list.length === 1}
          icon={<DeleteOutlined />}
          onClick={() => ingotList.deleteIngot(key)}
        />
      ),
    },
  };

  const fields = ingotList.yearOrUin.value === IngotIdType.year ? yearFields : uinFields;

  return fields.map((key) => mapColumnKeyToColumnData[key]);
};
