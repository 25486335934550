import * as React from 'react';

type CrossCompProps = {
  scrollAction: () => Promise<unknown>;
  hasMore?: boolean;
  options?: IntersectionObserverInit;
  omitInitialLoad?: boolean;
};

const CrossComp: React.FC<CrossCompProps> = ({ scrollAction, hasMore = false, omitInitialLoad = false, options }) => {
  const compRef = React.useRef<HTMLDivElement>(null);
  const observer = React.useRef<IntersectionObserver>();

  React.useEffect(() => {
    if (!compRef.current) {
      return;
    }

    let isInitial = omitInitialLoad;

    observer.current = new IntersectionObserver(([target]) => {
      if (target.isIntersecting && hasMore && !isInitial) {
        scrollAction();
      } else {
        isInitial = false;
      }
    }, options);

    observer.current.observe(compRef.current);

    return () => {
      if (observer.current && compRef.current) {
        observer.current.unobserve(compRef.current);
      }
    };
  }, [scrollAction, hasMore, omitInitialLoad, options?.root]);

  return <div ref={compRef} />;
};

export default React.memo(CrossComp);
