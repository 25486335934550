import {
  DocumentFileType,
  UploadOrGenerationDocumentModel,
  UploadOrGenerationDocumentModelParams,
} from '@/entities/file';
import { AcceptanceCertificateDocPayload } from '@/entities/stage/types';

import { AcceptanceCertificateFiedsModel } from './AcceptanceCertificateFiedsModel';

export class AcceptanceCertificateModel extends UploadOrGenerationDocumentModel<
  DocumentFileType.customerAcceptanceCertificate,
  AcceptanceCertificateDocPayload,
  AcceptanceCertificateFiedsModel
> {
  readonly generationFields: AcceptanceCertificateFiedsModel;

  constructor(params: UploadOrGenerationDocumentModelParams<DocumentFileType.customerAcceptanceCertificate>) {
    super(params);

    this.generationFields = new AcceptanceCertificateFiedsModel(params);
  }
}
