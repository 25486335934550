import { StepProps } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { CustomVerticalSteps } from '../CustomVerticalSteps';

import s from './StageContent.module.scss';

type StageStepsProps = {
  className?: string;
  currentStep: number;
  items: StepProps[];
  asSingleStep?: boolean;
};

const StageSteps: React.FC<StageStepsProps> = ({ className, currentStep, items, asSingleStep }) => {
  const stepsWrapperRef = React.useRef<HTMLDivElement>(null);

  // Автоскролл к активному шагу этапа
  React.useEffect(() => {
    if (stepsWrapperRef.current) {
      const activeSteps = stepsWrapperRef.current.getElementsByClassName('ant-steps-item-active');

      if (activeSteps.length) {
        activeSteps[0].scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      }
    }
  }, [currentStep]);

  return (
    <CustomVerticalSteps
      className={cn(s.stageSteps, asSingleStep && s.stageSteps_singleStep, className)}
      ref={stepsWrapperRef}
      current={currentStep}
      items={items}
    />
  );
};

export default React.memo(StageSteps);
