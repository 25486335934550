import { ITradeWorkflowStore } from '@/entities/trade';
import { UserType } from '@/entities/user';
import { TranslationString } from '@/shared/types/localization';
import { Nullable } from '@/shared/types/values';

import { ExpectedDeliveryTimeModel } from '../../ExpectedDeliveryTimeModel';

export const optionalFieldsServer = [
  'arbitrary_documents',
  'authorization_letter',
  'destination_logistic_company',
  'finalization_photo',
  'indirect_taxes_payment_certificate',
  'supplier_specification',
  'tracking_link',
  'transit_logistic_company',
  'warranty_loa',
  'etd',
] as const;

type TextParams = {
  label: TranslationString;
  description: TranslationString;
};

export const getExpectedDeliveryTimeTextParams = (
  userRole: Nullable<UserType>,
  etdDate: Nullable<Date>,
): TextParams => {
  const params: TextParams = {
    label: '',
    description: '',
  };

  if (!userRole) {
    return params;
  }

  switch (userRole) {
    case UserType.admin:

    case UserType.manager: {
      params.label = (t) => t('optionalFields.expectedDeliveryTime.manager.label', { ns: 'stage' });

      break;
    }

    case UserType.customer: {
      params.label = (t) => t('optionalFields.expectedDeliveryTime.customer.label', { ns: 'stage' });
      params.description = (t) =>
        t(
          etdDate
            ? 'optionalFields.expectedDeliveryTime.customer.approveDescription'
            : 'optionalFields.expectedDeliveryTime.customer.waitDescription',
          { ns: 'stage' },
        );

      break;
    }
  }

  return params;
};

export const initExpectedDeliveryTimeModel = (tradeWorkflowStore: ITradeWorkflowStore): ExpectedDeliveryTimeModel => {
  const { tradeInfo, rootStore } = tradeWorkflowStore;
  const { userRole } = rootStore.userStore;

  const modelDisabled = tradeInfo.tradeDisabled || tradeInfo.stageDisabled;

  return new ExpectedDeliveryTimeModel({
    ...getExpectedDeliveryTimeTextParams(userRole, tradeInfo.etdDate),
    initialValue: tradeInfo.etdDate,
    disabled: modelDisabled,
    tradeWorkflowStore,
  });
};
