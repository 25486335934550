import { BaseResponse } from '@kts-front/types';
import { action, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { MessageType } from '@/entities/message';
import { HistoryActions, mapHistoryActionToLabel } from '@/entities/tradeHistory';
import { UserType } from '@/entities/user';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, ValueModel } from '@/shared/model';
import { TranslationString } from '@/shared/types/localization';
import { Nullable } from '@/shared/types/values';

import { NotificationServer } from '../types/server';

type Params = {
  id: number;
  action: HistoryActions;
  tradeId: number;
  createDate: string;
  isNew: ValueModel<boolean>;
  rootStore: IRootStore;
};

const T_OPTIONS = { ns: 'notifications' } as const;

export class NotificationModel {
  readonly id: number;
  readonly action: HistoryActions;
  readonly tradeId: number;
  readonly createDate: string;
  readonly isNew: ValueModel<boolean>;

  readonly readingStage = new LoadingStageModel();
  private readonly _rootStore: IRootStore;

  constructor(params: Params) {
    this.id = params.id;
    this.action = params.action;
    this.tradeId = params.tradeId;
    this.createDate = params.createDate;
    this.isNew = params.isNew;

    this._rootStore = params.rootStore;

    makeObservable(this, {
      markAsRead: action.bound,
    });
  }

  get tradeLink(): string {
    return `${location.origin}/trades/${this.tradeId}`;
  }

  get label(): TranslationString {
    return mapHistoryActionToLabel(this.tradeId, this._userType)[this.action];
  }

  async markAsRead(): Promise<BaseResponse> {
    if (this.isNew.value === false) {
      return { isError: false };
    }

    if (this.readingStage.isLoading) {
      return { isError: true };
    }

    this.readingStage.loading();

    const { isError } = await this._notificationReadRequest.call({
      data: { id: this.id },
    });

    if (isError) {
      this.readingStage.error();

      this._rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('list.messages.readOneError', T_OPTIONS),
      });

      return { isError: true };
    }

    this.isNew.change(false);

    this.readingStage.success();

    this._rootStore.notificationsStore.addNotification({
      type: MessageType.success,
      message: (t) => t('list.messages.readOneSuccess', T_OPTIONS),
    });

    return { isError: false };
  }

  private get _userType(): Nullable<UserType> {
    return this._rootStore.userStore.userModel?.type ?? null;
  }

  private _notificationReadRequest = apiStore.createRequest({
    method: 'POST',
    url: apiUrls.notifications.read,
  });

  static fromJson({ id, action, trade_id, created_at, is_new }: NotificationServer, rootStore: IRootStore) {
    return new NotificationModel({
      id,
      action,
      tradeId: trade_id,
      createDate: created_at,
      isNew: new ValueModel(is_new),
      rootStore,
    });
  }
}
