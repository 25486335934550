import { TranslationString } from '@/shared/types/localization';

import { DocumentFileType } from './types';

export const documentFileTypeList = Object.values(DocumentFileType);

export const mapDocTypeToLabel: Record<DocumentFileType, TranslationString> = {
  [DocumentFileType.supplierSpecification]: (t) => t('docTypeLabel.supplier_specification', { ns: 'file' }),
  [DocumentFileType.customerSpecification]: (t) => t('docTypeLabel.customer_specification', { ns: 'file' }),

  [DocumentFileType.deliveryConfirmation]: (t) => t('docTypeLabel.delivery_confirmation', { ns: 'file' }),
  [DocumentFileType.confirmationSigning]: (t) => t('docTypeLabel.confirmation_signing', { ns: 'file' }),

  [DocumentFileType.paymentBill]: (t) => t('docTypeLabel.payment_bill', { ns: 'file' }),
  [DocumentFileType.paidBill]: (t) => t('docTypeLabel.paid_bill', { ns: 'file' }),

  [DocumentFileType.exportInvoice]: (t) => t('docTypeLabel.export_invoice', { ns: 'file' }),
  [DocumentFileType.customerCommercialInvoice]: (t) => t('docTypeLabel.customer_commercial_invoice', { ns: 'file' }),
  [DocumentFileType.awbForDirectCountry]: (t) => t('docTypeLabel.awb_for_direct_country', { ns: 'file' }),
  [DocumentFileType.awbForTransitCountry]: (t) => t('docTypeLabel.awb_for_transit_country', { ns: 'file' }),
  [DocumentFileType.awbForDestinationCountry]: (t) => t('docTypeLabel.awb_for_destination_country', { ns: 'file' }),

  [DocumentFileType.customerAcceptanceCertificate]: (t) =>
    t('docTypeLabel.customer_acceptance_certificate', { ns: 'file' }),
  [DocumentFileType.customerAdditionalAgreement]: (t) =>
    t('docTypeLabel.customer_additional_agreement', { ns: 'file' }),

  [DocumentFileType.supplierAcceptanceCertificate]: (t) =>
    t('docTypeLabel.supplier_acceptance_certificate', { ns: 'file' }),
  [DocumentFileType.supplierAdditionalAgreement]: (t) =>
    t('docTypeLabel.supplier_additional_agreement', { ns: 'file' }),

  [DocumentFileType.signedCustomerAcceptanceCertificate]: (t) =>
    t('docTypeLabel.signed_customer_acceptance_certificate', { ns: 'file' }),
  [DocumentFileType.signedCustomerAdditionalAgreement]: (t) =>
    t('docTypeLabel.signed_customer_additional_agreement', { ns: 'file' }),

  [DocumentFileType.arbitraryDocuments]: (t) => t('docTypeLabel.arbitrary_documents', { ns: 'file' }),
  [DocumentFileType.warrantyLoa]: (t) => t('docTypeLabel.warranty_loa', { ns: 'file' }),
  [DocumentFileType.indirectTaxesPaymentCertificate]: (t) =>
    t('docTypeLabel.indirect_taxes_payment_certificate', { ns: 'file' }),
  [DocumentFileType.authorizationLetter]: (t) => t('docTypeLabel.authorization_letter', { ns: 'file' }),
  [DocumentFileType.finalizationPhoto]: (t) => t('docTypeLabel.finalization_photo', { ns: 'file' }),
};

export const getDocTypeLabel = (docType: DocumentFileType) => {
  return docType in mapDocTypeToLabel ? mapDocTypeToLabel[docType] : docType;
};
