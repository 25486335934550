import dayjs, { UnitTypeLong } from 'dayjs';

const CURRENT_DATE = dayjs();

type Params = {
  dateString: string | Date;
  formatWithUnit: string;
  formatWithoutUnit: string;
  unitType: UnitTypeLong;
};

export const getDateFormat = ({ dateString, formatWithUnit, formatWithoutUnit, unitType }: Params) => {
  return dayjs(dateString).isSameOrAfter(CURRENT_DATE, unitType) ? formatWithoutUnit : formatWithUnit;
};
