import { MapDataFieldsToNullable } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

export enum IngotIdType {
  year = 'year',
  uin = 'uin',
}

export type CustomerSpecificationDocPayload = {
  signatory_id: number;
  bars: IngotServer[];
};

export type IngotServer = {
  bar_number: string;
  weight: number;
  ligature_weight: Nullable<number>;
  metal_purity: number;
  year?: string;
  uin?: string;
};

export type CustomerSpecificationDocResponse = Partial<MapDataFieldsToNullable<CustomerSpecificationDocPayload>>;
