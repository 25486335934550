import { MessageInstance } from 'antd/es/message/interface';
import cn from 'classnames';
import { action, makeObservable } from 'mobx';
import { nanoid } from 'nanoid';

import s from '@/shared/styles/notification.module.scss';

import { IMessage } from '../types';

import { getContent } from './config';
import { INotificationsStore, MessageKey } from './types';

type Params = {
  messageApi: MessageInstance;
};

export class NotificationsStore implements INotificationsStore {
  private readonly _api: MessageInstance;

  constructor({ messageApi }: Params) {
    this._api = messageApi;

    makeObservable(this, {
      addNotification: action.bound,
    });
  }

  addNotification({ type, message, title, ...args }: IMessage): MessageKey {
    const key = nanoid();

    this._api.open({
      className: cn(s.message, title && s.message_title, s[`message_${type}`]),
      key,
      content: getContent({ title, message }),
      type: type,
      ...args,
    });

    return key;
  }

  destroy(key: MessageKey): void {
    this._api.destroy(key);
  }
}
