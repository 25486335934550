import { Flex } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks';
import { CustomAlert, CustomTypographyText } from '@/shared/ui';
import { FormUpload } from '@/shared/ui/form/FormUpload';
import { UploadDragger } from '@/shared/ui/form/UploadDragger';

import { DocumentFormModel } from '../../model';

type DocumentFormProps = {
  className?: string;
  model: DocumentFormModel;
  disabled?: boolean;
  uploadError?: boolean;
};

const DocumentForm: React.FC<DocumentFormProps> = ({ className, model, disabled, uploadError }) => {
  const { docFile, onChange } = model;
  const { t } = useTypedTranslation('file');

  const file = docFile.value[0];

  return (
    <Flex className={cn(className)} vertical gap={8}>
      {file ? (
        <>
          {uploadError && (
            <CustomAlert type="error" message={t('messages.uploadFileError', { count: docFile.value.length })} />
          )}
          <FormUpload model={docFile} disabled={disabled} />
          {file.error && <CustomTypographyText type="secondary">{file.error}</CustomTypographyText>}
        </>
      ) : (
        <UploadDragger {...docFile.props} onChange={onChange} disabled={disabled} />
      )}
    </Flex>
  );
};

export default observer(DocumentForm);
