import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import * as React from 'react';

import { colors } from '@/shared/styles/colors';
import { Nullable } from '@/shared/types/values';
import { TypographyTextWithTooltip } from '@/shared/ui';

import { BidPrices, BidPricesKeys } from '../../types';

type BidPriceProps = {
  priceKey: BidPricesKeys;
  bidPrices: Nullable<BidPrices>;
};

const BidPrice: React.FC<BidPriceProps> = ({ bidPrices, priceKey }) => {
  if (!bidPrices) {
    return null;
  }

  const price = bidPrices[priceKey];

  return (
    <Flex align="center" gap={8}>
      <TypographyTextWithTooltip text={price} />
      {bidPrices.isPositive && <ArrowUpOutlined style={{ color: colors.green_6, fontSize: 16 }} />}
      {bidPrices.isNegative && <ArrowDownOutlined style={{ color: colors.error_active, fontSize: 16 }} />}
    </Flex>
  );
};

export default BidPrice;
