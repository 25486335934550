import { DocumentFileType } from '@/entities/file';

export const finalizationStage = {
  en: {
    manager: {
      cargoDelivered: {
        title: 'Cargo delivered',
        description: 'When the cargo is accepted by the customer, confirm the completion of the trade',
      },
      tradeCloseCustomerDocs: {
        title: 'Upload documents for closing the trade with the customer',
        descriptions: {
          signedCertificate: 'Upload the signed customer acquisition protocol',
          additionalAgreement: 'Upload supplementary agreement',
        },
      },
      customerPayments: {
        title: 'Select user payment',
        description: 'Select fully or partially payment of the user',
        transactionId: 'Transaction {{ transactionId }}',
        completeTrade: 'Complete the trade',
        addTransaction: 'Add transaction',
        successModalTitle: 'Trade completed',
        placeholders: {
          number: 'Transaction №',
          date: 'Transaction date',
          price: 'Amount',
          priceUSD: 'Received in USD',
          comment: 'Leave a comment',
        },
      },
      tradeCompletionModal: {
        title: 'Confirm completion of the trade',
        okText: 'Complete the trade',
        totalPrice: 'Trade price',
        totalAmount: 'Current payment amount: {{ totalAmount }}',
        paymentList: 'Payments',
        alert: {
          description:
            'Verify that all payment for the transaction has been received from the customer, and only then click “Complete transaction”',
        },
      },
    },
    supplier: {
      cargoArrival: {
        title: 'Waiting for the cargo to arrive',
        waitDescription: 'Wait for the manager to mark the arrival of the cargo',
        approveDescription: 'The manager confirmed the arrival of the cargo',
      },
      tradeCloseSupplierDocs: {
        title: 'Waiting for closing documents',
        waitDescription: 'Wait for the manager to sign the closing documents for the deal',
        approveDescription: 'The manager signed the closing documents for the transaction',
        docsDescription: {
          [DocumentFileType.supplierAcceptanceCertificate]: 'Transfer and acceptance report',
        },
      },
      finish: 'The deal is completed',
    },
    customer: {
      cargoDelivery: {
        title: 'Waiting for the cargo to arrive',
        waitDescription: 'Wait for the manager to mark the arrival of the cargo',
        approveDescription: 'The manager confirmed the arrival of the cargo',
      },
      tradeCloseCustomerDocs: {
        title: 'Waiting for closing documents',
        waitDescription: 'Wait for the manager to upload the closing documents for the deal',
        approveDescription: 'The manager uploaded the closing documents for the transaction',
        docsDescription: {
          [DocumentFileType.signedCustomerAcceptanceCertificate]: 'Transfer and Acceptance Certificate',
          [DocumentFileType.signedCustomerAdditionalAgreement]: 'Supplementary agreement',
        },
      },
      finish: 'The deal is completed',
    },
  },
  ru: {
    manager: {
      cargoDelivered: {
        title: 'Груз доставлен',
        description: 'Когда груз будет принят покупателем, подтвердите завершение сделки',
      },
      tradeCloseCustomerDocs: {
        title: 'Загрузите документы для закрытия сделки с покупателем',
        descriptions: {
          signedCertificate: 'Загрузите подписанный с покупателем акт приема-передачи',
          additionalAgreement: 'Загрузите дополнительное соглашение',
        },
      },
      customerPayments: {
        title: 'Выберите оплату пользователя',
        description: 'Введите полностью или частично оплату пользователя',
        transactionId: 'Транзакция {{ transactionId }}',
        completeTrade: 'Завершить сделку',
        addTransaction: 'Добавить транзакцию',
        successModalTitle: 'Сделка завершена',
        placeholders: {
          number: '№ транзакции',
          date: 'Дата транзакции',
          price: 'Сумма',
          priceUSD: 'Получено в USD',
          comment: 'Оставьте комментарий',
        },
      },
      tradeCompletionModal: {
        title: 'Подтвердите завершение сделки',
        okText: 'Завершить сделку',
        totalPrice: 'Цена сделки',
        totalAmount: 'Текущая сумма оплаты: {{ totalAmount }}',
        paymentList: 'Оплаты',
        alert: {
          description:
            'Проверьте, что от покупателя получена вся оплата за сделку, и только после этого нажимайте ”Завершить сделку”',
        },
      },
    },
    supplier: {
      cargoArrival: {
        title: 'Ожидание прибытия груза',
        waitDescription: 'Дождитесь, пока менеджер отметит прибытие груза',
        approveDescription: 'Менеджер подтвердил прибытие груза',
      },
      tradeCloseSupplierDocs: {
        title: 'Ожидание закрывающих документов',
        waitDescription: 'Дождитесь, пока менеджер подпишет закрывающие документы по сделке',
        approveDescription: 'Менеджер подписал закрывающие документы по сделке',
        docsDescription: {
          [DocumentFileType.supplierAcceptanceCertificate]: 'Акт приема-передачи',
        },
      },
      finish: 'Cделка завершена',
    },
    customer: {
      cargoDelivery: {
        title: 'Ожидание прибытия груза',
        waitDescription: 'Дождитесь, пока менеджер отметит прибытие груза',
        approveDescription: 'Менеджер подтвердил прибытие груза',
      },
      tradeCloseCustomerDocs: {
        title: 'Ожидание закрывающих документов',
        waitDescription: 'Дождитесь, пока менеджер загрузит закрывающие документы по сделке',
        approveDescription: 'Менеджер загрузил закрывающие документы по сделке',
        docsDescription: {
          [DocumentFileType.signedCustomerAcceptanceCertificate]: 'Акт приема-передачи',
          [DocumentFileType.signedCustomerAdditionalAgreement]: 'Дополнительное соглашение',
        },
      },
      finish: 'Cделка завершена',
    },
  },
  zh: {
    manager: {
      cargoDelivered: {
        title: '货物已交付',
        description: '买方接受货物后，确认交易完成',
      },
      tradeCloseCustomerDocs: {
        title: '上传与卖家成交的文档',
        descriptions: {
          signedCertificate: '上传与卖家签署的验收证明书',
          additionalAgreement: '下载附加协议',
        },
      },
      customerPayments: {
        title: '选择用户付款',
        description: '输入用户的全部或部分付款',
        transactionId: '交易 {{ transactionId }}',
        completeTrade: '完成交易',
        addTransaction: '添加交易',
        successModalTitle: '交易完成',
        placeholders: {
          number: '交易号码',
          date: '交易日期',
          price: '金额',
          priceUSD: '收到美元',
          comment: '发表评论',
        },
      },
      tradeCompletionModal: {
        title: '请确认交易完成',
        okText: '完成交易',
        totalPrice: '交易金额',
        // totalAmount: 'Текущая сумма оплаты: {{ totalAmount }}',
        paymentList: '付款',
        alert: {
          description: '检查是否已收到买方支付的所有交易款项，然后点击 “完成交易”',
        },
      },
    },
    supplier: {
      cargoArrival: {
        title: '等待货物到达',
        waitDescription: '等待经理标记货物到达',
        approveDescription: '经理确认货物已经抵达',
      },
      tradeCloseSupplierDocs: {
        title: '等待结算文件',
        waitDescription: '等待经理签署交易结束文件',
        approveDescription: '经理签署交易结束文件',
        docsDescription: {
          [DocumentFileType.supplierAcceptanceCertificate]: '验收证书',
        },
      },
      finish: '交易完成',
    },
    customer: {
      cargoDelivery: {
        title: '等待货物到达',
        waitDescription: '等待经理标记货物到达',
        approveDescription: '经理确认货物已经抵达',
      },
      tradeCloseCustomerDocs: {
        title: '等待结算文件',
        waitDescription: '等待经理签上传交易完成文件',
        approveDescription: '经理签上传交易完成文件',
        docsDescription: {
          [DocumentFileType.signedCustomerAcceptanceCertificate]: '验收证书',
          [DocumentFileType.signedCustomerAdditionalAgreement]: '附加协议',
        },
      },
      finish: '交易完成',
    },
  },
};
