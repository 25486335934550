import { ManagerShipmentStep } from '@/entities/stage/types';

export const stepsOrder: ManagerShipmentStep[] = [
  ManagerShipmentStep.uploadSpec,
  ManagerShipmentStep.specVerifying,
  ManagerShipmentStep.shipmentType,
  ManagerShipmentStep.transitCountry,
  ManagerShipmentStep.transitCountryAwb,
  ManagerShipmentStep.transitCountryAwbVerifying,
  ManagerShipmentStep.destinationCountryDocs,
  ManagerShipmentStep.destinationCountryAwb,
  ManagerShipmentStep.cargoShipment,
  ManagerShipmentStep.transfer,
];
