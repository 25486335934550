import { ValueModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import { BaseFieldsModel, BaseFieldsModelParams } from '../BaseFieldsModel';

type Params<Step, Action> = BaseFieldsModelParams<Step, Action> & {
  confirmed: ValueModel<boolean>;
};

export class ConfirmationFieldsModel<Step, Action> extends BaseFieldsModel<Step, Action> {
  readonly confirmed: ValueModel<boolean>;

  constructor({ confirmed, ...params }: Params<Step, Action>) {
    super(params);

    this.confirmed = confirmed;
  }

  static fromJson<Step, Action>({
    data,
    ...params
  }: {
    data?: Nullable<boolean>;
  } & BaseFieldsModelParams<Step, Action>): ConfirmationFieldsModel<Step, Action> {
    return new ConfirmationFieldsModel({
      confirmed: new ValueModel(Boolean(data)),
      ...params,
    });
  }
}
