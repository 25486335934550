import { SelectProps } from 'antd/es/select';

import { isStringOrNumber } from '@/shared/types/typesGuard';

export const filterOption: SelectProps['filterOption'] = (input, option) => {
  return isStringOrNumber(option?.title) && String(option.title).toLowerCase().includes(input.toLowerCase());
};

export const filterSort: SelectProps['filterSort'] = (optionA, optionB) => {
  return isStringOrNumber(optionA.title) && isStringOrNumber(optionB.title)
    ? String(optionA.title).toLowerCase().localeCompare(String(optionB.title).toLowerCase())
    : 0;
};
