import { Tabs, TabsProps } from 'antd';
import * as React from 'react';

import { TranslationString } from '@/shared/types/localization';

import { TransComp } from '../TransComp';

type Tab = Required<TabsProps>['items'][number];
export type CustomTab = Omit<Tab, 'label'> & {
  label: TranslationString;
};

export type CustomTabsProps = Omit<TabsProps, 'items'> & {
  items?: Array<CustomTab>;
};

const CustomTabs: React.FC<CustomTabsProps> = ({ items = [], ...props }) => {
  const itemsList: Array<Tab> = React.useMemo(() => {
    return items.map((item) => ({
      ...item,
      label: <TransComp>{item.label}</TransComp>,
    }));
  }, [items]);

  return <Tabs {...props} items={itemsList} />;
};

export default CustomTabs;
