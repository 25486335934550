import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { routerUrls } from '@/shared/config/routes';

import { App } from '../App';
import { withAuthCheck } from '../hocs/withAuthCheck';
import { withDictionaries } from '../hocs/withDictionaries';
import { sentryCreateBrowserRouter } from '../scripts/initSentry';

import {
  bidsPageRoleDictionariesArgsMap,
  bidsPageRoleDictionariesMap,
  chatsPageRoleDictionariesArgsMap,
  chatsPageRoleDictionariesMap,
  documentsPageRoleDictionariesMap,
  profilePageRoleDictionariesMap,
  tradesPageRoleDictionariesMap,
  tradeWorkflowPageRoleDictionariesMap,
} from './config';

/* Страницы с предзагруженными словарями */
const TradesPageWithDictionaries = withDictionaries({
  Component: React.lazy(() => import('@/pages/Trades')),
  dictionariesMap: tradesPageRoleDictionariesMap,
});
const TradePageWithDictionaries = withDictionaries({
  Component: React.lazy(() => import('@/pages/Trade')),
  dictionariesMap: tradeWorkflowPageRoleDictionariesMap,
});
const ProfilePageWithDictionaries = withDictionaries({
  Component: React.lazy(() => import('@/pages/Profile')),
  dictionariesMap: profilePageRoleDictionariesMap,
});
const BidsPageWithDictionaries = withDictionaries({
  Component: React.lazy(() => import('@/pages/Bids')),
  dictionariesMap: bidsPageRoleDictionariesMap,
  dictionariesArgs: bidsPageRoleDictionariesArgsMap,
});

const DocumentPageWithDictionaries = withDictionaries({
  Component: React.lazy(() => import('@/pages/Documents')),
  dictionariesMap: documentsPageRoleDictionariesMap,
});

const ChatsPageWithDictionaries = withDictionaries({
  Component: React.lazy(() => import('@/pages/Chats')),
  dictionariesMap: chatsPageRoleDictionariesMap,
  dictionariesArgs: chatsPageRoleDictionariesArgsMap,
});

/* Страницы доступные только авторизованным пользователям */
const MainPage = withAuthCheck({ Component: React.lazy(() => import('@/pages/Main')) });
const ClientsPage = withAuthCheck({ Component: React.lazy(() => import('@/pages/Clients')) });
const AgentsPage = withAuthCheck({ Component: React.lazy(() => import('@/pages/Agents')) });
const TradesPage = withAuthCheck({ Component: TradesPageWithDictionaries });
const TradePage = withAuthCheck({ Component: TradePageWithDictionaries });
const DocumentsPage = withAuthCheck({ Component: DocumentPageWithDictionaries });
const ChatsPage = withAuthCheck({ Component: ChatsPageWithDictionaries });

const ProfilePage = withAuthCheck({ Component: ProfilePageWithDictionaries });
const EditProfile = withAuthCheck({ Component: React.lazy(() => import('@/features/profile/EditProfile')) });
const EditCompany = withAuthCheck({ Component: React.lazy(() => import('@/features/profile/EditCompany')) });

const NotificationList = withAuthCheck({
  Component: React.lazy(() => import('@/features/notifications/NotificationList')),
});
const NotificationsSettings = withAuthCheck({
  Component: React.lazy(() => import('@/features/notifications/NotificationsSettings')),
});

const BidsPage = withAuthCheck({ Component: BidsPageWithDictionaries });
const Buy = withAuthCheck({
  Component: React.lazy(() => import('@/features/bids/Buy')),
});
const Sell = withAuthCheck({
  Component: React.lazy(() => import('@/features/bids/Sell')),
});
const MyBids = withAuthCheck({
  Component: React.lazy(() => import('@/features/bids/MyBids')),
});
const Reply = withAuthCheck({
  Component: React.lazy(() => import('@/features/bids/Reply')),
});

/* Страницы доступные только неавторизованным пользователям */
const LoginPage = withAuthCheck({ Component: React.lazy(() => import('@/pages/Login')), needAuth: false });
const CreatePasswordPage = withAuthCheck({
  Component: React.lazy(() => import('@/pages/CreatePassword')),
  needAuth: false,
});

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: routerUrls.main.mask,
        element: <MainPage />,
      },
      {
        path: routerUrls.login.mask,
        element: <LoginPage />,
      },
      {
        path: routerUrls.createPassword.mask,
        element: <CreatePasswordPage />,
      },
      {
        path: routerUrls.clients.mask,
        element: <ClientsPage />,
      },
      {
        path: routerUrls.agents.mask,
        element: <AgentsPage />,
      },
      {
        path: routerUrls.trades.mask,
        element: <TradesPage />,
      },
      {
        path: routerUrls.trade.mask,
        element: <TradePage />,
      },
      {
        path: routerUrls.documents.mask,
        element: <DocumentsPage />,
      },
      {
        path: routerUrls.profile.mask,
        element: <ProfilePage />,
        children: [
          {
            path: routerUrls.profile.children.me.mask,
            element: <EditProfile />,
          },
          {
            path: routerUrls.profile.children.company.mask,
            element: <EditCompany />,
          },
          {
            path: routerUrls.profile.children.notifications.mask,
            children: [
              {
                path: routerUrls.profile.children.notifications.mask,
                element: <NotificationList />,
              },
              {
                path: routerUrls.profile.children.notifications.children.settings.mask,
                element: <NotificationsSettings />,
              },
              {
                path: '*',
                element: <Navigate to={routerUrls.profile.children.notifications.mask} replace />,
              },
              {
                path: routerUrls.profile.children.notifications.mask,
                element: <Navigate to={routerUrls.profile.children.notifications.mask} replace />,
              },
            ],
          },
          {
            path: '*',
            element: <Navigate to={routerUrls.profile.children.me.mask} replace />,
          },
          {
            path: routerUrls.profile.mask,
            element: <Navigate to={routerUrls.profile.children.me.mask} replace />,
          },
        ],
      },
      {
        path: routerUrls.chats.mask,
        element: <ChatsPage />,
      },
      {
        path: routerUrls.bids.mask,
        element: <BidsPage />,
        children: [
          {
            path: routerUrls.bids.children.buy.mask,
            element: <Buy />,
          },
          {
            path: routerUrls.bids.children.sell.mask,
            element: <Sell />,
          },
          {
            path: routerUrls.bids.children.my.mask,
            element: <MyBids />,
          },
          {
            path: routerUrls.bids.children.reply.mask,
            element: <Reply />,
          },
          {
            path: '*',
            element: <Navigate to={routerUrls.bids.children.buy.mask} replace />,
          },
          /**
           * Путь по умолчанию регулируется в компоненте SegmentBids в зависимости от роли пользователя
           */
        ],
      },

      {
        path: '*',
        element: <Navigate to={'/'} replace />,
      },
    ],
  },
]);

export default router;
