export const creationStage = {
  en: {
    manager: {
      alert: {
        message: 'Complete the creation of a deal',
        description: 'Please enter the missing information about the deal',
      },
    },
    supplier: {
      waiting: 'Wait for the manager to complete the creation of the deal',
      created: 'Deal created',
    },
  },
  ru: {
    manager: {
      alert: {
        message: 'Завершите создание сделки',
        description: 'Пожалуйста, впишите недостающую информацию по сделке',
      },
    },
    supplier: {
      waiting: 'Ожидайте, пока менеджер закончит создание сделки',
      created: 'Сделка создана',
    },
  },
  zh: {
    manager: {
      alert: {
        message: '完成创建交易',
        description: '请填写缺失的交易信息',
      },
    },
    supplier: {
      waiting: '请等待经理完成创建交易',
      created: '交易已创建',
    },
  },
};
