import { Drawer } from 'antd';
import cn from 'classnames';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { CustomTypographyTitle } from '@/shared/ui';
import { getPathname } from '@/shared/utils';

import { menuUrlsMap } from '../../config';
import { SideMenu } from '../SideMenu';
import { SideMenuButton } from '../SideMenuButton';

import s from './SideMenuNavBar.module.scss';

type SideMenuNavBarProps = {
  className?: string;
  isMenuOpened: boolean;
  toggleMenuOpen: VoidFunction;
};

const SideMenuNavBar: React.FC<SideMenuNavBarProps> = ({ className, isMenuOpened, toggleMenuOpen }) => {
  const location = useLocation();
  const pathName = getPathname(location);

  return (
    <>
      <div className={cn(s.navbar, className)}>
        <SideMenuButton className={s.navbar_button} onClick={toggleMenuOpen} />
        <CustomTypographyTitle className={s.navbar_title} level={4}>
          {menuUrlsMap[pathName].label}
        </CustomTypographyTitle>
      </div>
      <Drawer className={s.navbar_drawer} placement="left" width={248} open={isMenuOpened} onClose={toggleMenuOpen}>
        <SideMenu className={s.navbar_drawer_menu} isMenuOpened={true} closeMenu={toggleMenuOpen} />
      </Drawer>
    </>
  );
};

export default React.memo(SideMenuNavBar);
