import { SupplierPaymentStep } from '@/entities/stage/types';
import { Nullable } from '@/shared/types/values';

export const mapSupplierStepToNumber = (step: Nullable<SupplierPaymentStep>): number => {
  switch (step) {
    case SupplierPaymentStep.uploadBill:
      return 0;

    case SupplierPaymentStep.payBill:
      return 1;

    case SupplierPaymentStep.transfer:
      return 2;

    default:
      return -1;
  }
};
