import { BaseResponse } from '@kts-front/types';
import { action, computed, makeObservable, reaction, runInAction } from 'mobx';

import { UserServer, UserType } from '@/entities/user';
import { AgentModel } from '@/entities/user/model/AgentModel';
import { CustomerModel } from '@/entities/user/model/CustomerModel';
import { SupplierModel } from '@/entities/user/model/SupplierModel';
import { apiStore, apiUrls } from '@/shared/api';
import { LSKey } from '@/shared/config/storageKeys';
import { LoadingStageModel, LocalStore, ValueModel } from '@/shared/model';
import { RequestPollingModel } from '@/shared/model/RequestPollingModel';
import { ownLocalStorage, ownSessionStorage } from '@/shared/model/storage';
import { ApiErrorCode, ApiErrorData } from '@/shared/types/api';
import { Nullable } from '@/shared/types/values';

import { AdminModel } from '../AdminModel';
import { ManagerModel } from '../ManagerModel';
import { UserModelType } from '../types';

import { IUserStore } from './types';

export class UserStore extends LocalStore implements IUserStore {
  private _userModel = new ValueModel<Nullable<UserModelType>>(null);

  readonly notificationsPollingEvent: RequestPollingModel;

  readonly userLoadingStage = new LoadingStageModel();

  constructor() {
    super();

    this.notificationsPollingEvent = new RequestPollingModel({
      request: this._getNotificationsCount,
      timeout: 10_000,
    });

    makeObservable(this, {
      authorized: computed,
      userModel: computed,
      userRole: computed,
      isManagerRole: computed,
      isAdminRole: computed,
      isCustomerRole: computed,
      isSupplierRole: computed,
      newNotificationsCount: computed,

      logout: action.bound,
    });

    this.addReactions([
      reaction(
        () => this.authorized && !this.isMainRole,
        (isNeedPolling) => {
          if (isNeedPolling) {
            this.notificationsPollingEvent.watchProgress();
          } else {
            this.notificationsPollingEvent.stopPolling();
          }
        },
      ),
    ]);
  }

  get userModel(): Nullable<UserModelType> {
    return this._userModel.value;
  }

  get userRole(): Nullable<UserType> {
    return this.userModel?.type ?? null;
  }

  get isManagerRole(): boolean {
    return this.userRole === UserType.manager;
  }

  get isAdminRole(): boolean {
    return this.userRole === UserType.admin;
  }

  get isMainRole(): boolean {
    return this.isManagerRole || this.isAdminRole;
  }

  get isCustomerRole(): boolean {
    return this.userRole === UserType.customer;
  }

  get isSupplierRole(): boolean {
    return this.userRole === UserType.supplier;
  }

  get authorized(): boolean {
    return Boolean(this._userModel.value);
  }

  get newNotificationsCount(): number {
    return this.userModel?.newNotificationsCount.value ?? 0;
  }

  getCurrentUser = async (): Promise<BaseResponse> => {
    if (this.userLoadingStage.isLoading) {
      return { isError: true };
    }

    this.userLoadingStage.loading();

    const response = await this._currentUserRequest.call();

    if (response.isError) {
      this.userLoadingStage.error();

      const data: Nullable<ApiErrorData> = response.data?.data ?? null;

      if (data && data.code === ApiErrorCode.tokenNotValid) {
        this._removeToken();
      }

      return { isError: true };
    }

    runInAction(() => {
      this.setUserFromJson(response.data);
      this.userLoadingStage.success();
    });

    return { isError: false };
  };

  setUserFromJson = (raw: UserServer): void => {
    let userModel: UserModelType;

    switch (raw.type) {
      case UserType.customer: {
        userModel = CustomerModel.fromJson(raw);

        break;
      }

      case UserType.agent: {
        userModel = AgentModel.fromJson(raw);

        break;
      }

      case UserType.supplier: {
        userModel = SupplierModel.fromJson(raw);

        break;
      }

      case UserType.manager: {
        userModel = ManagerModel.fromJson(raw);

        break;
      }

      case UserType.admin: {
        userModel = AdminModel.fromJson(raw);

        break;
      }
    }

    this._setUser(userModel);
  };

  logout(): void {
    this._clearStorage();
    this._setUser(null);
  }

  private readonly _currentUserRequest = apiStore.createRequest<UserServer>({
    url: apiUrls.user.current,
  });

  private readonly _setUser = (value: Nullable<UserModelType>) => {
    this._userModel.change(value);
  };

  private readonly _getNotificationsCount = async (): Promise<BaseResponse> => {
    if (!this.userModel) {
      return { isError: true };
    }

    const response = await this._currentUserRequest.call();

    if (response.isError) {
      const data: Nullable<ApiErrorData> = response.data?.data ?? null;

      if (data && data.code === ApiErrorCode.tokenNotValid) {
        this._removeToken();
      }

      return { isError: true };
    }

    if (this.userModel) {
      this.userModel.newNotificationsCount.change(response.data.new_notifications_count);
    }

    return { isError: false };
  };

  private readonly _clearStorage = (): void => {
    ownLocalStorage.clear();
    ownSessionStorage.clear();
  };

  private readonly _removeToken = (): void => {
    ownLocalStorage.removeItem(LSKey.accessToken);
    ownSessionStorage.removeItem(LSKey.accessToken);
  };
}
