import { Result } from 'antd';
import { ResultStatusType } from 'antd/es/result';
import cn from 'classnames';
import * as React from 'react';

import { TranslationNode } from '@/shared/types/localization';

import { TransComp } from '../TransComp';

import s from './CustomResult.module.scss';

type CustomResultProps = React.PropsWithChildren<{
  className?: string;
  title: TranslationNode;
  subTitle?: TranslationNode;
  icon?: React.ReactNode;
  titleSize?: 'large' | 'small';
  status?: ResultStatusType;
}>;

const CustomResult: React.FC<CustomResultProps> = ({ className, titleSize = 'small', title, subTitle, ...props }) => {
  return (
    <Result
      className={cn(s.result, s[`result_title-${titleSize}`], className)}
      {...props}
      title={<TransComp>{title}</TransComp>}
      subTitle={<TransComp>{subTitle}</TransComp>}
    />
  );
};

export default React.memo(CustomResult);
