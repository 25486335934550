import { TranslationString } from '@/shared/types/localization';

import { mapBidStatusToLabel } from '../../config';
import { BidStatus } from '../../types';

type BidTypeTagConfig = {
  color: string;
  label: TranslationString;
};

export const mapStatusToConfig: Record<BidStatus, BidTypeTagConfig> = {
  [BidStatus.new]: {
    color: 'orange',
    label: mapBidStatusToLabel(BidStatus.new),
  },
  [BidStatus.review]: {
    color: 'purple',
    label: mapBidStatusToLabel(BidStatus.review),
  },
  [BidStatus.published]: {
    color: 'blue',
    label: mapBidStatusToLabel(BidStatus.published),
  },
  [BidStatus.inProgress]: {
    color: 'volcano',
    label: mapBidStatusToLabel(BidStatus.inProgress),
  },
  [BidStatus.approved]: {
    color: 'green',
    label: mapBidStatusToLabel(BidStatus.approved),
  },
  [BidStatus.rejected]: {
    color: 'red',
    label: mapBidStatusToLabel(BidStatus.rejected),
  },
  [BidStatus.cancelled]: {
    color: 'red',
    label: mapBidStatusToLabel(BidStatus.cancelled),
  },
  [BidStatus.tradeCreated]: {
    color: 'cyan',
    label: mapBidStatusToLabel(BidStatus.tradeCreated),
  },
};
