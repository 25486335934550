import { NameSpaceTranslation, SupportedLocale, Translation, Translations } from '@/shared/types/localization';

import { I18nResource, NameSpace, NameSpaces } from './types';

const mergeResources = (
  firstResource: I18nResource,
  secondResource: I18nResource,
  locale: SupportedLocale,
): I18nResource => ({
  ...firstResource,
  ...secondResource,
  [locale]: {
    ...firstResource[locale],
    ...secondResource[locale],
  },
});

export const adaptLoadedResources = (nameSpaces: NameSpaces): I18nResource => {
  const flatNameSpaces = Object.entries(nameSpaces) as Array<[NameSpace, NameSpaceTranslation]>;

  return flatNameSpaces.reduce<I18nResource>((resources, [nameSpace, nameSpaceResources]) => {
    const locales = Object.entries(nameSpaceResources) as Array<[SupportedLocale, Translation]>;

    return locales.reduce<I18nResource>((resource, [locale, localeResources]) => {
      resource[locale] = { [nameSpace]: localeResources } as Translations;

      return mergeResources(resources, resource, locale);
    }, {} as I18nResource);
  }, {} as I18nResource);
};
