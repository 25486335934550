import {
  DocumentFileType,
  UploadOrGenerationDocumentModel,
  UploadOrGenerationDocumentModelParams,
} from '@/entities/file';
import { InvoiceDocPayload } from '@/entities/stage/types';

import { InvoiceFiedsModel } from './InvoiceFiedsModel';

export type InvoiceDocTypes = DocumentFileType.exportInvoice | DocumentFileType.customerCommercialInvoice;

export class InvoiceModel<DocType extends InvoiceDocTypes> extends UploadOrGenerationDocumentModel<
  DocType,
  InvoiceDocPayload,
  InvoiceFiedsModel<DocType>
> {
  readonly generationFields: InvoiceFiedsModel<DocType>;

  constructor(params: UploadOrGenerationDocumentModelParams<DocType>) {
    super(params);

    this.generationFields = new InvoiceFiedsModel(params);
  }
}
