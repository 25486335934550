import { Checkbox, CheckboxProps } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { TranslationString } from '@/shared/types/localization';

import { TransComp } from '../../TransComp';

import s from './CustomCheckbox.module.scss';

type CustomCheckboxProps = CheckboxProps & {
  label?: TranslationString;
  fullWidth?: boolean;
};

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ className, fullWidth = false, label, ...props }) => {
  return (
    <Checkbox className={cn(s.checkbox, fullWidth && s.checkbox_fullWidth, className)} {...props}>
      <TransComp>{label}</TransComp>
    </Checkbox>
  );
};

export default React.memo(CustomCheckbox);
