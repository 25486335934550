import { Radio } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@/app/store';
import { AwbType, StageModel, StepComponentProps } from '@/entities/stage';
import { CustomAlert, StepDescription } from '@/shared/ui';
import { FormInput } from '@/shared/ui/form/FormInput';
import { FormUpload } from '@/shared/ui/form/FormUpload';
import { ApproveButton } from '@/shared/ui/form/buttons';

import { UploadAwbOrFlightNumberModel } from '../../model/fieldsModel/UploadAwbOrFlightNumberModel';

import s from './AwbOrFlightNumberStep.module.scss';

type AwbOrFlightNumberStepProps<StageModel> = StepComponentProps<StageModel>;

const AwbOrFlightNumberStep = <Model extends StageModel>({ model, fieldKey }: AwbOrFlightNumberStepProps<Model>) => {
  const { userStore } = useRootStore();
  const { t } = useTranslation('stage');

  const fields = fieldKey && model[fieldKey];

  if (!(fields instanceof UploadAwbOrFlightNumberModel)) {
    return null;
  }

  return (
    <StepDescription>
      <Radio.Group
        size="small"
        className={s.radio}
        disabled={fields.isDisabled}
        defaultValue={fields.awbType.value}
        onChange={(e) => {
          fields.awbType.change(e.target.value);
          fields.reset();
        }}
      >
        <Radio.Button value={AwbType.file}>{t('requiredStep.awbDoc.buttonFile')}</Radio.Button>
        <Radio.Button value={AwbType.number}> {t('requiredStep.awbDoc.buttonNumber')}</Radio.Button>
      </Radio.Group>

      {fields.awbType.value === AwbType.file ? (
        <>
          {userStore.isSupplierRole && fields.isRejected && (
            <CustomAlert
              type="error"
              message={(t) => t('messages.rejectedDocError', { ns: 'file' })}
              description={fields.error}
            />
          )}
          <FormUpload model={fields.file} disabled={fields.isDisabled || fields.isLoading} />
        </>
      ) : (
        <FormInput model={fields.flightNumber} disabled={fields.isDisabled} />
      )}
      <ApproveButton
        loading={fields.isLoading}
        disabled={fields.isDisabled || fields.approveDisabled}
        onClick={fields.approveStep}
      />
    </StepDescription>
  );
};

export default observer(AwbOrFlightNumberStep);
