export const paymentStage = {
  en: {
    manager: {
      uploadBill: {
        title: 'Upload invoice',
        description: 'Upload bill and click “Approve“',
      },
      payBill: {
        title: 'Pay invoice',
        description: 'Pay invoice, upload payment order with the mark “Done“ and click “Approve“',
      },
    },
    supplier: {
      uploadBill: {
        title: 'Upload invoice',
        description: 'Upload invoice and click “Approve“',
      },
      payBill: {
        title: 'Wait for payment order',
        description: 'Wait until the manager uploads the payment order marked “Executed”',
      },
      billPaid: 'Bill paid',
    },
  },
  ru: {
    manager: {
      uploadBill: {
        title: 'Загрузите счет',
        description: 'Загрузите счет и нажмите “Подтвердить“',
      },
      payBill: {
        title: 'Оплатите счет',
        description: 'Оплатите счет, загрузите платежное поручение с пометкой “Исполнено“ и нажмите “Подтвердить“',
      },
    },
    supplier: {
      uploadBill: {
        title: 'Загрузите счет',
        description: 'Загрузите счет и нажмите “Подтвердить“',
      },
      payBill: {
        title: 'Ожидание платежного поручения',
        description: 'Дождитесь, пока менеджер загрузит платежное поручение с пометкой “Исполнено“',
      },
      billPaid: 'Cчет оплачен',
    },
  },
  zh: {
    manager: {
      uploadBill: {
        title: '上传账单',
        description: '上传账单并点击‘确认’',
      },
      payBill: {
        title: '支付账单',
        description: '支付账单，上传标有“已完成”的付款单，点击“确认”',
      },
    },
    supplier: {
      uploadBill: {
        title: '上传账单',
        description: '上传账单并点击‘确认’',
      },
      payBill: {
        title: '等待付款订单',
        description: '请等待经理上传标记为“已完成”的付款订单',
      },
      billPaid: '订单已付款',
    },
  },
};
