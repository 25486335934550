import { InputModel } from '@/shared/model/form/InputModel';
import { Nullable } from '@/shared/types/values';
import { emptyValueValidator, stringLengthValidator } from '@/shared/utils/validators';

import { ClientFieldsModel, ClientFieldsParams, ClientPayload } from './ClientFieldsModel';

type ClientEditPayload = ClientPayload & {
  name: string;
};

type ClientEditFiledsParams = ClientFieldsParams & {
  name: string;
};

const T_OPTIONS = { ns: 'user' } as const;

export class ClientEditFiledsModel extends ClientFieldsModel {
  readonly name: InputModel;

  constructor({ name, ...params }: ClientEditFiledsParams) {
    super(params);

    this.name = new InputModel({
      initialValue: name,
      label: (t) => t('fields.fio', T_OPTIONS),
      placeholder: (t) => t('fields.fio', T_OPTIONS),
      validators: [emptyValueValidator(), stringLengthValidator(128)],
      required: true,
    });

    this.fields = [this.email, this.phone, this.name];
  }

  toJson = (): Nullable<ClientEditPayload> => {
    if (!this.email.value && !this.phone.value && !this.name.value) {
      return null;
    }

    return {
      email: this.email.value,
      phone: this.phone.value,
      name: this.name.value,
    };
  };
}
