import { ID } from '@/shared/types/meta';

import { IUnitDictionary, UnitDictionaryServer } from '../types';

export class UnitDictionaryModel<Value extends ID = number, Slug extends string = string>
  implements IUnitDictionary<Value, Slug>
{
  readonly value: Value;
  readonly label: string;
  readonly title: string;
  readonly slug: Slug;

  constructor({ value, label, title, slug }: IUnitDictionary<Value, Slug>) {
    this.value = value;
    this.label = label;
    this.title = title;
    this.slug = slug;
  }

  static fromJson<Value extends ID = number, Slug extends string = string>(
    server: UnitDictionaryServer<Value, Slug>,
  ): UnitDictionaryModel<Value, Slug> {
    return new UnitDictionaryModel<Value, Slug>({
      value: server.id,
      label: server.name,
      title: server.name,
      slug: server.slug,
    });
  }
}
