import { action, comparer, computed, makeObservable, observable } from 'mobx';

import { IListParams, ListModel } from './ListModel';

type IListOffsetParams<T, K extends PropertyKey> = IListParams<T, K> & {
  offset?: number;
};

const ITEMS_ON_PAGE = 10;

const defaultParams = {
  limit: ITEMS_ON_PAGE,
  offset: 0,
};

export class ListOffsetModel<T, K extends PropertyKey = string> extends ListModel<T, K> {
  private _offset: number;
  private _page: number;

  constructor(initialParams?: IListOffsetParams<T, K>) {
    const { offset, ...params } = { ...defaultParams, ...initialParams };

    super(params);

    this._offset = offset;
    this._page = offset ? Math.ceil(offset / this._limit) + 1 : 1;

    type PrivateFields = '_offset' | '_page';

    makeObservable<ListOffsetModel<T, K>, PrivateFields>(this, {
      _offset: observable,
      _page: observable,

      offset: computed,
      page: computed,
      pagesNumber: computed({ equals: comparer.identity }),

      changePage: action,
      changeOffset: action,
    });
  }

  get offset(): number {
    return this._offset;
  }

  get page(): number {
    return this._page;
  }

  get pagesNumber(): number {
    return Math.ceil(this._total / this._limit);
  }

  changePage = (page: number): void => {
    this._page = page;
    this._offset = (page - 1) * this._limit;
  };

  changeOffset = (offset: number): void => {
    this._offset = offset;
    this._page = Math.ceil(offset / this._limit) + 1;
  };

  reset(): void {
    super.reset();
    this._offset = 0;
    this._page = 1;
  }
}
