import { BaseResponse } from '@kts-front/types';

import { ListModel, LoadingStageModel } from '@/shared/model';
import { ID } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

import { DictionaryModel } from '../model/DictionaryModel';

import { ProductType, ProductWeightType } from './server';

export interface IDictionary<T extends ID = number> {
  value: T;
  label: string;
  /**
   * Необходим для корректной работы поиска в селектах
   */
  title: string;
}

export interface IUnitDictionary<T extends ID = number, S extends string = string> extends IDictionary<T> {
  slug: S;
}

export interface IProductTypeDictionary extends IDictionary {
  type: ProductType;
  productList: ListModel<IUnitDictionary, number>;
  unitList: ListModel<IUnitDictionary, number>;
}

export interface IProductPrice {
  date: Date;
  current: number;
  previous: Nullable<number>;
  currency: IUnitDictionary;
}

export interface IProductDictionary extends IDictionary {
  type: string;
  spotPrice: Nullable<IProductPrice>;
  londonPrice: Nullable<IProductPrice>;
  shapeList: ListModel<IUnitDictionary, number>;
  unitList: ListModel<IUnitDictionary<number, ProductWeightType>, number>;
}

export interface ISignatoryDictionary extends IDictionary {
  type: Nullable<string>;
  signLink: Nullable<string>;
}

export enum DictionaryField {
  suppliers = 'suppliers',
  customers = 'customers',
  countries = 'countries',
  currencies = 'currencies',
  legalCompanies = 'legalCompanies',
  logisticCompanies = 'logisticCompanies',
  productTypes = 'productTypes',
  products = 'products',
  signatories = 'signatories',
}

export interface IDictionaryStore<Model extends DictionaryModel<ID>, K extends ID = number> {
  readonly list: ListModel<Model, K>;
  load(): Promise<BaseResponse>;
}

export interface IProductsStore {
  readonly list: ListModel<ListModel<IProductDictionary, number>, ProductType>;
  load(type: ProductType): Promise<BaseResponse>;
}

export type DictionaryFielsdArgs = Partial<{
  [DictionaryField.products]: Parameters<IProductsStore['load']>;
}>;

export type DictionaryFielsdWithArgs = keyof DictionaryFielsdArgs;

export interface IDictionariesStore {
  readonly legalCompanies: IDictionaryStore<IDictionary>;
  readonly logisticCompanies: IDictionaryStore<IDictionary>;
  readonly currencies: IDictionaryStore<IDictionary>;
  readonly productTypes: IDictionaryStore<IProductTypeDictionary>;
  readonly products: IProductsStore;
  readonly countries: IDictionaryStore<IDictionary>;
  readonly suppliers: IDictionaryStore<IDictionary>;
  readonly customers: IDictionaryStore<IDictionary>;
  readonly signatories: IDictionaryStore<ISignatoryDictionary>;

  readonly loadingStage: LoadingStageModel;
  readonly isInitialLoading: boolean;

  loadClients(): Promise<BaseResponse>;
  loadDictionaries(fields: DictionaryField[], args?: DictionaryFielsdArgs): Promise<BaseResponse>;
}

const dictionariesWithArgs: Array<DictionaryFielsdWithArgs> = [DictionaryField.products];

export const isDictionaryFieldNeedArgs = (field: DictionaryField): field is DictionaryFielsdWithArgs => {
  return dictionariesWithArgs.some((fieldWithArgs) => fieldWithArgs === field);
};
