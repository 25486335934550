import { UploadOutlined } from '@ant-design/icons';
import * as React from 'react';

import { CustomButton } from '../../CustomButton';
import { BaseButtonProps } from '../types';

const UploadButton: React.FC<BaseButtonProps> = ({ text = (t) => t('buttons.upload', { ns: 'shared' }), ...props }) => {
  return (
    <CustomButton type="primary" size="small" icon={<UploadOutlined />} {...props}>
      {text}
    </CustomButton>
  );
};

export default React.memo(UploadButton);
