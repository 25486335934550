import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { action, computed, makeObservable } from 'mobx';

import { UploadFileModel } from '@/shared/model/form/UploadFileModel';
import { fileSizeValidator } from '@/shared/utils/validators';

export class DocumentFormModel {
  readonly docFile: UploadFileModel;

  constructor(files?: UploadFile[]) {
    this.docFile = new UploadFileModel({
      initialValue: files ?? [],
      label: (t) => t('documentForm.docFile.label', { ns: 'file' }),
      required: true,
    });

    makeObservable(this, {
      isError: computed,

      onChange: action.bound,
      reset: action.bound,
    });
  }

  get isError(): boolean {
    return this.docFile.isError || this.docFile.value.some((file) => file.error);
  }

  onChange({ fileList }: UploadChangeParam) {
    fileList = fileList.map((file) => {
      const error = fileSizeValidator([file]);

      return {
        ...file,
        error,
        status: error ? 'error' : 'done',
      };
    });

    this.docFile.change(fileList);
  }

  reset(): void {
    this.docFile.reset();
  }
}
