import { LoadingOutlined } from '@ant-design/icons';
import { Pagination, Table, TableProps } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { HeightsMap, useUpdateTableContent, useWidth } from '@/shared/hooks';
import { TranslationString } from '@/shared/types/localization';
import { CustomEmpty, EmptySearchStub, Loader } from '@/shared/ui';

import { BaseBidListFilters, BidModel } from '../../model';
import { IBaseBidListStore, IBidTableData } from '../../types';

import s from './BidTable.module.scss';

type BidTableProps<M extends BidModel, F extends BaseBidListFilters<M>, C extends IBidTableData<M>> = {
  className?: string;
  store: IBaseBidListStore<M, F, C>;
  onRow: TableProps<C>['onRow'];
  emptyListDescription?: TranslationString;
};

const HEIGHTS: HeightsMap = {
  desktop: {
    ROW_HEIGHT: 77,
    TABLE_HEADER_HEIGHT: 93,
    PAGINATION_HEIGHT: 72,
  },
  tablet: {
    ROW_HEIGHT: 61,
    TABLE_HEADER_HEIGHT: 78,
    PAGINATION_HEIGHT: 64,
  },
};

const BidTable = <M extends BidModel, F extends BaseBidListFilters<M>, C extends IBidTableData<M>>({
  className,
  store,
  onRow,
  emptyListDescription,
}: BidTableProps<M, F, C>): React.ReactElement<BidTableProps<M, F, C>> => {
  const { isInitialLoading, isEmptyList, isEmptyFilteredList, list, filters, columns, rows, changePage } = store;
  const { isMobile } = useWidth();

  const pageRef = React.useRef<HTMLDivElement>(null);

  useUpdateTableContent(pageRef, store.refetchByLimit, HEIGHTS);

  const loader: TableProps['loading'] = React.useMemo(
    () => list.loadingStage.isLoading && { indicator: <LoadingOutlined style={{ fontSize: 40 }} /> },
    [list.loadingStage.isLoading],
  );

  return (
    <div className={cn(s.table_wrapper, className)} ref={pageRef}>
      {isInitialLoading && <Loader />}
      {isEmptyList && <CustomEmpty className={s.table_empty} description={emptyListDescription} />}
      {isEmptyFilteredList && <EmptySearchStub className={s.table_empty} onResetClick={filters.resetFilters} />}
      {!isInitialLoading && !isEmptyList && !isEmptyFilteredList && (
        <>
          <Table
            className={cn(s.table, onRow && s.table_clickable)}
            columns={columns}
            dataSource={rows}
            pagination={false}
            onRow={onRow}
            bordered
            sticky
            loading={loader}
            scroll={{ x: '100%' }}
          />
          <Pagination
            className={s.table_pagination}
            defaultCurrent={1}
            current={list.page}
            pageSize={list.limit}
            total={list.total}
            onChange={changePage}
            showSizeChanger={false}
            showLessItems={isMobile}
          />
        </>
      )}
    </div>
  );
};

export default observer(BidTable);
