export const agents = {
  en: {
    title: 'Agents',
    actions: {
      addAgent: 'Add agent',
    },
    colums: {
      name: 'Name',
      telephone: 'Phone number',
      email: 'Email',
      address: 'Address',
      accepted: 'Status',
    },
    modal: {
      title: 'Contact details',
    },
    add: {
      title: 'Adding a agent',
      add: 'Add',
      cancel: 'Cancel',
    },
    edit: {
      title: 'Agent Profile',
      add: 'Save changes',
      cancel: 'Reset changes',
    },
    messages: {
      editSuccess: 'Agent updated successfully',
      editError: 'An error occurred while updating the agent',
      addSuccess: 'Agent added successfully',
      addError: 'An error occurred while adding a agent',
    },
  },
  ru: {
    title: 'Агенты',
    actions: {
      addAgent: 'Добавить агента',
    },
    colums: {
      name: 'Название',
      telephone: 'Номер телефона',
      email: 'Email',
      address: 'Адрес',
      accepted: 'Статус',
    },
    modal: {
      title: 'Контактные данные',
    },
    add: {
      title: 'Добавление агента',
      add: 'Добавить',
      cancel: 'Отмена',
    },
    edit: {
      title: 'Профиль агента',
      add: 'Сохранить изменения',
      cancel: 'Сбросить изменения',
    },
    messages: {
      editSuccess: 'Агент успешно обновлен',
      editError: 'Произошла ошибка при обновлении агента',
      addSuccess: 'Агент успешно добавлен',
      addError: 'Произошла ошибка при добавлении агента',
    },
  },
  zh: {
    title: '代办',
    actions: {
      addAgent: '添加代办',
    },
    colums: {
      name: '名称',
      telephone: '电话号码',
      email: '电子邮箱',
      address: '地址',
      accepted: '状态',
    },
    modal: {
      title: '联系方式',
    },
    add: {
      title: '代办添加中',
      add: '添加',
      cancel: '取消',
    },
    edit: {
      title: '代办',
      add: '保存更改',
      cancel: '重置更改',
    },
    messages: {
      editSuccess: '代办更新成功',
      editError: '更新代办时出错',
      addSuccess: '代办添加成功',
      addError: '添加代办时出错',
    },
  },
};
