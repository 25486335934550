import { ManagerConfirmationStep } from '@/entities/stage/types';
import { Nullable } from '@/shared/types/values';

const STEPS_ORDER_WITHOUT_VERIFYING: Record<ManagerConfirmationStep, number> = {
  [ManagerConfirmationStep.deliveryConfirmation]: 0,
  [ManagerConfirmationStep.confirmationVerifying]: 0,
  [ManagerConfirmationStep.confirmationSigning]: 1,
  [ManagerConfirmationStep.transfer]: 2,
};

const STEPS_ORDER_WITH_VERIFYING: Record<ManagerConfirmationStep, number> = {
  [ManagerConfirmationStep.deliveryConfirmation]: 0,
  [ManagerConfirmationStep.confirmationVerifying]: 1,
  [ManagerConfirmationStep.confirmationSigning]: 2,
  [ManagerConfirmationStep.transfer]: 3,
};

export const mapManagerStepToNumber = (step: Nullable<ManagerConfirmationStep>, isNeedVerifying: boolean): number => {
  if (!step) {
    return -1;
  }

  const stepNumber = isNeedVerifying ? STEPS_ORDER_WITH_VERIFYING[step] : STEPS_ORDER_WITHOUT_VERIFYING[step];

  return stepNumber ?? -1;
};
