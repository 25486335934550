import { isString, stringIsNumber } from '../types/typesGuard';

export const formatNumberWithUnit = (num: number, unit: string, decimals: number = 2) => {
  /**
   * Проверка на случай, если num не является числом
   * (иногда приходит с бэка строка вместо числа)
   */
  if (isString(num)) {
    if (stringIsNumber(num)) {
      num = Number(num);
    } else {
      throw new Error('formatNumberWithUnit: num не является числом');
    }
  }

  const formattedNum = Number(num.toFixed(decimals)).toLocaleString();

  return `${formattedNum}\u00A0${unit}`;
};
