import {
  DocumentFileType,
  UploadOrGenerationDocumentModel,
  UploadOrGenerationDocumentModelParams,
} from '@/entities/file';
import { CustomerSpecificationDocPayload } from '@/entities/stage/types';

import { CustomerSpecificationFieldsModel } from './CustomerSpecificationFieldsModel';

export class CustomerSpecificationModel extends UploadOrGenerationDocumentModel<
  DocumentFileType.customerSpecification,
  CustomerSpecificationDocPayload,
  CustomerSpecificationFieldsModel
> {
  readonly generationFields: CustomerSpecificationFieldsModel;

  constructor(params: UploadOrGenerationDocumentModelParams<DocumentFileType.customerSpecification>) {
    super(params);

    this.generationFields = new CustomerSpecificationFieldsModel(params);
  }
}
