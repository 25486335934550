import { computed, makeObservable } from 'mobx';

import { DocumentCheckStatus, DocumentServer, DocumentWithStatusServer, FileModel } from '@/entities/file';
import { UserType } from '@/entities/user';
import { UploadFileModel } from '@/shared/model/form/UploadFileModel';
import { Nullable } from '@/shared/types/values';

import { BaseFieldsModel, BaseFieldsModelParams } from '../BaseFieldsModel';

export type BaseUploadFileFieldsModelParams<Step, Action> = BaseFieldsModelParams<Step, Action> & {
  fileModel: UploadFileModel;
  status: Nullable<DocumentCheckStatus>;
  error: Nullable<string>;
  uploadedBy: Nullable<UserType.admin | UserType.manager | UserType.supplier>;
};

export type BaseUploadFilePayload<Action> = {
  action: Action;
  files: File[];
};

export class BaseUploadFileFieldsModel<
  Step,
  Action,
  Payload extends BaseUploadFilePayload<Action> = BaseUploadFilePayload<Action>,
> extends BaseFieldsModel<Step, Action, Payload> {
  readonly file: UploadFileModel;
  readonly status: Nullable<DocumentCheckStatus>;
  readonly error: Nullable<string>;
  readonly uploadedBy: Nullable<UserType.admin | UserType.manager | UserType.supplier>;

  constructor({ fileModel, status, error, uploadedBy, ...params }: BaseUploadFileFieldsModelParams<Step, Action>) {
    super(params);

    this.file = fileModel;
    this.status = status;
    this.error = error;
    this.uploadedBy = uploadedBy;

    makeObservable(this, {
      initializedByFiles: computed,
      approveDisabled: computed,
    });
  }

  get isWaiting(): boolean {
    return this.status === DocumentCheckStatus.waiting;
  }

  get isApproved(): boolean {
    return this.status === DocumentCheckStatus.approved;
  }

  get isRejected(): boolean {
    return this.status === DocumentCheckStatus.rejected;
  }

  get initializedByFiles(): boolean {
    return this.file.isInitializedByValue;
  }

  get approveDisabled(): boolean {
    return !this.file.isUploaded || this.file.isError;
  }

  toApproveJson() {
    const files: File[] = [];
    this.file.value.forEach(({ originFileObj }) => {
      originFileObj && files.push(originFileObj);
    });

    return {
      action: this.approveAction,
      files,
    } as Payload;
  }

  static fromJson<Step, Action>({
    data,
    ...params
  }: {
    data: Nullable<DocumentServer | DocumentWithStatusServer>;
  } & BaseFieldsModelParams<Step, Action>): BaseUploadFileFieldsModel<Step, Action> {
    return new BaseUploadFileFieldsModel({
      fileModel: new UploadFileModel({
        initialValue: FileModel.fileListFromJson(data?.document),
        required: true,
      }),
      status: data && 'status' in data ? data.status : null,
      error: data && 'error_message' in data ? data.error_message : null,
      uploadedBy: data?.document?.uploaded_by.role ?? null,
      ...params,
    });
  }
}
