import {
  DocumentFileType,
  UploadOrGenerationDocumentModel,
  UploadOrGenerationDocumentModelParams,
} from '@/entities/file';
import { AdditionalAgreementDocPayload } from '@/entities/stage/types';

import { AdditionalAgreementFiedsModel } from './AdditionalAgreementFiedsModel';

export class AdditionalAgreementModel extends UploadOrGenerationDocumentModel<
  DocumentFileType.customerAdditionalAgreement,
  AdditionalAgreementDocPayload,
  AdditionalAgreementFiedsModel
> {
  readonly generationFields: AdditionalAgreementFiedsModel;

  constructor(params: UploadOrGenerationDocumentModelParams<DocumentFileType.customerAdditionalAgreement>) {
    super(params);

    this.generationFields = new AdditionalAgreementFiedsModel(params);
  }
}
