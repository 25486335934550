import { computed, makeObservable } from 'mobx';

import { DocumentFileType, FileModel } from '@/entities/file';
import { TradeCloseCustomerDocsServer } from '@/entities/stage';
import { UploadFileModel } from '@/shared/model/form/UploadFileModel';
import { Nullable } from '@/shared/types/values';

import { BaseFieldsModel, BaseFieldsModelParams } from '../../../fieldsModel/BaseFieldsModel';

type Params<Step, Action> = BaseFieldsModelParams<Step, Action> & {
  signedCertificate: UploadFileModel;
  additionalAgreement: UploadFileModel;
};

type Payload<Action> = {
  action: Action;
  files: File[];
  doc_types: Array<keyof TradeCloseCustomerDocsServer>;
};

export class TradeCloseCustomerDocsFieldsModel<Step, Action> extends BaseFieldsModel<Step, Action, Payload<Action>> {
  readonly signedCertificate: UploadFileModel;
  readonly additionalAgreement: UploadFileModel;

  constructor({ signedCertificate, additionalAgreement, ...params }: Params<Step, Action>) {
    super(params);

    this.signedCertificate = signedCertificate;
    this.additionalAgreement = additionalAgreement;

    makeObservable(this, {
      approveDisabled: computed,
    });
  }

  get approveDisabled(): boolean {
    return (
      this.additionalAgreement.isError ||
      !this.additionalAgreement.isUploaded ||
      this.signedCertificate.isError ||
      !this.signedCertificate.isUploaded
    );
  }

  toApproveJson() {
    const files = [
      this.signedCertificate.value[0] && this.signedCertificate.value[0].originFileObj,
      this.additionalAgreement.value[0] && this.additionalAgreement.value[0].originFileObj,
    ];

    if (files.some((file) => !file)) {
      return null;
    }

    return {
      action: this.approveAction,
      files,
      doc_types: [
        DocumentFileType.signedCustomerAcceptanceCertificate,
        DocumentFileType.signedCustomerAdditionalAgreement,
      ],
    } as Payload<Action>;
  }

  static fromJson<Step, Action>({
    data,
    ...params
  }: {
    data?: Nullable<TradeCloseCustomerDocsServer>;
  } & BaseFieldsModelParams<Step, Action>): TradeCloseCustomerDocsFieldsModel<Step, Action> {
    return new TradeCloseCustomerDocsFieldsModel({
      signedCertificate: new UploadFileModel({
        initialValue: FileModel.fileListFromJson(data?.[DocumentFileType.signedCustomerAcceptanceCertificate]),
        required: true,
        description: (t) =>
          t('manager.tradeCloseCustomerDocs.descriptions.signedCertificate', { ns: 'finalizationStage' }),
      }),
      additionalAgreement: new UploadFileModel({
        initialValue: FileModel.fileListFromJson(data?.[DocumentFileType.signedCustomerAdditionalAgreement]),
        required: true,
        description: (t) =>
          t('manager.tradeCloseCustomerDocs.descriptions.additionalAgreement', { ns: 'finalizationStage' }),
      }),
      ...params,
    });
  }
}
