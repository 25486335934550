import { autorun, computed, makeObservable, reaction } from 'mobx';

import { IRootStore } from '@/app/store';
import { IDictionariesStore, IDictionary, ProductTypeDictionaryModel } from '@/entities/dictionary';
import { ICreationStage } from '@/entities/stage';
import { CreateTradeFormData, ITradeWorkflowStore } from '@/entities/trade';
import { LoadingStageModel, LocalStore } from '@/shared/model';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { SelectModel } from '@/shared/model/form/SelectModel';
import { UploadFileModel } from '@/shared/model/form/UploadFileModel';
import { stringIsNumber } from '@/shared/types/typesGuard';
import { Nullable } from '@/shared/types/values';
import { numberParser } from '@/shared/utils';
import { formatDate } from '@/shared/utils/formatDate';
import { FieldError, emptyValueValidator, numberValidator, stringLengthValidator } from '@/shared/utils/validators';

type Params = {
  data: ICreationStage;
  rootStore: IRootStore;
  tradeWorkflowStore?: Nullable<ITradeWorkflowStore>;
};

const T_OPTIONS = { ns: 'trade' } as const;

export class CreateTradeModel extends LocalStore {
  readonly supplier: SelectModel<number>;
  readonly customer: SelectModel<number>;
  readonly legalCompanyFrom: SelectModel<number>;
  readonly legalCompanyTo: SelectModel<number>;
  readonly productType: SelectModel<number>;
  readonly product: SelectModel<number>;
  readonly weight: InputModel;
  readonly ligatureWeight: InputModel;
  readonly weightUnit: SelectModel<number>;
  readonly fixingDate: DatePickerModel;
  readonly sellPrice: InputModel;
  readonly sellPriceUnit: SelectModel<number>;
  readonly sellPriceTotal: InputModel;
  readonly buyPrice: InputModel;
  readonly buyPriceUnit: SelectModel<number>;
  readonly buyPriceTotal: InputModel;
  readonly supplierSpec: UploadFileModel;

  readonly rootStore: IRootStore;

  readonly requestStage = new LoadingStageModel();

  readonly tradeWorkflowStore: Nullable<ITradeWorkflowStore> = null;

  protected readonly formFields: (keyof ICreationStage)[] = [
    'supplier',
    'customer',
    'legalCompanyFrom',
    'legalCompanyTo',
    'productType',
    'product',
    'weight',
    'weightUnit',
    'fixingDate',
    'buyPrice',
    'buyPriceUnit',
    'sellPrice',
    'sellPriceUnit',
    'buyPriceTotal',
    'sellPriceTotal',
    'supplierSpec',
    'ligatureWeight',
  ];

  constructor({ data, rootStore, tradeWorkflowStore = null }: Params) {
    super();

    this.rootStore = rootStore;

    this.supplier = new SelectModel<number>({
      initialValue: data.supplier,
      label: (t) => t('createTrade.fields.supplier', T_OPTIONS),
      placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
      required: true,
      validators: [emptyValueValidator()],
      ignoreOnBlurValidation: true,
    });
    this.customer = new SelectModel<number>({
      initialValue: data.customer,
      label: (t) => t('createTrade.fields.customer', T_OPTIONS),
      placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
      required: true,
      validators: [emptyValueValidator()],
      ignoreOnBlurValidation: true,
    });
    this.legalCompanyFrom = new SelectModel<number>({
      initialValue: data.legalCompanyFrom,
      label: (t) => t('createTrade.fields.legalCompanyFrom', T_OPTIONS),
      placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
      required: true,
      validators: [emptyValueValidator()],
      ignoreOnBlurValidation: true,
    });
    this.legalCompanyTo = new SelectModel<number>({
      initialValue: data.legalCompanyTo,
      label: (t) => t('createTrade.fields.legalCompanyTo', T_OPTIONS),
      placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
      validators: [emptyValueValidator()],
      ignoreOnBlurValidation: true,
      required: true,
    });
    this.productType = new SelectModel<number>({
      initialValue: data.productType,
      label: (t) => t('createTrade.fields.productType', T_OPTIONS),
      placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
      validators: [emptyValueValidator()],
      required: true,
    });
    this.product = new SelectModel<number>({
      initialValue: data.product?.id ?? null,
      label: this.productTypeModel?.label ?? '',
      placeholder: (t) => t('placeholders.select', { ns: 'shared' }),
      validators: [emptyValueValidator()],
      required: true,
    });
    this.weight = new InputModel({
      initialValue: data.weight,
      label: (t) => t('createTrade.fields.chemicalWeight', T_OPTIONS),
      placeholder: (t) => t('createTrade.fields.chemicalWeight', T_OPTIONS),
      parser: numberParser({ maxFractionDigits: 3 }),
      validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
      required: true,
    });
    this.ligatureWeight = new InputModel({
      initialValue: data.ligatureWeight,
      label: (t) => t('createTrade.fields.ligatureWeight', T_OPTIONS),
      placeholder: (t) => t('createTrade.fields.ligatureWeight', T_OPTIONS),
      parser: numberParser({ maxFractionDigits: 3 }),
      validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
      required: true,
    });
    this.weightUnit = new SelectModel<number>({
      initialValue: data.weightUnit,
      validators: [emptyValueValidator(FieldError.weightUnit)],
      ignoreOnBlurValidation: true,
      required: true,
    });
    this.fixingDate = new DatePickerModel({
      initialValue: data.fixingDate,
      label: (t) => t('createTrade.fields.fixingDate', T_OPTIONS),
      placeholder: (t) => t('placeholders.date', { ns: 'shared' }),
      validators: [emptyValueValidator()],
      required: true,
    });
    this.sellPrice = new InputModel({
      initialValue: data.sellPrice,
      label: (t) => t('createTrade.fields.sellPrice', T_OPTIONS),
      placeholder: (t) => t('placeholders.price', { ns: 'shared' }),
      parser: numberParser(),
      validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
      ignoreOnBlurValidation: true,
      required: true,
    });
    this.sellPriceUnit = new SelectModel<number>({
      initialValue: data.sellPriceUnit,
      validators: [emptyValueValidator(FieldError.sellPriceUnit)],
      ignoreOnBlurValidation: true,
      required: true,
    });
    this.sellPriceTotal = new InputModel({
      initialValue: data.sellPriceTotal,
      label: (t) => t('createTrade.fields.sellPriceTotal', T_OPTIONS),
      placeholder: (t) => t('placeholders.price', { ns: 'shared' }),
      parser: numberParser(),
      validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
      ignoreOnBlurValidation: true,
      required: true,
    });
    this.buyPrice = new InputModel({
      initialValue: data.buyPrice,
      label: (t) => t('createTrade.fields.buyPrice', T_OPTIONS),
      placeholder: (t) => t('placeholders.price', { ns: 'shared' }),
      parser: numberParser(),
      validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
      ignoreOnBlurValidation: true,
      required: true,
    });
    this.buyPriceUnit = new SelectModel<number>({
      initialValue: data.buyPriceUnit,
      validators: [emptyValueValidator(FieldError.buyPriceUnit)],
      ignoreOnBlurValidation: true,
      required: true,
    });
    this.buyPriceTotal = new InputModel({
      initialValue: data.buyPriceTotal,
      label: (t) => t('createTrade.fields.buyPriceTotal', T_OPTIONS),
      placeholder: (t) => t('placeholders.price', { ns: 'shared' }),
      parser: numberParser(),
      validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
      ignoreOnBlurValidation: true,
      required: true,
    });
    this.supplierSpec = new UploadFileModel({
      initialValue: data.supplierSpec,
      label: (t) => t('createTrade.fields.supplierSpec', T_OPTIONS),
    });

    this.tradeWorkflowStore = tradeWorkflowStore;

    makeObservable(this, {
      isError: computed,
      formDisabled: computed,
      weightUnitOptions: computed,
      productTypeHasProducts: computed,
      productOptions: computed,
    });

    this.addReactions([
      // Если значений для Валют не было при инициализации,
      // то устанавливаем значения, после получения словарей
      autorun(() => {
        if (this.dictionaries.loadingStage.isSuccess) {
          const currencyList = this.dictionaries.currencies.list.items;
          const defaultCurrency = currencyList.find((currency) => currency.label === 'RUB') ?? currencyList[0];

          !this.buyPriceUnit.value && this.buyPriceUnit.change(defaultCurrency.value);
          !this.sellPriceUnit.value && this.sellPriceUnit.change(defaultCurrency.value);
        }
      }),
      // Проверяем ошибку "Валюты покупки" и выставляем ее для "Цены покупки",
      // чтобы отобразить в форме, даже при отсутсвии ошибки в "Цене покупки"
      reaction(
        () => ({ priceValue: this.buyPrice.value, unitError: this.buyPriceUnit.error }),
        ({ unitError }) => (unitError ? this.buyPrice.changeError(unitError) : this.buyPrice.resetError()),
      ),
      // Проверяем ошибку "Валюты продажи" и выставляем ее для "Цены продажи",
      // чтобы отобразить в форме, даже при отсутсвии ошибки в "Цене продажи"
      reaction(
        () => ({ priceValue: this.sellPrice.value, unitError: this.sellPriceUnit.error }),
        ({ unitError }) => (unitError ? this.sellPrice.changeError(unitError) : this.sellPrice.resetError()),
      ),
      // При изменении "Вид товара" сбрасываем "Единицу измерения" и выставляем ее по умолчанию,
      // также сбрасываем product и меняем его label
      reaction(
        () => this.productType.value,
        () => {
          const defaultUnit = this.weightUnitOptions.length ? this.weightUnitOptions[0].value : null;
          this.weightUnit.change(defaultUnit);
          this.product.change(null);
          this.ligatureWeight.change('');
          this.product.changeLabel(this.productTypeModel?.label ?? '');
        },
      ),
    ]);
  }

  private get productTypeModel(): Nullable<ProductTypeDictionaryModel> {
    return this.productType.value !== null
      ? this.dictionaries.productTypes.list.getEntity(this.productType.value)
      : null;
  }

  get dictionaries(): IDictionariesStore {
    return this.rootStore.dictionariesStore;
  }

  get isError(): boolean {
    return this.formFields.some((field) => {
      return this[field].isError;
    });
  }

  get formDisabled(): boolean {
    return this.requestStage.isLoading;
  }

  get productTypeHasProducts(): boolean {
    return this.productTypeModel !== null && this.productTypeModel.productList.length > 0;
  }

  get productOptions(): IDictionary[] {
    return this.productTypeModel?.productList.items ?? [];
  }

  get weightUnitOptions(): IDictionary[] {
    let options: IDictionary[] = [];

    if (this.productType.value) {
      options = this.dictionaries.productTypes.list.getEntity(Number(this.productType.value))?.unitList.items ?? [];
    }

    return options;
  }

  protected readonly scrollToErrorField = () => {
    const errorField = this.formFields.find((field) => this[field].isError);

    if (errorField) {
      this[errorField].scrollToField();
    }
  };

  protected readonly toJson = (): CreateTradeFormData => {
    const files = this.supplierSpec.value;
    const file = files.length > 0 && this.supplierSpec.value[0].originFileObj;

    return {
      ...(file ? { file } : {}),
      ...(this.customer.value ? { customer_id: this.customer.value } : {}),
      ...(this.supplier.value ? { supplier_id: this.supplier.value } : {}),
      ...(this.legalCompanyFrom.value ? { legal_company_from_id: this.legalCompanyFrom.value } : {}),
      ...(this.legalCompanyTo.value ? { legal_company_to_id: this.legalCompanyTo.value } : {}),
      ...(this.productType.value ? { product_type_id: this.productType.value } : {}),
      ...(this.product.value ? { product_id: this.product.value } : {}),
      ...(this.weight.value && stringIsNumber(this.weight.value) ? { weight: Number(this.weight.value) } : {}),
      ...(this.weightUnit.value ? { weight_unit_id: this.weightUnit.value } : {}),
      ...(this.fixingDate.value ? { fixing_date: formatDate(this.fixingDate.value) } : {}),
      ...(this.sellPriceUnit.value ? { sell_price_unit_id: this.sellPriceUnit.value } : {}),
      ...(this.buyPriceUnit.value ? { buy_price_unit_id: this.buyPriceUnit.value } : {}),
      ...(this.sellPrice.value && stringIsNumber(this.sellPrice.value)
        ? { sell_price: Number(this.sellPrice.value) }
        : {}),
      ...(this.buyPrice.value && stringIsNumber(this.buyPrice.value) ? { buy_price: Number(this.buyPrice.value) } : {}),
      ...(this.sellPriceTotal.value && stringIsNumber(this.sellPriceTotal.value)
        ? { sell_price_total: Number(this.sellPriceTotal.value) }
        : {}),
      ...(this.buyPriceTotal.value && stringIsNumber(this.buyPriceTotal.value)
        ? { buy_price_total: Number(this.buyPriceTotal.value) }
        : {}),
      ...(this.ligatureWeight.value && stringIsNumber(this.ligatureWeight.value)
        ? { ligature_weight: Number(this.ligatureWeight.value) }
        : {}),
    };
  };
}
