import { Flex, Table, Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { IngotListModel } from '../../../model/fieldsModel/CustomerSpecificationModel/IngotListModel/IngotListModel';

import s from './IngotsTable.module.scss';

type IngotsTableProps = {
  className?: string;
  model: IngotListModel;
};

const IngotsTable = ({ className, model }: IngotsTableProps): React.ReactElement<IngotsTableProps> => {
  const { t } = useTranslation('stage');

  return (
    <Flex className={cn(s.table_wrapper, className)} vertical justify="space-between" flex={1}>
      {!model.isError ? (
        <Typography.Text className={s.table_error} type="danger">
          {t('customerSpecification.message.emptyIngotFieldsError')}
        </Typography.Text>
      ) : null}
      <Table
        className={s.table}
        columns={model.columns}
        dataSource={model.rows}
        pagination={false}
        bordered
        sticky
        scroll={{ x: '100%' }}
      />
    </Flex>
  );
};

export default observer(IngotsTable);
