import { Alert, AlertProps } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { TranslationNode } from '@/shared/types/localization';

import { TransComp } from '../TransComp';

import s from './CustomAlert.module.scss';

export type CustomAlertProps = Omit<AlertProps, 'banner' | 'message' | 'description'> & {
  message?: TranslationNode;
  description?: TranslationNode;
  scrollPosition?: ScrollLogicalPosition;
};

const CustomAlert: React.FC<CustomAlertProps> = ({ className, message, description, scrollPosition, ...props }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (scrollPosition) {
      setTimeout(() => ref.current && ref.current.scrollIntoView({ behavior: 'smooth', block: scrollPosition }), 0);
    }
  }, [scrollPosition, ref.current]);

  return (
    <div ref={ref}>
      <Alert
        {...props}
        className={cn(s.alert, !message && s.alert_noMessage, !description && s.alert_noDescription, className)}
        message={<TransComp>{message}</TransComp>}
        description={<TransComp>{description}</TransComp>}
        banner
      />
    </div>
  );
};

export default React.memo(CustomAlert);
