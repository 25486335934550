import { useAsyncLocalStore } from '@kts-front/hooks';
import { MetaState } from '@kts-front/types';
import { ConfigProvider, message } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { localeConfig } from '@/shared/config/localeConfig';
import { themeConfig } from '@/shared/config/themeConfig';

import { RootStore } from './RootStore';

const RootStoreContext = React.createContext<RootStore | null>(null);

export const RootStoreContextProvider: React.FC<React.PropsWithChildren> = observer(({ children }) => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage({
    maxCount: 5,
  });

  const storeCtx = useAsyncLocalStore(() => RootStore.asyncStoreInit({ messageApi, navigate }));

  React.useEffect(() => {
    if (storeCtx.stage === MetaState.success) {
      storeCtx.ctx.store.userStore.getCurrentUser();
    }
  }, [storeCtx.stage]);

  if (storeCtx.stage !== MetaState.success) {
    return null;
  }

  const { store } = storeCtx.ctx;

  return (
    <RootStoreContext.Provider value={store}>
      <ConfigProvider theme={themeConfig} locale={localeConfig[store.localizationStore.lng]}>
        {children}
        {contextHolder}
      </ConfigProvider>
    </RootStoreContext.Provider>
  );
});

export const useRootStore = () => {
  const context = React.useContext(RootStoreContext);

  if (!context) {
    throw new Error('RootStoreContext was not provided');
  }

  return context;
};
