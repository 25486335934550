import { BaseResponse } from '@kts-front/types';

import { ListModel } from '@/shared/model';

import { IDictionariesStore, IDictionaryStore } from '../../types';
import { DictionaryModel } from '../DictionaryModel';

export class ClientsStore implements IDictionaryStore<DictionaryModel> {
  readonly list = new ListModel<DictionaryModel, number>();

  private _dictionariesStore: IDictionariesStore;

  constructor(dictionariesStore: IDictionariesStore) {
    this._dictionariesStore = dictionariesStore;
  }

  async load(): Promise<BaseResponse> {
    const { loadClients } = this._dictionariesStore;

    const response = await loadClients();

    return response;
  }
}
