import * as React from 'react';

import { StepDescription } from '@/shared/ui';

import { StageModel, StepComponentProps } from '../../types';

type WaitingStepProps<Model> = StepComponentProps<Model>;

const WaitingStep = <Model extends StageModel>({ description }: WaitingStepProps<Model>) => {
  return <StepDescription description={description} />;
};

export default WaitingStep;
