import { BaseResponse } from '@kts-front/types';
import { MessageInstance } from 'antd/es/message/interface';
import { NavigateFunction } from 'react-router-dom';

import { DictionariesStore, IDictionariesStore } from '@/entities/dictionary';
import { INotificationsStore, NotificationsStore } from '@/entities/message';
import { IUserStore, UserStore } from '@/entities/user';
import { LocalStore } from '@/shared/model';

import { CentrifugeStore, ICentrifugeStore } from '../CentrifugeStore';
import { ILocalizationStore, LocalizationStore } from '../LocalizationStore';
import { IRouterStore, RouterStore } from '../RouterStore';

import { IRootStore } from './types';

type InitParams = {
  messageApi: MessageInstance;
  navigate: NavigateFunction;
};

type RootStoreParams = {
  localizationStore: ILocalizationStore;
  notificationsStore: INotificationsStore;
  routerStore: IRouterStore;
};

export class RootStore extends LocalStore implements IRootStore {
  readonly centrifugeStore: ICentrifugeStore = new CentrifugeStore();
  readonly dictionariesStore: IDictionariesStore = new DictionariesStore();
  readonly localizationStore: ILocalizationStore;
  readonly notificationsStore: INotificationsStore;
  readonly routerStore: IRouterStore;
  readonly userStore: IUserStore = new UserStore();

  constructor({ localizationStore, notificationsStore, routerStore }: RootStoreParams) {
    super();

    this.localizationStore = localizationStore;
    this.notificationsStore = notificationsStore;
    this.routerStore = routerStore;
  }

  destroy(): void {
    super.destroy();
    this.centrifugeStore.destroy();
  }

  static async asyncStoreInit({ messageApi, navigate }: InitParams): Promise<BaseResponse<RootStore>> {
    const localizationStore = await LocalizationStore.init();
    const notificationsStore = new NotificationsStore({ messageApi });
    const routerStore = new RouterStore({ navigate });

    return {
      isError: false,
      data: new RootStore({ localizationStore, notificationsStore, routerStore }),
    };
  }
}
