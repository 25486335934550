import { Flex } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ArbitraryDocsForm, UploadArbitraryDocsModel } from '@/entities/file';
import { useTypedTranslation } from '@/shared/hooks';
import { CustomModal } from '@/shared/ui';
import { CustomFormItem } from '@/shared/ui/form/CustomFormItem';
import { CustomUpload } from '@/shared/ui/form/CustomUpload';
import { UploadButton } from '@/shared/ui/form/buttons';

import s from './UploadArbitraryDocs.module.scss';

type UploadArbitraryDocsProps = {
  model: UploadArbitraryDocsModel;
};

const UploadArbitraryDocs: React.FC<UploadArbitraryDocsProps> = ({ model }) => {
  const { disabled, okButtonProps, originDocs, uploadError, loadingStage, closeModal, modalState } = model;

  const { t } = useTypedTranslation();

  return (
    <>
      <CustomModal
        title={t('optionalFields.arbitraryDocs.uploadModalTitle', { ns: 'stage' })}
        width={720}
        open={modalState.isOpen}
        onCancel={closeModal}
        cancelButtonProps={{ disabled: loadingStage.isLoading }}
        okText={t('buttons.upload', { ns: 'shared' })}
        okButtonProps={okButtonProps}
      >
        <ArbitraryDocsForm model={model} disabled={disabled || loadingStage.isLoading} error={uploadError} />
      </CustomModal>
      <CustomFormItem
        label={t('optionalFields.arbitraryDocs.label', { ns: 'stage' })}
        tooltip={t('optionalFields.arbitraryDocs.tooltip', { ns: 'stage' })}
      >
        <Flex vertical gap={8}>
          {Boolean(originDocs.length) && <CustomUpload className={s.upload} fileList={originDocs} disabled />}
          <UploadButton className={cn(s.upload_button)} onClick={modalState.open} disabled={disabled} />
        </Flex>
      </CustomFormItem>
    </>
  );
};

export default observer(UploadArbitraryDocs);
