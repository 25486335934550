import { IRootStore } from '@/app/store';

import { ReplyBidServer } from '../types';

import { BidModel } from './BidModel';

export class ReplyBidModel extends BidModel {
  static fromJson(server: ReplyBidServer, rootStore: IRootStore): ReplyBidModel {
    return new ReplyBidModel({ ...BidModel.mapServerToClient({ server, rootStore }) });
  }
}
