import { ManagerTransportationStep, ShipmentType } from '@/entities/stage/types';
import { Nullable } from '@/shared/types/values';

const STEPS_ORDER_TRANSIT: Record<ManagerTransportationStep, number> = {
  [ManagerTransportationStep.transitCountryCargoArrival]: 0,
  [ManagerTransportationStep.cargoArrival]: 0,
  [ManagerTransportationStep.tradeCloseSupplierDocs]: 1,
  [ManagerTransportationStep.destinationCountryDocs]: 2,
  [ManagerTransportationStep.destinationCountryAwb]: 3,
  [ManagerTransportationStep.transitCountryCargoShipment]: 4,
  [ManagerTransportationStep.destinationCountryCargoArrival]: 5,
  [ManagerTransportationStep.transfer]: 6,
};

const STEPS_ORDER_DIRECT: Record<ManagerTransportationStep, number> = {
  [ManagerTransportationStep.transitCountryCargoArrival]: 0,
  [ManagerTransportationStep.cargoArrival]: 0,
  [ManagerTransportationStep.tradeCloseSupplierDocs]: 1,
  [ManagerTransportationStep.destinationCountryDocs]: 1,
  [ManagerTransportationStep.destinationCountryAwb]: 1,
  [ManagerTransportationStep.transitCountryCargoShipment]: 1,
  [ManagerTransportationStep.destinationCountryCargoArrival]: 1,
  [ManagerTransportationStep.transfer]: 2,
};

export const mapManagerStepToNumber = (
  step: Nullable<ManagerTransportationStep>,
  shipmentType: Nullable<ShipmentType>,
): number => {
  if (!step || !shipmentType) {
    return -1;
  }

  const stepNumber = shipmentType === ShipmentType.transit ? STEPS_ORDER_TRANSIT[step] : STEPS_ORDER_DIRECT[step];

  return stepNumber ?? -1;
};
