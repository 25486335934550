import { Flex } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ValidatorResult } from '@/shared/types/validator';
import { CustomAlert, TransComp } from '@/shared/ui';
import { UploadDragger } from '@/shared/ui/form/UploadDragger';

import { ArbitraryDocsFormModel } from '../../model';
import { ArbitraryDocsEditFormModel } from '../../model/ArbitraryDocsEditFormModel';

import ArbitraryDoc from './ArbitraryDoc';

import s from './ArbitraryDocsForm.module.scss';

type ArbitraryDocsFormProps = {
  className?: string;
  model: ArbitraryDocsFormModel | ArbitraryDocsEditFormModel;
  disabled?: boolean;
  error?: ValidatorResult;
};

const ArbitraryDocsForm: React.FC<ArbitraryDocsFormProps> = ({ className, model, disabled, error }) => {
  const { arbitraryDocs, onChange, onRemove, arbitraryDocsOptions } = model;
  const isEdit = React.useMemo(() => model instanceof ArbitraryDocsEditFormModel, [model]);

  return (
    <Flex className={cn(s.upload, className)} vertical gap={24}>
      {arbitraryDocs.props.openFileDialogOnClick && (
        <UploadDragger
          className={s.upload_list_hidden}
          multiple
          {...arbitraryDocs.props}
          onChange={onChange}
          disabled={disabled}
        />
      )}
      {error && <CustomAlert type="error" message={<TransComp>{error}</TransComp>} />}
      {arbitraryDocs.value.map((file) => {
        const options = arbitraryDocsOptions.getEntity(file.uid);

        if (!options) return null;

        return (
          <ArbitraryDoc
            key={file.uid}
            file={file}
            onRemove={onRemove}
            options={options}
            disabled={disabled}
            isEdit={isEdit}
          />
        );
      })}
    </Flex>
  );
};

export default observer(ArbitraryDocsForm);
