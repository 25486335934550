import { isString, stringIsNumber } from '../types/typesGuard';

export const LIGATURE_WEIGHT_COEFFICIENT = 0.9999;

export const getLigatureWeight = (weight: number | string): number => {
  if (isString(weight) && !stringIsNumber(weight)) {
    return 0;
  }

  const weightAsNumber = Number(weight);

  return Number((weightAsNumber / LIGATURE_WEIGHT_COEFFICIENT).toFixed(3));
};
