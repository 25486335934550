import { computed, makeObservable } from 'mobx';

import { ITradeWorkflowStore } from '@/entities/trade';
import { LocalStore } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import { BaseStageDataServer, ShipmentType, StageType } from '../../types';
import { OptionalFieldsModel } from '../fieldsModel/OptionalFieldsModel';

export type BaseStageModelParams<Step> = {
  currentType: StageType;
  step: Nullable<Step>;
  shipmentType: Nullable<ShipmentType>;
  optionalFields: OptionalFieldsModel;
};

type Params<TStage extends StageType, Step> = BaseStageModelParams<Step> & {
  type: TStage;
};

export abstract class BaseStageModel<TStage extends StageType, Step> extends LocalStore {
  /** Полученный этап сделки */
  readonly type: TStage;
  /** Текущий этап сделки */
  readonly currentType: StageType;
  /** Текущий шаг этапа */
  readonly step: Nullable<Step>;
  /** Тип перевозки */
  readonly shipmentType: Nullable<ShipmentType>;

  readonly optionalFields: OptionalFieldsModel;

  constructor({ type, currentType, step, shipmentType, optionalFields }: Params<TStage, Step>) {
    super();

    this.type = type;
    this.currentType = currentType;
    this.step = type === currentType ? step : null;
    this.shipmentType = shipmentType;
    this.optionalFields = optionalFields;

    makeObservable(this, {
      currentStep: computed,
      steps: computed,
      isShipmentTypeDirect: computed,
      isShipmentTypeTransit: computed,
    });
  }

  get isShipmentTypeTransit(): boolean {
    return this.shipmentType === ShipmentType.transit;
  }

  get isShipmentTypeDirect(): boolean {
    return this.shipmentType === ShipmentType.direct;
  }

  abstract get currentStep(): number;

  abstract get steps(): Step[];

  static paramsFromJson<Step, Steps>({
    dataServer,
    tradeWorkflowStore,
  }: {
    dataServer: BaseStageDataServer<Step, Steps>;
    tradeWorkflowStore: ITradeWorkflowStore;
  }): BaseStageModelParams<Step> {
    return {
      optionalFields: OptionalFieldsModel.fromJson({ tradeWorkflowStore }),
      currentType: dataServer.current_stage,
      step: dataServer.current_step,
      shipmentType: dataServer.shipment_type,
    };
  }

  destroy(): void {
    super.destroy();
  }
}
