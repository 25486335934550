import { Steps, StepsProps } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { ProgressIcon } from '../icons';

import s from './CustomVerticalSteps.module.scss';

type CustomStepsProps = Omit<StepsProps, 'direction' | 'progressDot'>;

type ProgressDotRender = NonNullable<Exclude<StepsProps['progressDot'], boolean>>;
const progressDotRender: ProgressDotRender = (dot, { status }) => (status === 'finish' ? <ProgressIcon /> : dot);

const CustomVerticalSteps = React.forwardRef<HTMLDivElement, CustomStepsProps>(({ className, ...props }, ref) => {
  return (
    <div className={cn(s.verticalSteps, className)} ref={ref}>
      <Steps direction="vertical" progressDot={progressDotRender} {...props} />
    </div>
  );
});

CustomVerticalSteps.displayName = 'CustomVerticalSteps';

export default CustomVerticalSteps;
