import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { UploadOrGenerationDocument } from '@/entities/file';

import { InvoiceDocTypes, InvoiceModel } from '../../model/fieldsModel/InvoiceModel';

import InvoiceForm from './InvoiceForm';

type InvoiceProps<DocType extends InvoiceDocTypes> = {
  model: InvoiceModel<DocType>;
  disabled?: boolean;
};

const Invoice = <DocType extends InvoiceDocTypes>({ model, disabled }: InvoiceProps<DocType>) => {
  return <UploadOrGenerationDocument model={model} disabled={disabled} FieldsComponent={InvoiceForm} />;
};

export default observer(Invoice);
