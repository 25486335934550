import { FilterOutlined } from '@ant-design/icons';
import { Button, Dropdown, DropdownProps } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { TradeStageFilterModel } from '../../model';

import { getMenu } from './config';

import s from './TradeStageFilter.module.scss';

type TradeStageFilterProps = {
  className?: string;
  placement?: DropdownProps['placement'];
  model: TradeStageFilterModel;
};

const TradeStageFilter: React.FC<TradeStageFilterProps> = ({ className, model, ...props }) => {
  const { dropdownState, openChange, isFiltered } = model;

  return (
    <Dropdown
      className={cn(s.filter, className)}
      rootClassName={s.filter_root}
      menu={getMenu(model)}
      open={dropdownState.isOpen}
      onOpenChange={openChange}
      trigger={['click']}
      {...props}
    >
      <Button className={s.filter_trigger} icon={<FilterOutlined className={cn(isFiltered && s.filter_filtered)} />} />
    </Dropdown>
  );
};

export default observer(TradeStageFilter);
