import { OptionFieldsAction, StageType } from './baseStage';
import { ConfirmationAction, ConfirmationStageDataServer } from './confirmationStage';
import { CreationAction, CreationStageDataServer } from './creationStage';
import { FinalizationAction, FinalizationStageDataServer } from './finalizationStage';
import { PaymentAction, PaymentStageDataServer } from './paymentStage';
import { ShipmentAction, ShipmentStageDataServer } from './shipmentStage';
import { TransportationAction, TransportationStageDataServer } from './transportationStage';

type MapStageTypeToDataServer = {
  [StageType.creation]: CreationStageDataServer;
  [StageType.confirmation]: ConfirmationStageDataServer;
  [StageType.payment]: PaymentStageDataServer;
  [StageType.shipment]: ShipmentStageDataServer;
  [StageType.transportation]: TransportationStageDataServer;
  [StageType.finalization]: FinalizationStageDataServer;
  [StageType.finished]: null;
};

type BaseStageServer<T extends StageType> = {
  key: T;
  data: MapStageTypeToDataServer[T];
};

export type CreationStageServer = BaseStageServer<StageType.creation>;

export type ConfirmationStageServer = BaseStageServer<StageType.confirmation>;

export type PaymentStageServer = BaseStageServer<StageType.payment>;

export type ShipmentStageServer = BaseStageServer<StageType.shipment>;

export type TransportationStageServer = BaseStageServer<StageType.transportation>;

export type FinalizationStageServer = BaseStageServer<StageType.finalization>;

export type StageServer =
  | CreationStageServer
  | ConfirmationStageServer
  | PaymentStageServer
  | ShipmentStageServer
  | TransportationStageServer
  | FinalizationStageServer;

export type StageAction =
  | CreationAction
  | ConfirmationAction
  | PaymentAction
  | ShipmentAction
  | TransportationAction
  | FinalizationAction
  | OptionFieldsAction;

export enum ActionStatusPrefix {
  approve = 'approve',
  reject = 'reject',
}
