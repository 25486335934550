import { QuestionCircleOutlined } from '@ant-design/icons';
import { Flex, Tooltip } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { Nullable } from '@/shared/types/values';
import { TransComp, TypographyTextWithTooltip } from '@/shared/ui';

import { WeightWithUnit } from '../../types';

import s from './BidWeightTooltip.module.scss';

type Props = {
  className?: string;
  current: WeightWithUnit;
  previous: Nullable<string>;
};

const BidWeightTooltip: React.FC<Props> = ({ className, current, previous }) => {
  const tooltipNode = previous && (
    <TransComp>
      {(t) => t(`tooltip.tooltipWeight`, { prevWeight: previous, weight: current.gram, ns: 'bids' })}
    </TransComp>
  );

  return (
    <div>
      <Flex gap={4}>
        <TypographyTextWithTooltip text={current.gram} />
        {tooltipNode && (
          <Tooltip title={tooltipNode}>
            <QuestionCircleOutlined className={cn(s.icon, className)} />
          </Tooltip>
        )}
      </Flex>
      <TypographyTextWithTooltip text={current.ounce} />
    </div>
  );
};

export default React.memo(BidWeightTooltip);
