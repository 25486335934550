import { IRootStore } from '@/app/store';

import { BuyBidServer } from '../types';

import { BidModel } from './BidModel';

export class BuyBidModel extends BidModel {
  static fromJson(server: BuyBidServer, rootStore: IRootStore): BuyBidModel {
    return new BuyBidModel({ ...BidModel.mapServerToClient({ server, rootStore }) });
  }
}
