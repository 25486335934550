import { PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '@/app/store';
import { CustomTypographyText } from '@/shared/ui';
import { FormRadioGroup } from '@/shared/ui/form/FormRadioGroup';
import { FormSelect } from '@/shared/ui/form/FormSelect';

import { CustomerSpecificationFieldsModel } from '../../model/fieldsModel/CustomerSpecificationModel';

import IngotsTable from './IngotsTable/IngotsTable';

import s from './CustomerSpecification.module.scss';

type CustomerSpecificationFormProps = {
  model: CustomerSpecificationFieldsModel;
};

const CustomerSpecificationForm: React.FC<CustomerSpecificationFormProps> = ({ model }) => {
  const { signatureId, yearOrUin, loadingState, ingots } = model;
  const { t } = useTranslation('stage');

  const { dictionariesStore } = useRootStore();

  return (
    <Flex vertical gap={12}>
      <FormSelect
        model={signatureId}
        options={dictionariesStore.signatories.list.items}
        disabled={loadingState.isLoading}
      />
      <FormRadioGroup model={yearOrUin} />
      <Flex justify="space-between" align="middle">
        <Typography.Title level={5}>{t('customerSpecification.informationAboutIngots')}</Typography.Title>
        <CustomTypographyText type="secondary">
          {t('customerSpecification.countAddIngots', { count: ingots.countIngots })}
        </CustomTypographyText>
      </Flex>
      <Flex>
        <IngotsTable className={s.table} model={ingots} />
      </Flex>
      <Flex justify="end">
        <Button type="dashed" disabled={ingots.countIngots === 0} icon={<PlusOutlined />} onClick={ingots.addIngot}>
          {t('customerSpecification.action.add')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default observer(CustomerSpecificationForm);
