import { ICreationStage } from '@/entities/stage';

export const requiredFields: (keyof ICreationStage)[] = ['productType', 'weight', 'weightUnit', 'fixingDate'];

export const supplierFields: (keyof ICreationStage)[] = [
  'supplier',
  'legalCompanyFrom',
  'buyPrice',
  'buyPriceUnit',
  'buyPriceTotal',
];

export const customerFields: (keyof ICreationStage)[] = [
  'customer',
  'legalCompanyTo',
  'sellPrice',
  'sellPriceUnit',
  'sellPriceTotal',
];
