import {
  AwbTransitAction,
  ManagerShipmentStep,
  StageModelJsonParams,
  StageType,
  SupplierShipmentAction,
  SupplierShipmentStageDataServer,
  SupplierShipmentStep,
} from '@/entities/stage/types';
import { UserType } from '@/entities/user';
import { mapStepToNumber } from '@/shared/utils';

import { BaseManagerStageModel } from '../../BaseManagerStageModel';
import { BaseStageModel, BaseStageModelParams } from '../../BaseStageModel';
import { BaseUploadFileFieldsModel } from '../../fieldsModel/BaseUploadFileFieldsModel';
import { UploadAwbOrFlightNumberModel } from '../../fieldsModel/UploadAwbOrFlightNumberModel';
import { WaitingFieldsModel } from '../../fieldsModel/WaitingFieldsModel';

import { stepsOrder } from './config';

type Params = BaseStageModelParams<SupplierShipmentStep> & {
  uploadSpecFields: BaseUploadFileFieldsModel<SupplierShipmentStep, SupplierShipmentAction>;
  specVerifyingFields: WaitingFieldsModel<SupplierShipmentStep>;
  transitCountry: WaitingFieldsModel<SupplierShipmentStep>;
  transitCountryAwb: UploadAwbOrFlightNumberModel<
    SupplierShipmentStep,
    AwbTransitAction.uploadFile,
    AwbTransitAction.flightNumber
  >;
  transitCountryAwbVerifying: WaitingFieldsModel<SupplierShipmentStep>;
  isCargoShipped: boolean;
};

export class SupplierShipmentStageModel extends BaseStageModel<StageType.shipment, SupplierShipmentStep> {
  readonly uploadSpecFields: BaseUploadFileFieldsModel<SupplierShipmentStep, SupplierShipmentAction>;
  readonly specVerifyingFields: WaitingFieldsModel<SupplierShipmentStep>;
  // StageType.transit fields
  readonly transitCountry: WaitingFieldsModel<SupplierShipmentStep>;
  readonly transitCountryAwb: UploadAwbOrFlightNumberModel<
    SupplierShipmentStep,
    AwbTransitAction.uploadFile,
    AwbTransitAction.flightNumber
  >;
  readonly transitCountryAwbVerifying: WaitingFieldsModel<SupplierShipmentStep>;

  readonly isCargoShipped: boolean;

  constructor({
    uploadSpecFields,
    specVerifyingFields,
    transitCountry,
    transitCountryAwb,
    transitCountryAwbVerifying,
    isCargoShipped,
    ...params
  }: Params) {
    super({
      type: StageType.shipment,
      ...params,
    });

    this.uploadSpecFields = uploadSpecFields;
    this.specVerifyingFields = specVerifyingFields;
    this.transitCountry = transitCountry;
    this.transitCountryAwb = transitCountryAwb;
    this.transitCountryAwbVerifying = transitCountryAwbVerifying;
    this.isCargoShipped = isCargoShipped;
  }

  private get isNeedVerifyingSpec(): boolean {
    return this.uploadSpecFields.uploadedBy === UserType.supplier;
  }

  private get isNeedVerifyingAwb(): boolean {
    return this.transitCountryAwb.uploadedBy === UserType.supplier;
  }

  get isApprovedSpec(): boolean {
    return this.uploadSpecFields.isApproved;
  }

  get isApprovedAwb(): boolean {
    return this.transitCountryAwb.isApproved;
  }

  get currentStep(): number {
    return mapStepToNumber({ step: this.step, steps: this.steps, stepsOrder });
  }

  get steps(): SupplierShipmentStep[] {
    const steps: SupplierShipmentStep[] = [SupplierShipmentStep.uploadSpec]; // Первый шаг, есть всегда

    // Если нужна верификация спецификации, то добавляем шаг верификации
    if (this.isNeedVerifyingSpec) {
      steps.push(SupplierShipmentStep.specVerifying);
    }

    // Если тип отгрузки = transit, то добавляем шаги для transit
    if (this.isShipmentTypeTransit) {
      steps.push(SupplierShipmentStep.transitCountry, SupplierShipmentStep.transitCountryAwb);

      // Если нужна верификация Awb, то добавляем шаг верификации Awb
      if (this.isNeedVerifyingAwb) {
        steps.push(SupplierShipmentStep.transitCountryAwbVerifying);
      }
    }

    steps.push(SupplierShipmentStep.cargoShipment, SupplierShipmentStep.transfer);

    return steps;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<SupplierShipmentStageDataServer>): SupplierShipmentStageModel {
    const { current_step, steps, supplier_specification } = dataServer;

    const specDoc =
      steps[ManagerShipmentStep.uploadSpec] ??
      (supplier_specification ? { document: supplier_specification ?? null } : null);

    return new SupplierShipmentStageModel({
      uploadSpecFields: BaseUploadFileFieldsModel.fromJson<SupplierShipmentStep, SupplierShipmentAction>({
        data: specDoc,
        step: SupplierShipmentStep.uploadSpec,
        approveAction: SupplierShipmentStep.uploadSpec,
        tradeWorkflowStore,
      }),
      specVerifyingFields: WaitingFieldsModel.fromJson({
        step: SupplierShipmentStep.specVerifying,
        tradeWorkflowStore,
      }),
      transitCountry: WaitingFieldsModel.fromJson({
        step: SupplierShipmentStep.transitCountry,
        tradeWorkflowStore,
      }),
      transitCountryAwb: UploadAwbOrFlightNumberModel.fromServer({
        data: steps[SupplierShipmentStep.transitCountryAwb],
        step: SupplierShipmentStep.transitCountryAwb,
        approveFileAction: AwbTransitAction.uploadFile,
        approveNumberAction: AwbTransitAction.flightNumber,
        tradeWorkflowStore,
      }),
      transitCountryAwbVerifying: WaitingFieldsModel.fromJson({
        step: SupplierShipmentStep.transitCountryAwbVerifying,
        tradeWorkflowStore,
      }),
      isCargoShipped:
        dataServer.steps[SupplierShipmentStep.cargoShipment]?.shipped || current_step === SupplierShipmentStep.transfer,

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
      step: current_step,
    });
  }
}
