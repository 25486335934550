import { Tag } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { UserType } from '@/entities/user';
import { TransComp } from '@/shared/ui';

import { userTypeTagMap } from './config';

import s from './UserTypeTag.module.scss';

type UserTypeTagProps = {
  type: UserType;
  className?: string;
};

const UserTypeTag = ({ type, className }: UserTypeTagProps): React.ReactElement<UserTypeTagProps> => {
  const { color, label } = userTypeTagMap[type];

  return (
    <Tag className={cn(s.tag, className)} color={color}>
      <TransComp>{label}</TransComp>
    </Tag>
  );
};

export default UserTypeTag;
