import { TranslationString } from '@/shared/types/localization';
import { Options } from '@/shared/types/values';

import { TaxType } from './types';

const mapTaxTypeToLabel =
  (type: TaxType): TranslationString =>
  (t) =>
    t(`taxName.${type}`, { ns: 'company' });

export const taxTypeOptions: Options<TaxType> = [
  {
    label: mapTaxTypeToLabel(TaxType.BR),
    value: TaxType.BR,
  },
  {
    label: mapTaxTypeToLabel(TaxType.CR),
    value: TaxType.CR,
  },
  {
    label: mapTaxTypeToLabel(TaxType.TIN),
    value: TaxType.TIN,
  },
  {
    label: mapTaxTypeToLabel(TaxType.TRN),
    value: TaxType.TRN,
  },
  {
    label: mapTaxTypeToLabel(TaxType.certificateNumber),
    value: TaxType.certificateNumber,
  },
  {
    label: mapTaxTypeToLabel(TaxType.licenceNumber),
    value: TaxType.licenceNumber,
  },
];
