import { isString } from 'antd/es/button';
import { action, computed, makeObservable } from 'mobx';
import { nanoid } from 'nanoid';

import { IngotModel } from '@/entities/file/model/IngotModel';
import { IngotIdType } from '@/entities/stage/types';
import { ListModel } from '@/shared/model';
import { RadioListModel } from '@/shared/model/form/RadioListModel';
import { ColumnsData } from '@/shared/ui/Table';

import { getColumns } from './config';
import { IIngotsTableData } from './types';

type IngotListModelParams = {
  yearOrUin: RadioListModel<IngotIdType>;
};

export class IngotListModel {
  readonly list = new ListModel<IngotModel, string>();
  readonly yearOrUin: RadioListModel<IngotIdType>;

  constructor({ yearOrUin }: IngotListModelParams) {
    this.yearOrUin = yearOrUin;
    this.addIngot();

    makeObservable(this, {
      columns: computed,
      rows: computed,
      isError: computed,
      isEmptyValue: computed,
      countIngots: computed,

      addIngot: action.bound,
      deleteIngot: action.bound,
    });
  }

  get isError(): boolean {
    return this.list.items.every((row) => !row.getFields(this.yearOrUin.value).some((field) => field.isError));
  }

  get isEmptyValue(): boolean {
    return this.list.items.every(
      (row) =>
        !row
          .getFields(this.yearOrUin.value)
          .some((field) => (isString(field.value) ? !field.value.trim() : !field.value)),
    );
  }

  get countIngots(): number {
    return this.list.items.reduce((acc, row) => {
      if (
        row
          .getFields(this.yearOrUin.value)
          .some((field) => (isString(field.value) ? !field.value.trim() : !field.value) || field.isError)
      )
        return acc;

      return acc + 1;
    }, 0);
  }

  get columns(): ColumnsData<IIngotsTableData> {
    return getColumns(this);
  }

  get rows(): IIngotsTableData[] {
    return this.list.items.map((_, index) => {
      const key = this.list.keys[index];

      return {
        key: key,
        ingotNumber: key,
        weight: key,
        ligatureWeight: key,
        metalPurity: key,
        year: key,
        uin: key,
      };
    });
  }

  addIngot() {
    const key = nanoid();

    this.list.addEntity({ entity: new IngotModel(), key });
  }

  deleteIngot(key: string): void {
    if (this.list.length === 1) return;

    this.list.removeEntity(key);
  }

  destroy(): void {}
}
