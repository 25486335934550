import {
  CustomerShipmentStageDataServer,
  CustomerShipmentStep,
  StageModelJsonParams,
  StageType,
} from '@/entities/stage/types';

import { BaseManagerStageModel } from '../../BaseManagerStageModel';
import { BaseStageModel, BaseStageModelParams } from '../../BaseStageModel';
import { WaitingFieldsWithFilesModel } from '../../fieldsModel/WaitingFieldsWithFilesModel';

import { DestinationCountryDocs, destinationCountryDocsOptions, mapStepToNumber } from './config';

type Params = BaseStageModelParams<CustomerShipmentStep> & {
  destinationCountryDocsFields: WaitingFieldsWithFilesModel<CustomerShipmentStep, DestinationCountryDocs>;
};

export class CustomerShipmentStageModel extends BaseStageModel<StageType.shipment, CustomerShipmentStep> {
  readonly destinationCountryDocsFields: WaitingFieldsWithFilesModel<CustomerShipmentStep, DestinationCountryDocs>;

  constructor({ destinationCountryDocsFields, ...params }: Params) {
    super({
      type: StageType.shipment,
      ...params,
    });

    this.destinationCountryDocsFields = destinationCountryDocsFields;
  }

  get isDestinationCountryDocsUploaded(): boolean {
    return Boolean(this.destinationCountryDocsFields.files?.every(({ file }) => file.isUploaded));
  }

  get currentStep(): number {
    return mapStepToNumber(this.step, this.isShipmentTypeDirect);
  }

  get steps(): CustomerShipmentStep[] {
    const steps: CustomerShipmentStep[] = [CustomerShipmentStep.shipmentType];

    if (this.isShipmentTypeDirect) {
      steps.push(CustomerShipmentStep.destinationCountryDocs);
    }

    steps.push(CustomerShipmentStep.transfer);

    return steps;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<CustomerShipmentStageDataServer>): CustomerShipmentStageModel {
    const { current_step, steps } = dataServer;

    return new CustomerShipmentStageModel({
      destinationCountryDocsFields: WaitingFieldsWithFilesModel.fromJson({
        data: steps[CustomerShipmentStep.destinationCountryDocs],
        options: destinationCountryDocsOptions,
        step: CustomerShipmentStep.destinationCountryDocs,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
      step: current_step,
    });
  }
}
