import { EditOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { FormDatePicker } from '@/shared/ui/form/FormDatePicker';
import { ApproveButton } from '@/shared/ui/form/buttons';

import { ExpectedDeliveryTimeModel } from '../../model/ExpectedDeliveryTimeModel';

import s from './ExpectedDeliveryTime.module.scss';

type Props = {
  className?: string;
  model: ExpectedDeliveryTimeModel;
};

const ExpectedDeliveryTime: React.FC<Props> = ({ className, model }) => {
  const { disabled, isError, isInitializedByValue, approveDisabled, loadingStage, changeInitializedByValue, submit } =
    model;

  const handleChangeInitialized = React.useCallback(() => {
    changeInitializedByValue(false);
  }, [changeInitializedByValue]);

  return (
    <Flex className={cn(className)} vertical gap={8}>
      <FormDatePicker
        className={cn(s['date-picker'], !disabled && isInitializedByValue && s['date-picker_disabled'])}
        pickerRootClassName={s['date-picker_root']}
        model={model}
        disabled={loadingStage.isLoading}
        suffixIcon={!disabled && isInitializedByValue && <EditOutlined onClick={handleChangeInitialized} />}
        showTime
      />
      <ApproveButton
        loading={loadingStage.isLoading}
        disabled={disabled || approveDisabled || isError || isInitializedByValue}
        onClick={submit}
      />
    </Flex>
  );
};

export default observer(ExpectedDeliveryTime);
