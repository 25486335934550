import { DictionaryField, DictionaryFielsdArgs, ProductType } from '@/entities/dictionary';
import { UserType } from '@/entities/user';
import { RouteKey, routerUrls } from '@/shared/config/routes';
import { getPathname } from '@/shared/utils';

type PageRoleDictionariesMap = Record<UserType, DictionaryField[]>;
type PageRoleDictionariesArgsMap = Record<UserType, DictionaryFielsdArgs>;

export const roleRouteKeyMap: Record<UserType, Array<RouteKey>> = {
  [UserType.admin]: ['login', 'createPassword', 'clients', 'trades', 'documents', 'chats', 'bids'],
  [UserType.manager]: ['login', 'createPassword', 'clients', 'trades', 'documents', 'chats', 'bids'],
  [UserType.agent]: ['login', 'createPassword', 'trades'],
  [UserType.customer]: ['login', 'createPassword', 'trades', 'profile', 'notifications', 'chats', 'bids'],
  [UserType.supplier]: ['login', 'createPassword', 'trades', 'profile', 'notifications', 'chats', 'bids'],
};

export const roleRouteMap: Record<UserType, string[]> = {
  [UserType.admin]: roleRouteKeyMap[UserType.admin].map((key) => getPathname(routerUrls[key].mask)),
  [UserType.manager]: roleRouteKeyMap[UserType.manager].map((key) => getPathname(routerUrls[key].mask)),
  [UserType.agent]: roleRouteKeyMap[UserType.agent].map((key) => getPathname(routerUrls[key].mask)),
  [UserType.supplier]: roleRouteKeyMap[UserType.supplier].map((key) => getPathname(routerUrls[key].mask)),
  [UserType.customer]: roleRouteKeyMap[UserType.customer].map((key) => getPathname(routerUrls[key].mask)),
};

export const defaultRoleRoute: Record<UserType, string> = {
  [UserType.agent]: routerUrls.trades.create(),
  [UserType.supplier]: routerUrls.trades.create(),
  [UserType.customer]: routerUrls.trades.create(),
  [UserType.manager]: routerUrls.clients.create(),
  [UserType.admin]: routerUrls.clients.create(),
};

export const tradesPageRoleDictionariesMap: PageRoleDictionariesMap = {
  [UserType.admin]: [DictionaryField.productTypes],
  [UserType.manager]: [DictionaryField.productTypes],
  [UserType.agent]: [],
  [UserType.supplier]: [DictionaryField.currencies, DictionaryField.productTypes],
  [UserType.customer]: [DictionaryField.currencies, DictionaryField.productTypes],
};

export const tradeWorkflowPageRoleDictionariesMap: PageRoleDictionariesMap = {
  [UserType.admin]: [
    DictionaryField.customers,
    DictionaryField.suppliers,
    DictionaryField.countries,
    DictionaryField.currencies,
    DictionaryField.legalCompanies,
    DictionaryField.logisticCompanies,
    DictionaryField.productTypes,
    DictionaryField.signatories,
  ],
  [UserType.manager]: [
    DictionaryField.customers,
    DictionaryField.suppliers,
    DictionaryField.countries,
    DictionaryField.currencies,
    DictionaryField.legalCompanies,
    DictionaryField.logisticCompanies,
    DictionaryField.productTypes,
    DictionaryField.signatories,
  ],
  [UserType.agent]: [],
  [UserType.supplier]: [DictionaryField.countries, DictionaryField.currencies, DictionaryField.productTypes],
  [UserType.customer]: [DictionaryField.countries, DictionaryField.currencies, DictionaryField.productTypes],
};

export const profilePageRoleDictionariesMap: PageRoleDictionariesMap = {
  [UserType.admin]: [],
  [UserType.manager]: [],
  [UserType.agent]: [],
  [UserType.supplier]: [DictionaryField.countries],
  [UserType.customer]: [DictionaryField.countries],
};

export const bidsPageRoleDictionariesMap: PageRoleDictionariesMap = {
  [UserType.admin]: [
    DictionaryField.productTypes,
    DictionaryField.countries,
    DictionaryField.currencies,
    DictionaryField.products,
  ],
  [UserType.manager]: [
    DictionaryField.productTypes,
    DictionaryField.countries,
    DictionaryField.currencies,
    DictionaryField.products,
  ],
  [UserType.agent]: [],
  [UserType.supplier]: [DictionaryField.productTypes, DictionaryField.currencies, DictionaryField.products],
  [UserType.customer]: [DictionaryField.productTypes, DictionaryField.currencies, DictionaryField.products],
};

export const bidsPageRoleDictionariesArgsMap: PageRoleDictionariesArgsMap = {
  [UserType.admin]: { [DictionaryField.products]: [ProductType.metal] },
  [UserType.agent]: {},
  [UserType.customer]: { [DictionaryField.products]: [ProductType.metal] },
  [UserType.manager]: { [DictionaryField.products]: [ProductType.metal] },
  [UserType.supplier]: { [DictionaryField.products]: [ProductType.metal] },
};

export const documentsPageRoleDictionariesMap: PageRoleDictionariesMap = {
  [UserType.admin]: [DictionaryField.productTypes],
  [UserType.manager]: [DictionaryField.productTypes],
  [UserType.agent]: [],
  [UserType.supplier]: [DictionaryField.productTypes],
  [UserType.customer]: [DictionaryField.productTypes],
};

export const chatsPageRoleDictionariesMap: PageRoleDictionariesMap = {
  [UserType.admin]: [
    DictionaryField.countries,
    DictionaryField.currencies,
    DictionaryField.logisticCompanies,
    DictionaryField.productTypes,
    DictionaryField.signatories,
  ],
  [UserType.manager]: [
    DictionaryField.countries,
    DictionaryField.currencies,
    DictionaryField.logisticCompanies,
    DictionaryField.productTypes,
    DictionaryField.signatories,
  ],
  [UserType.supplier]: [
    DictionaryField.countries,
    DictionaryField.currencies,
    DictionaryField.logisticCompanies,
    DictionaryField.productTypes,
    DictionaryField.signatories,
    DictionaryField.products,
  ],
  [UserType.customer]: [
    DictionaryField.countries,
    DictionaryField.currencies,
    DictionaryField.logisticCompanies,
    DictionaryField.productTypes,
    DictionaryField.signatories,
    DictionaryField.products,
  ],
  [UserType.agent]: [],
};

export const chatsPageRoleDictionariesArgsMap: PageRoleDictionariesArgsMap = {
  [UserType.admin]: {},
  [UserType.manager]: {},
  [UserType.customer]: { [DictionaryField.products]: [ProductType.metal] },
  [UserType.supplier]: { [DictionaryField.products]: [ProductType.metal] },
  [UserType.agent]: {},
};
