import { computed, makeObservable } from 'mobx';

import { TranslationString } from '@/shared/types/localization';
import { ValidatorResult } from '@/shared/types/validator';

import { BaseFieldModel, BaseFieldModelParams } from './BaseFieldModel';

type InputTextAreaModelParams = BaseFieldModelParams & {
  maxRows?: number;
};

type InputTextAreaModelProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: VoidFunction;
  disabled: boolean;
  required: boolean;
  error: ValidatorResult;
  placeholder: TranslationString;
  rows: number;
};

export class InputTextAreaModel extends BaseFieldModel {
  private _maxRows: number;

  constructor({ maxRows = 3, ...params }: InputTextAreaModelParams) {
    super(params);

    this._maxRows = maxRows;

    makeObservable(this, {
      props: computed,
    });
  }

  get props(): InputTextAreaModelProps {
    return {
      value: this._value,
      onChange: (e) => {
        e.preventDefault();
        this.change(e.currentTarget.value);
      },
      onBlur: this.validate,
      disabled: this.disabled,
      required: this.required,
      error: this.error,
      placeholder: this.placeholder,
      rows: this._maxRows,
    };
  }
}
