import {
  StageModelJsonParams,
  StageType,
  SupplierConfirmationAction,
  SupplierConfirmationStageDataServer,
  SupplierConfirmationStep,
} from '@/entities/stage';
import { UserType } from '@/entities/user';

import { BaseManagerStageModel } from '../../BaseManagerStageModel';
import { BaseStageModel, BaseStageModelParams } from '../../BaseStageModel';
import { BaseUploadFileFieldsModel } from '../../fieldsModel/BaseUploadFileFieldsModel';
import { WaitingFieldsModel } from '../../fieldsModel/WaitingFieldsModel';
import { WaitingFieldsWithFileModel } from '../../fieldsModel/WaitingFieldsWithFileModel';

import { mapSupplierStepToNumber } from './config';

type Params = BaseStageModelParams<SupplierConfirmationStep> & {
  deliveryConfirmFields: BaseUploadFileFieldsModel<SupplierConfirmationStep, SupplierConfirmationAction>;
  confirmVerifyingFields: WaitingFieldsModel<SupplierConfirmationStep>;
  confirmSigningFields: WaitingFieldsWithFileModel<SupplierConfirmationStep>;
};

export class SupplierConfirmationStageModel extends BaseStageModel<StageType.confirmation, SupplierConfirmationStep> {
  readonly deliveryConfirmFields: BaseUploadFileFieldsModel<SupplierConfirmationStep, SupplierConfirmationAction>;
  readonly confirmVerifyingFields: WaitingFieldsModel<SupplierConfirmationStep>;
  readonly confirmSigningFields: WaitingFieldsWithFileModel<SupplierConfirmationStep>;

  constructor({ deliveryConfirmFields, confirmVerifyingFields, confirmSigningFields, ...params }: Params) {
    super({
      type: StageType.confirmation,
      ...params,
    });

    this.deliveryConfirmFields = deliveryConfirmFields;
    this.confirmVerifyingFields = confirmVerifyingFields;
    this.confirmSigningFields = confirmSigningFields;
  }

  private get isNeedVerifyingDeliveryConfirm(): boolean {
    return this.deliveryConfirmFields.uploadedBy === UserType.supplier;
  }

  get currentStep(): number {
    return mapSupplierStepToNumber(this.step, this.isNeedVerifyingDeliveryConfirm);
  }

  get steps(): SupplierConfirmationStep[] {
    let steps: SupplierConfirmationStep[] = [];

    if (this.isNeedVerifyingDeliveryConfirm) {
      steps = [
        SupplierConfirmationStep.deliveryConfirmation,
        SupplierConfirmationStep.confirmationVerifying,
        SupplierConfirmationStep.confirmationSigning,
      ];
    } else {
      steps = [SupplierConfirmationStep.deliveryConfirmation, SupplierConfirmationStep.confirmationSigning];
    }

    return steps;
  }

  get isApprovedDeliveryConfirm(): boolean {
    return this.deliveryConfirmFields.isApproved;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<SupplierConfirmationStageDataServer>): SupplierConfirmationStageModel {
    return new SupplierConfirmationStageModel({
      deliveryConfirmFields: BaseUploadFileFieldsModel.fromJson({
        data: dataServer.steps[SupplierConfirmationStep.deliveryConfirmation],
        step: SupplierConfirmationStep.deliveryConfirmation,
        approveAction: SupplierConfirmationStep.deliveryConfirmation,
        tradeWorkflowStore,
      }),
      confirmVerifyingFields: WaitingFieldsModel.fromJson({
        step: SupplierConfirmationStep.confirmationVerifying,
        tradeWorkflowStore,
      }),
      confirmSigningFields: WaitingFieldsWithFileModel.fromJson({
        data: dataServer.steps[SupplierConfirmationStep.confirmationSigning],
        step: SupplierConfirmationStep.confirmationSigning,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
