import cn from 'classnames';
import * as React from 'react';

import s from './Aircraft.module.scss';

type AircraftProps = {
  className?: string;
};

const Aircraft: React.FC<AircraftProps> = ({ className }) => {
  return (
    <svg className={cn(s.svg, className)} viewBox="0 0 520 221" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2412_45183)">
        <path
          d="M350.697 220.438H169.303C164.173 220.438 160 217.784 160 214.522V6.35278C160 3.09128 164.173 0.4375 169.303 0.4375H350.697C355.827 0.4375 360 3.09128 360 6.35278V214.522C360 217.784 355.827 220.438 350.697 220.438ZM240.202 1.42531C196.765 1.42531 169.303 60.319 169.303 87.9375L161.554 214.522C161.554 217.244 165.023 219.45 169.303 219.45H350.697C354.977 219.45 358.446 217.244 358.446 214.522V6.35278C358.446 3.63141 354.977 1.42531 350.697 1.42531L240.202 1.42531Z"
          fill="#E4EBF7"
        />
        <path
          d="M323.348 54.9611C323.348 55.3143 323.744 55.598 324.237 55.598H476.903C477.397 55.598 477.793 55.3143 477.793 54.9611C477.793 54.6079 477.397 54.3242 476.903 54.3242H324.237C323.744 54.3242 323.348 54.6079 323.348 54.9611Z"
          fill="#E4EBF7"
        />
        <path
          d="M199.186 145.913L200.697 185.952H197.02V190.485H207.299V185.952H204.3L204.515 145.913H199.186Z"
          fill="#2F2E41"
        />
        <path
          d="M195.22 179.53C196.784 179.53 198.053 180.799 198.053 182.363V194.073C198.053 195.637 196.784 196.906 195.22 196.906C193.655 196.906 192.387 195.637 192.387 194.073V182.363C192.387 180.799 193.655 179.53 195.22 179.53Z"
          fill="#2F2E41"
        />
        <path
          d="M209.571 179.53C211.136 179.53 212.404 180.799 212.404 182.363V194.073C212.404 195.637 211.136 196.906 209.571 196.906C208.007 196.906 206.738 195.637 206.738 194.073V182.363C206.738 180.799 208.007 179.53 209.571 179.53Z"
          fill="#2F2E41"
        />
        <path d="M232.616 120.966L174.445 118.322L183.511 114.167L234.504 114.922L232.616 120.966Z" fill="#1890FF" />
        <path
          d="M0.6875 126.836L7.71268 124.192L225.814 129.858L234.88 125.703L231.103 151.389L57.8548 132.928L0.6875 126.836Z"
          fill="#1890FF"
        />
        <path d="M56.7852 141.758L57.8596 132.93L231.107 151.391L230.163 164.422L56.7852 141.758Z" fill="#2F2E41" />
        <path
          d="M173.313 167.822C183.222 167.822 191.255 159.281 191.255 148.746C191.255 138.211 183.222 129.671 173.313 129.671C163.404 129.671 155.371 138.211 155.371 148.746C155.371 159.281 163.404 167.822 173.313 167.822Z"
          fill="#2F2E41"
        />
        <path
          d="M173.314 158.566C180.616 158.566 186.535 152.647 186.535 145.346C186.535 138.044 180.616 132.125 173.314 132.125C166.013 132.125 160.094 138.044 160.094 145.346C160.094 152.647 166.013 158.566 173.314 158.566Z"
          fill="#1890FF"
        />
        <path
          d="M320.815 145.913L319.304 185.952H322.982V190.485H312.703V185.952H315.701L315.487 145.913H320.815Z"
          fill="#2F2E41"
        />
        <path
          d="M324.778 179.53H324.778C323.214 179.53 321.945 180.799 321.945 182.363V194.073C321.945 195.637 323.214 196.906 324.778 196.906H324.778C326.343 196.906 327.611 195.637 327.611 194.073V182.363C327.611 180.799 326.343 179.53 324.778 179.53Z"
          fill="#2F2E41"
        />
        <path
          d="M310.427 179.53H310.427C308.862 179.53 307.594 180.799 307.594 182.363V194.073C307.594 195.637 308.862 196.906 310.427 196.906H310.427C311.991 196.906 313.26 195.637 313.26 194.073V182.363C313.26 180.799 311.991 179.53 310.427 179.53Z"
          fill="#2F2E41"
        />
        <path d="M287.385 120.966L345.555 118.322L336.49 114.167L285.496 114.922L287.385 120.966Z" fill="#1890FF" />
        <path d="M266.565 112.829L263.921 54.6582L259.766 63.7237L260.521 114.717L266.565 112.829Z" fill="#1890FF" />
        <path
          d="M519.31 126.836L512.284 124.192L294.183 129.858L285.117 125.703L288.894 151.389L462.142 132.928L519.31 126.836Z"
          fill="#1890FF"
        />
        <path d="M463.217 141.758L462.142 132.93L288.895 151.391L289.839 164.422L463.217 141.758Z" fill="#2F2E41" />
        <path
          d="M346.688 167.822C356.597 167.822 364.63 159.281 364.63 148.746C364.63 138.211 356.597 129.671 346.688 129.671C336.779 129.671 328.746 138.211 328.746 148.746C328.746 159.281 336.779 167.822 346.688 167.822Z"
          fill="#2F2E41"
        />
        <path
          d="M346.689 158.566C353.991 158.566 359.91 152.647 359.91 145.346C359.91 138.044 353.991 132.125 346.689 132.125C339.388 132.125 333.469 138.044 333.469 145.346C333.469 152.647 339.388 158.566 346.689 158.566Z"
          fill="#1890FF"
        />
        <path
          d="M292.956 113.72L292.098 120.038L291.653 154.049C291.619 156.579 291.313 159.08 290.739 161.505C289.084 168.569 285.22 174.994 279.512 179.651C274.334 183.882 267.27 187.085 257.921 186.518C232.991 185.007 230.789 163.79 230.789 163.79L231.73 125.315L231.858 120.038L232.991 110.517C232.991 110.517 236.013 83.6977 263.965 84.4532C291.917 85.2086 292.956 113.72 292.956 113.72Z"
          fill="#1890FF"
        />
        <path
          d="M239.035 104.85L244.701 95.7849L251.878 93.5186H276.43L282.474 95.7849L287.959 104.85L277.941 100.318H249.841L239.035 104.85Z"
          fill="#E6E6E6"
        />
        <path
          d="M259.812 170.025L260.781 195.711H258.422V198.619H265.016V195.711H263.093L263.23 170.025H259.812Z"
          fill="#2F2E41"
        />
        <path
          d="M257.267 191.592C258.27 191.592 259.084 192.405 259.084 193.409V200.921C259.084 201.925 258.27 202.738 257.267 202.738C256.263 202.738 255.449 201.925 255.449 200.921V193.409C255.449 192.405 256.263 191.592 257.267 191.592Z"
          fill="#2F2E41"
        />
        <path
          d="M266.474 191.592C267.477 191.592 268.291 192.405 268.291 193.409V200.921C268.291 201.925 267.477 202.738 266.474 202.738C265.47 202.738 264.656 201.925 264.656 200.921V193.409C264.656 192.405 265.47 191.592 266.474 191.592Z"
          fill="#2F2E41"
        />
        <path d="M252.781 87.9509L247.562 106.739L248.568 107.019L253.787 88.2302L252.781 87.9509Z" fill="#1890FF" />
        <path d="M276.446 90.9491L275.422 91.1504L279.182 110.284L280.206 110.083L276.446 90.9491Z" fill="#1890FF" />
        <path
          d="M383.976 60.9335C387.723 60.9335 390.761 57.8959 390.761 54.1489C390.761 50.4018 387.723 47.3643 383.976 47.3643C380.229 47.3643 377.191 50.4018 377.191 54.1489C377.191 57.8959 380.229 60.9335 383.976 60.9335Z"
          fill="#1890FF"
        />
        <path
          d="M196.191 83.1415C199.938 83.1415 202.976 80.104 202.976 76.3569C202.976 72.6099 199.938 69.5723 196.191 69.5723C192.444 69.5723 189.406 72.6099 189.406 76.3569C189.406 80.104 192.444 83.1415 196.191 83.1415Z"
          fill="#1890FF"
        />
        <path
          d="M42.5664 203.7C42.5664 204.054 42.9625 204.337 43.4557 204.337H196.122C196.615 204.337 197.011 204.054 197.011 203.7C197.011 203.347 196.615 203.063 196.122 203.063H43.4557C42.9625 203.063 42.5664 203.347 42.5664 203.7Z"
          fill="#E4EBF7"
        />
        <path
          d="M103.199 209.674C106.946 209.674 109.983 206.636 109.983 202.889C109.983 199.142 106.946 196.104 103.199 196.104C99.4516 196.104 96.4141 199.142 96.4141 202.889C96.4141 206.636 99.4516 209.674 103.199 209.674Z"
          fill="#1890FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_2412_45183">
          <rect width="518.623" height="220.408" fill="white" transform="translate(0.6875)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default React.memo(Aircraft);
