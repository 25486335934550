import { HistoryActions } from '@/entities/tradeHistory';
import { ApiListResponse } from '@/shared/types/api';
import { ID, ListDirection } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

export type NotificationServer = {
  id: number;
  action: HistoryActions;
  trade_id: number;
  created_at: string;
  is_new: boolean;
};

export type NotificationListResponse = ApiListResponse<NotificationServer>;

export enum NotificationCategory {
  statusChange = 'trade_status_change',
  docUpload = 'document_upload',
  docVerification = 'document_verification',
}

export enum NotificationService {
  telegram = 'telegram',
  email = 'email',
}

export type NotificationListPayload = {
  limit: number;
  from_id: Nullable<number>;
  direction: ListDirection;
  category?: Nullable<Array<NotificationCategory>>;
  date_after?: Nullable<string>;
  date_before?: Nullable<string>;
};

export type NotificationsSetting<T> = {
  slug: T;
  enabled: boolean;
};

export type AccountServer = {
  account_id: ID;
  account_link: string;
  first_name: Nullable<string>;
  last_name: Nullable<string>;
  username: Nullable<string>;
};

export type NotificationsSettingsResponse = {
  categories: Array<NotificationsSetting<NotificationCategory>>;
  services: Array<NotificationsSetting<NotificationService>>;
  accounts?: Partial<Record<NotificationService, AccountServer>>;
};

export type NotificationsSettingsPayload = Partial<{
  category: NotificationsSetting<NotificationCategory>;
  service: NotificationsSetting<NotificationService>;
}>;
