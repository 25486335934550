import { Nullable } from '@/shared/types/values';

import { ISignatoryDictionary, SingatoryDictionaryServer } from '../types';

export class SignatoryDictionaryModel implements ISignatoryDictionary {
  readonly value: number;
  readonly label: string;
  readonly title: string;
  readonly type: Nullable<string>;
  readonly signLink: Nullable<string>;

  constructor({ value, label, title, type, signLink }: ISignatoryDictionary) {
    this.value = value;
    this.label = label;
    this.title = title;
    this.type = type;
    this.signLink = signLink;
  }

  static fromJson(server: SingatoryDictionaryServer): SignatoryDictionaryModel {
    return new SignatoryDictionaryModel({
      value: server.id,
      label: server.name,
      title: server.name,
      type: server.type,
      signLink: server.sign && server.sign.link,
    });
  }
}
