import { Flex } from 'antd';
import * as React from 'react';

import { TranslationString } from '@/shared/types/localization';
import { isStringOrNumber } from '@/shared/types/typesGuard';
import { CustomTypographyText } from '@/shared/ui';

import s from './TradeInfoList.module.scss';

type Props = {
  label: TranslationString;
  value: string | number | React.ReactElement;
};

const TradeInfoRow: React.FC<Props> = ({ label, value }) => {
  return (
    <Flex gap={10} align="center" justify="space-between">
      <CustomTypographyText>{label}</CustomTypographyText>
      {isStringOrNumber(value) ? (
        <CustomTypographyText className={s.row_value} type="secondary">
          {value}
        </CustomTypographyText>
      ) : (
        value
      )}
    </Flex>
  );
};

export default React.memo(TradeInfoRow);
