import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks';
import { InputTextAreaModel } from '@/shared/model/form/InputTextAreaModel';
import { renderTranslationString } from '@/shared/utils/renderTranslation';

import { CustomFormItem } from '../CustomFormItem';

type FormInputTextAreaProps = Pick<TextAreaProps, 'className' | 'disabled' | 'autoSize' | 'maxLength'> & {
  model: InputTextAreaModel;
};

const FormInputTextArea: React.FC<FormInputTextAreaProps> = ({ className, model, disabled, ...props }) => {
  const { t } = useTypedTranslation();

  const placeholder = renderTranslationString(model.placeholder, t);

  return (
    <CustomFormItem className={className} ref={model.ref} model={model}>
      <Input.TextArea
        {...model.props}
        {...props}
        placeholder={placeholder}
        disabled={disabled ?? model.props.disabled}
      />
    </CustomFormItem>
  );
};

export default observer(FormInputTextArea);
