import { UnitDictionaryServer } from '@/entities/dictionary';
import { Nullable } from '@/shared/types/values';

export enum TaxType {
  TIN = 'tin',
  TRN = 'trn',
  BR = 'br',
  CR = 'cr',
  licenceNumber = 'licence_number',
  certificateNumber = 'certificate_number',
}

export type TaxAttributeServer = {
  slug: TaxType;
  identifier: string;
};

export type CompanyServer = {
  id: number;
  name: string;
  address: string;
  email?: Nullable<string>;
  phone?: Nullable<string>;
  image?: Nullable<{ link: string }>;
  country?: Nullable<UnitDictionaryServer>;
  tax_attribute?: Nullable<TaxAttributeServer>;
};

export type CompanyPayload = {
  name: string;
  address: string;
};

export type CompanyEditPayload = CompanyPayload & {
  email: string;
  phone: string;
  tax_attribute_identifier: string;
  tax_attribute_slug: TaxType;
  country_id: number;
  image?: File | '';
};
