import { DatePickerProps } from 'antd/lib';
import dayjs from 'dayjs';

import { DateFormat } from '@/shared/types/meta';
import { ValidatorResult } from '@/shared/types/validator';
import { Nullable } from '@/shared/types/values';

import { BaseFieldModel, BaseFieldModelParams } from './BaseFieldModel';

type DisabledDate = NonNullable<DatePickerProps['disabledDate']>;

export type DatePickerModelParams = BaseFieldModelParams<Date | null> & {
  normalizationFormat?: string;
  disabledDate?: DisabledDate;
};

type DatePickerModelProps = {
  value: Nullable<dayjs.Dayjs>;
  onChange: (dayjs: Nullable<dayjs.Dayjs>) => void;
  onBlur?: VoidFunction;
  disabled: boolean;
  required: boolean;
  error: ValidatorResult;
  disabledDate: DisabledDate;
};

export class DatePickerModel extends BaseFieldModel<Nullable<Date>> {
  private readonly _normalizationFormat: string;
  private readonly _disabledDate: DisabledDate;

  constructor({
    placeholder = (t) => t('placeholders.date', { ns: 'shared' }),
    normalizationFormat = DateFormat.date,
    disabledDate = () => false,
    ...params
  }: DatePickerModelParams) {
    super({ placeholder, ...params });

    this._normalizationFormat = normalizationFormat;
    this._disabledDate = disabledDate;
  }

  get props(): DatePickerModelProps {
    return {
      value: this._value && dayjs(this._value),
      onChange: (dayjs: Nullable<dayjs.Dayjs>) => {
        const date = dayjs && new Date(dayjs.format(this._normalizationFormat));

        this.change(date);

        if (!date) {
          this.validate();
        }
      },
      onBlur: this.ignoreOnBlurValidation ? undefined : this.validate,
      disabledDate: this._disabledDate,
      disabled: this.disabled,
      required: this.required,
      error: this.error,
    };
  }
}
