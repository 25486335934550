import { Flex, MenuProps } from 'antd';
import * as React from 'react';

import { CustomTypographyText } from '@/shared/ui';
import { CustomButton } from '@/shared/ui/form/CustomButton';
import { CustomCheckbox } from '@/shared/ui/form/CustomCheckbox';

import { TradeStageFilterModel } from '../../model';

import s from './TradeStageFilter.module.scss';

enum MenuKey {
  title = 'title',
  all = 'all',
  actions = 'actions',
}

export const getMenu = ({
  checkedAll,
  props,
  resetDisabled,
  changed,
  onChange,
  reset,
  apply,
}: TradeStageFilterModel): MenuProps => {
  const items: MenuProps['items'] = [
    {
      key: MenuKey.title,
      label: <CustomTypographyText strong>{(t) => t('tradeStageFilter.title', { ns: 'trade' })}</CustomTypographyText>,
      disabled: true,
      style: { cursor: 'default' },
    },
    {
      key: MenuKey.all,
      label: (
        <CustomCheckbox
          label={(t) => t('tradeStageFilter.all', { ns: 'trade' })}
          checked={checkedAll.value}
          onChange={(e) => {
            if (e.target.checked) {
              props.onChange(props.options.map((option) => option.value));
            } else {
              props.onChange([]);
            }

            checkedAll.toggle();
          }}
          fullWidth
        />
      ),
    },
    { type: 'divider' },
    ...props.options.map((option) => ({
      key: option.value,
      label: (
        <CustomCheckbox
          label={option.label}
          checked={props.value.includes(option.value)}
          onChange={() => onChange(option.value)}
          fullWidth
        />
      ),
    })),
    { type: 'divider' },
    {
      className: s.filter_actions,
      key: MenuKey.actions,
      label: (
        <Flex gap={8}>
          <CustomButton style={{ flex: 1 }} size="small" disabled={resetDisabled} onClick={reset}>
            {(t) => t('buttons.reset', { ns: 'shared' })}
          </CustomButton>
          <CustomButton style={{ flex: 1 }} size="small" type="primary" disabled={!changed} onClick={apply}>
            {(t) => t('buttons.apply', { ns: 'shared' })}
          </CustomButton>
        </Flex>
      ),
      disabled: true,
    },
  ];

  return { items };
};
