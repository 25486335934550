import * as React from 'react';

import { IconProps } from '../types';

const ProfileIcon: React.FC<IconProps> = ({ size = 16, ...props }) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.73335 14.6667C3.73335 14.6667 2.66669 14.6667 2.66669 13.6111C2.66669 12.5556 3.73335 9.38889 8.00002 9.38889C12.2667 9.38889 13.3334 12.5556 13.3334 13.6111C13.3334 14.6667 12.2667 14.6667 12.2667 14.6667H3.73335ZM8.00002 8.33333C8.84871 8.33333 9.66264 7.9997 10.2628 7.40584C10.8629 6.81197 11.2 6.00652 11.2 5.16667C11.2 4.32681 10.8629 3.52136 10.2628 2.9275C9.66264 2.33363 8.84871 2 8.00002 2C7.15133 2 6.33739 2.33363 5.73728 2.9275C5.13716 3.52136 4.80002 4.32681 4.80002 5.16667C4.80002 6.00652 5.13716 6.81197 5.73728 7.40584C6.33739 7.9997 7.15133 8.33333 8.00002 8.33333Z"
        fill="currentColor"
        fillOpacity="0.88"
      />
    </svg>
  );
};

export default React.memo(ProfileIcon);
