import { computed, makeObservable } from 'mobx';

import { ManagerCreationStageDataServer, ManagerCreationStep, StageModelJsonParams, StageType } from '@/entities/stage';

import { BaseManagerStageModel, BaseManagerStageModelParams } from '../BaseManagerStageModel';

import { CreateTradeFieldsModel } from './fieldsModel/CreateTradeFieldsModel';

type Params = BaseManagerStageModelParams<ManagerCreationStep> & {
  createTradeFields: CreateTradeFieldsModel;
};

export class ManagerCreationStageModel extends BaseManagerStageModel<StageType.creation, ManagerCreationStep> {
  readonly createTradeFields: CreateTradeFieldsModel;

  constructor({ createTradeFields, ...params }: Params) {
    super({
      type: StageType.creation,
      ...params,
    });

    this.createTradeFields = createTradeFields;

    makeObservable(this, {
      createTradeFieldsDisabled: computed,
      showAlert: computed,
    });
  }

  get currentStep(): number {
    switch (this.step) {
      case ManagerCreationStep.createTrade:
        return 0;

      default:
        return -1;
    }
  }

  get steps(): ManagerCreationStep[] {
    return [ManagerCreationStep.createTrade];
  }

  get createTradeFieldsDisabled(): boolean {
    return this.step !== ManagerCreationStep.createTrade;
  }

  get showAlert(): boolean {
    return this.currentType === StageType.creation;
  }

  destroy(): void {
    super.destroy();
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<ManagerCreationStageDataServer>): ManagerCreationStageModel {
    return new ManagerCreationStageModel({
      createTradeFields: CreateTradeFieldsModel.fromJson({
        server: dataServer,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
