import { UserType } from '../types';

export const user = {
  en: {
    fields: {
      contacts: 'Contact details',
      name: 'Name',
      fio: 'Full name',
      phone: 'Phone number',
      email: 'Email',
      address: 'Address',
      type: 'Type',
    },
    userStatus: {
      active: 'Active',
      notActive: 'Not Active',
    },
    userRoles: {
      [UserType.customer]: 'Customer',
      [UserType.supplier]: 'Supplier',
      [UserType.agent]: 'Agent',
      [UserType.manager]: 'Manager',
      [UserType.admin]: 'Administrator',
    },
  },
  ru: {
    fields: {
      contacts: 'Контактные данные',
      name: 'Название',
      fio: 'ФИО',
      phone: 'Номер телефона',
      email: 'Email',
      address: 'Адрес',
      type: 'Тип',
    },
    userStatus: {
      active: 'Активен',
      notActive: 'Не активен',
    },
    userRoles: {
      [UserType.customer]: 'Покупатель',
      [UserType.supplier]: 'Поставщик',
      [UserType.agent]: 'Агент',
      [UserType.manager]: 'Менеджер',
      [UserType.admin]: 'Администратор',
    },
  },
  zh: {
    fields: {
      contacts: '联系方式',
      name: '名称',
      fio: '姓名',
      phone: '电话号码',
      email: '电子邮箱',
      address: '地址',
      type: '类别',
    },
    userStatus: {
      active: '活跃',
      notActive: '不活跃',
    },
    userRoles: {
      [UserType.customer]: '买方',
      [UserType.supplier]: '买方',
      [UserType.agent]: '代办',
      [UserType.manager]: '代办',
      [UserType.admin]: '管理人',
    },
  },
};
