import { TextProps } from 'antd/es/typography/Text';
import * as React from 'react';

import { TranslationString } from '@/shared/types/localization';

import { CustomTypographyText } from '../CustomTypographyText';
import { TransComp } from '../TransComp';

type TypographyTextWithTooltipProps = Omit<TextProps, 'children'> & {
  text: TranslationString;
};

const TypographyTextWithTooltip: React.FC<TypographyTextWithTooltipProps> = ({ text, ...props }) => {
  return (
    <CustomTypographyText ellipsis={{ tooltip: <TransComp>{text}</TransComp> }} {...props}>
      {text}
    </CustomTypographyText>
  );
};

export default TypographyTextWithTooltip;
