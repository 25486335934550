import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import * as React from 'react';

import { TranslationString } from '@/shared/types/localization';

import { TransComp } from '../../TransComp';

import { FormItemOptional } from './FormItemOptional';

import s from './FormItem.module.scss';

type FormItemLabelProps = {
  label?: TranslationString;
  required?: boolean;
  tooltip?: TranslationString;
};

export const FormItemLabel: React.FC<FormItemLabelProps> = ({ label, required, tooltip }) => {
  if (!label) return null;

  return (
    <>
      <TransComp>{label}</TransComp>
      <FormItemOptional required={required} />
      {tooltip && (
        <Tooltip placement="top" title={<TransComp>{tooltip}</TransComp>}>
          <QuestionCircleOutlined className={s['form-item_tooltip']} />
        </Tooltip>
      )}
    </>
  );
};
