import { computed, makeObservable } from 'mobx';

import { LoadingStageModel, LocalStore } from '@/shared/model';
import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { Nullable } from '@/shared/types/values';
import { emptyValueValidator, stringLengthValidator } from '@/shared/utils/validators';

import { CompanyPayload } from '../types';

export type CompanyFieldsParams = {
  name: string;
  address: string;
};

const T_OPTIONS = { ns: 'company' } as const;

export class CompanyFieldsModel extends LocalStore {
  readonly name: InputModel;
  readonly address: InputModel;

  readonly loadingStage = new LoadingStageModel();

  protected fields: BaseFieldModel<any>[];

  constructor(company: CompanyFieldsParams) {
    super();

    this.name = new InputModel({
      initialValue: company.name,
      label: (t) => t('fields.name', T_OPTIONS),
      placeholder: (t) => t('fields.name', T_OPTIONS),
      validators: [emptyValueValidator(), stringLengthValidator(128)],
      required: true,
    });

    this.address = new InputModel({
      initialValue: company.address,
      label: (t) => t('fields.address', T_OPTIONS),
      placeholder: (t) => t('fields.address', T_OPTIONS),
      required: true,
      validators: [emptyValueValidator(), stringLengthValidator(512)],
    });

    this.fields = [this.name, this.address];

    makeObservable(this, {
      isValid: computed,
      isChange: computed,
    });
  }

  get isValid(): boolean {
    return this.fields.every((field) => !field.isError);
  }

  get isChange(): boolean {
    return this.fields.some((field) => field.touched);
  }

  validate = (): boolean => {
    this.fields.forEach((field) => field.validate());

    return this.isValid;
  };

  reset = (): void => {
    this.fields.forEach((field) => field.reset());
  };

  toJson = (): Nullable<CompanyPayload> => {
    if (!this.name.value && !this.address.value) {
      return null;
    }

    return {
      name: this.name.value,
      address: this.address.value,
    };
  };

  static fromDefaultParams(): CompanyFieldsModel {
    return new CompanyFieldsModel({
      name: '',
      address: '',
    });
  }
}
