import { Typography } from 'antd';
import { TextProps } from 'antd/es/typography/Text';
import * as React from 'react';

import { TranslationNode } from '@/shared/types/localization';

import { TransComp } from '../TransComp';

type CustomTypographyTextProps = Omit<TextProps, 'children'> & {
  children: TranslationNode;
};

const CustomTypographyText: React.FC<CustomTypographyTextProps> = ({ children, ...props }) => {
  return (
    <Typography.Text {...props}>
      <TransComp>{children}</TransComp>
    </Typography.Text>
  );
};

export default CustomTypographyText;
